







































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
.c-compare__product-card {
  padding: $u-500;
  background-color: $color-white;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: $box-shadow-default;
  flex: 1;
  max-width: 304px;
  text-align: center;
  @include mq($screen-md, max) {
    padding: $u-300;
  }

  &__product-price-block {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: $u-300;
  }

  &__product-brand {
    margin-bottom: $u-150;
  }

  &__product-title {
    margin-bottom: $u-150;
  }

  &__product-photo {
    margin-bottom: $u-250;
    display: flex;
    flex: 1;
    align-items: center;
  }

  &__text {
    &--light {
      color: $c-nt-600;
    }
    &--sm {
      font-size: $font-size-h6;
    }
  }
}
