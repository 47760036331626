/**
 * oneblock-position.css
 * @version 1.0.10
 * @summary 14-10-2019
 * @author Mads Stoumann
 * @description OneBlock: Position
 * TODO Add CSS biredirection when postCSS bidirection plugin works 
 */

/* Position 
===============================
Prefix:
-C Caption
-E Content Box
-H Headline (Only 1 + 2)
-I Image
-L Link (Only 1 + 2)
-M Meta (Only 1 + 2)
-N Tagline (Only 1 + 2)
-S Splash
-T Text (Only 1 + 2)

Key:
-ps

Value:
1 TopLeft  2 TopCenter  3 TopRight
4 CenLeft  5 CenCenter  6 CenRight
7 BotLeft  8 BotCenter  9 BotRight
===============================
*/
.-C-ps1 .c-ob__bg-caption,
.-E-ps1 .c-ob__content,
.-I-ps1 .c-ob__img,
.-S-ps1 .c-ob__splash {
	left: var(--ob-distance-lr);
	top: var(--ob-distance-tb);
}
[dir="rtl"] .-C-ps1 .c-ob__bg-caption,
[dir="rtl"] .-I-ps1 .c-ob__img,
[dir="rtl"] .-S-ps1 .c-ob__splash {
	left: unset;
	right: var(--ob-distance-lr);
}
.-C-ps2 .c-ob__bg-caption,
.-E-ps2 .c-ob__content,
.-I-ps2 .c-ob__img,
.-S-ps2 .c-ob__splash {
	--ob-translate-x: -50%;
	left: 50%;
	right: unset;
	top: var(--ob-distance-tb);
	transform: translateX(var(--ob-translate-x));
}
.-C-ps3 .c-ob__bg-caption,
.-E-ps3 .c-ob__content,
.-I-ps3 .c-ob__img,
.-S-ps3 .c-ob__splash,
.c-ob__splash /* Default Splash Position */ {
	left: unset;
	right: var(--ob-distance-lr);
	top: var(--ob-distance-tb);
}
[dir="rtl"] .-C-ps3 .c-ob__bg-caption,
[dir="rtl"] .-I-ps3 .c-ob__img {
	left: var(--ob-distance-lr);
	right: unset;
}
/* Default Splash Position RTL */
[dir="rtl"] .c-ob:not([class*=-S-ps]) .c-ob__splash {
	left: var(--ob-distance-lr);
	right: unset;
}
.-C-ps4 .c-ob__bg-caption,
.-E-ps4 .c-ob__content,
.-I-ps4 .c-ob__img,
.-S-ps4 .c-ob__splash {
	--ob-translate-y: -50%;
	left: var(--ob-distance-lr);
	top: 50%;
	transform: translateY(var(--ob-translate-y));
}
[dir="rtl"] .-C-ps4 .c-ob__bg-caption,
[dir="rtl"] .-I-ps4 .c-ob__img,
[dir="rtl"] .-S-ps4 .c-ob__splash {
	left: unset;
	right: var(--ob-distance-lr);
}
.-C-ps5 .c-ob__bg-caption,
.-E-ps5 .c-ob__content,
.-I-ps5 .c-ob__img,
.-S-ps5 .c-ob__splash {
	--ob-translate-x: -50%;
	--ob-translate-y: -50%;
	left: 50%;
	right: unset;
	top: 50%;
	transform: translate(var(--ob-translate-x), var(--ob-translate-y));
}
.-C-ps6 .c-ob__bg-caption,
.-E-ps6 .c-ob__content,
.-I-ps6 .c-ob__img,
.-S-ps6 .c-ob__splash {
	--ob-translate-y: -50%;
	left: unset;
	right: var(--ob-distance-lr);
	top: 50%;
	transform: translateY(var(--ob-translate-y));
}
[dir="rtl"] .-C-ps6 .c-ob__bg-caption,
[dir="rtl"] .-I-ps6 .c-ob__img,
[dir="rtl"] .-S-ps6 .c-ob__splash {
	left: var(--ob-distance-lr);
	right: unset;
}
.-C-ps7 .c-ob__bg-caption,
.-E-ps7 .c-ob__content,
.-I-ps7 .c-ob__img,
.-S-ps7 .c-ob__splash {
	bottom: var(--ob-distance-tb);
	left: var(--ob-distance-lr);
	right: unset;
	top: unset;
}
[dir="rtl"] .-C-ps7 .c-ob__bg-caption,
[dir="rtl"] .-I-ps7 .c-ob__img,
[dir="rtl"] .-S-ps7 .c-ob__splash {
	left: unset;
	right: var(--ob-distance-lr);
}
.-C-ps8 .c-ob__bg-caption,
.-E-ps8 .c-ob__content,
.-I-ps8 .c-ob__img,
.-S-ps8 .c-ob__splash {
	--ob-translate-x: -50%;
	bottom: var(--ob-distance-tb);
	left: 50%;
	right: unset;
	top: unset;
	transform: translateX(var(--ob-translate-x));
}
.-C-ps9 .c-ob__bg-caption,
.-E-ps9 .c-ob__content,
.-I-ps9 .c-ob__img,
.-S-ps9 .c-ob__splash {
	bottom: var(--ob-distance-tb);
	left: unset;
	right: var(--ob-distance-lr);
	top: unset;
}
[dir="rtl"] .-C-ps9 .c-ob__bg-caption,
[dir="rtl"] .-I-ps9 .c-ob__img,
[dir="rtl"] .-S-ps9 .c-ob__splash {
	left: var(--ob-distance-lr);
	right: unset;
}

/* IE Fixes */
@media screen and (-ms-high-contrast: none) {
	.-C-ps2 .c-ob__bg-caption,
	.-E-ps2 .c-ob__content,
	.-I-ps2 .c-ob__img,
	.-S-ps2 .c-ob__splash {
		transform: translateX(-50%);
	}
	.-C-ps4 .c-ob__bg-caption,
	.-E-ps4 .c-ob__content,
	.-I-ps4 .c-ob__img,
	.-S-ps4 .c-ob__splash {
		transform: translateY(-50%);
	}
	.-C-ps5 .c-ob__bg-caption,
	.-E-ps5 .c-ob__content,
	.-I-ps5 .c-ob__img,
	.-S-ps5 .c-ob__splash {
		transform: translate(-50%,-50%);
	}
	.-C-ps6 .c-ob__bg-caption,
	.-E-ps6 .c-ob__content,
	.-I-ps6 .c-ob__img,
	.-S-ps6 .c-ob__splash {
		transform: translateY(-50%);
	}
	.-C-ps8 .c-ob__bg-caption,
	.-E-ps8 .c-ob__content,
	.-I-ps8 .c-ob__img,
	.-S-ps8 .c-ob__splash {
		transform: translateX(-50%);
	}
}

/* Link Wrapper */
.-L-ps1 .c-ob__link-wrapper {
	align-self: center;
}
.-L-ps2 .c-ob__link-wrapper {
	align-self: flex-end;
}
.-L-ps2 .c-ob__link-wrapper[dir=rtl] {
	align-self: flex-start;
}

/* Headline, Tagline */
.-E-ta1 .c-ob__content,
.-E-ta1 .c-ob__link-wrapper,
.-E-ta1 .c-ob__meta,
.-E-ta1 .c-ob__tagline {
	align-items: center;
	justify-content: center;
	text-align: center;
}
.-E-ta2 .c-ob__content,
.-E-ta2 .c-ob__link-wrapper,
.-E-ta2 .c-ob__meta,
.-E-ta2 .c-ob__tagline  {
	justify-content: flex-end;
	text-align: right;
}

/* Link-wrapper, Tagline, Meta position overwrite */
.-M-ps1 .c-ob__meta {
	justify-content: center;
}
.-M-ps2 .c-ob__meta {
	justify-content: flex-end;
}

/* Background-Position for background-images / videos
===============================
Prefix:
-B Background Image/Video

Key:
-op

Value:
0 Default (Center Center)
1 TopLeft  2 TopCenter  3 TopRight
4 CenLeft  5 CenCenter  6 CenRight
7 BotLeft  8 BotCenter  9 BotRight
===============================
*/
.-B-op1 .c-ob__bg {
	object-position: 0 0;
}
.-B-op2 .c-ob__bg {
	object-position: 50% 0;
}
.-B-op3 .c-ob__bg {
	object-position: 100% 0;
}
.-B-op4 .c-ob__bg {
	object-position: 0 50%;
}
.-B-op5 .c-ob__bg {
	object-position: 50% 50%;
}
.-B-op6 .c-ob__bg {
	object-position: 100% 50%;
}
.-B-op7 .c-ob__bg {
	object-position: 0 100%;
}
.-B-op8 .c-ob__bg {
	object-position: 50% 100%;
}
.-B-op9 .c-ob__bg {
	object-position: 100% 100%;
}


/* Flex-Styles for Content Box (default: stretch)
===============================
Prefix:
-C Content Box

Key:
-fs

Value:
0-?
===============================
*/
.-E-fp1 .c-ob__text,
.-E-fp2 .c-ob__text {
	flex: initial;
}

.-E-fp1 .c-ob__content {
	justify-content: center;
}

.-E-fp2 .c-ob__content {
	justify-content: flex-end;
}