// Links
a {
  color: $color-display-text;
  text-decoration: none;
  transition: background-color 0.25s, color 0.25s, border-color 0.25s;
  &:hover {
    text-decoration: underline;
  }
}
#{$prefix} {
  &link {
    &--red {
      color: $c-pm-500;
    }
    &--back {
      color: $c-pm-500;
      font-size: 14px;
    }
  }
}
