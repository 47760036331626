











































.c-layer-manager {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;

  &__back {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: auto;
  }

  &--fade {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.5s;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }
}
