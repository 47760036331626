




















































































































































































































































































































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$config-image-width: $u-700;
$config-image-spacing: $u-350;

.config-group {
  // .config-group--disabled
  &--disabled {
    opacity: 0.5;
  }

  // .config-group__image
  &__image {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: $config-image-width;
    margin-right: $config-image-spacing;
  }

  // .congig-group__header
  &__header {
    display: flex;
  }

  // .config-group__info
  &__info {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-right: $u-400;

    @include mq($screen-md, max) {
      flex-direction: column;
    }

    // .config-group__info__left
    &__left {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;

      // .config-group__info__left-title
      &-title {
        color: $c-nt-900;
      }

      // .config-group__info__left-completed-text
      &-completed-text {
        color: $c-pm-500;
      }
    }
    // .config-group__info__right
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;

      @include mq($screen-md, max) {
        margin-top: $u-300;
        align-items: unset;
      }

      // .config-group__info__right-title
      &-title {
        color: $c-nt-600;
      }
    }

    // .config-group__info__left, .config-group__info__right
    &__left,
    &__right {
      // .config-group__info__left-title, .config-group__info__right-title
      &-title {
        font-weight: 700;
      }

      // .config-group__info__left-description, .config-group__info__right-description
      &-description {
        color: $c-nt-600;
      }
    }
  }

  // .config-group__toggle
  &__toggle {
    display: flex;
    align-items: center;
    position: relative;

    // .config-group__toggle-button
    &-button {
      display: flex;
      align-items: center;
      background: none;
      padding: $u-400 0 $u-400 $u-400;
      border-radius: 0;

      // .config-group__toggle-button-icon
      &-icon {
        height: $u-350;
        width: $u-350;
        fill: $c-pm-500;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  // .congig-group__body
  &__body {
    margin: $u-700 #{$config-image-width + $config-image-spacing} 0 #{$config-image-width +
      $config-image-spacing};

    @include mq($screen-md, max) {
      margin: $u-300 0 0 0;
    }
  }

  &__submit-button {
    display: block;
    margin: $u-500 auto 0;
  }
}
