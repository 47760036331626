














































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.c-compare__specification-list-row {
  display: flex;
  flex-direction: column;
  &--item {
    border-bottom: 1px solid $c-nt-300;
    padding-bottom: $u-400;
  }
  h5 {
    padding: $u-300 0;
  }
  &--values {
    display: flex;
    > div {
      flex: 1;
      text-align: center;
    }
    @include mq($screen-md, max) {
      gap: $u-250;
    }
  }
}
