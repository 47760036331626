@import 'olympus/styles/mixins/anchor-reset.scss';

@mixin c-link($theme) {
  color: map-get($theme, color);
}

.c-link {
  @include anchor-reset;
  @include c-link($c-link);
}
