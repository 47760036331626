/// EpiServer Form - Range
/// - Version 1.0.0
/// - Date last edited: 2020-03-16
/// @name epi-form-range
/// @author Bjarke Rasmussen, Valtech

@mixin c-comparison-block($theme) {
  margin-bottom: 30px;

  .c-exp__content:not(.c-exp__content--toggle):after {
    background-image: map-deep-get($theme, expand, gradient);
  }

  &__display,
  &__spec {
    @include mq($screen-sm, min) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  &__display {
    @include mq($screen-sm, min) {
      align-items: flex-end;
    }
  }

  &__spec {
    @include mq($screen-sm, min) {
      align-items: flex-start;
    }
  }

  &__display-item,
  &__spec-info {
    @include mq($screen-sm, min) {
      width: calc(100% / 3);
      flex-shrink: 0;
    }
  }

  &__inner {
    width: 100%;
  }

  // .c-comparison-block--single
  &--single {
    .c-comparison-block {
      &__header {
        width: 100%;
        flex-shrink: 0;
      }

      &__inner {
        display: flex;
        flex-wrap: wrap;
      }

      &__display {
        display: block;
        width: 100%;
        flex-shrink: 0;

        @include mq($screen-md, min) {
          width: calc(100% / 3);
          align-self: flex-end;
        }
      }

      &__display-item {
        width: 100%;
      }

      &__spec {
        display: block;
        width: 100%;
        flex-shrink: 0;

        @include mq($screen-md, min) {
          width: calc(100% / 3 * 2);
          align-self: start;
        }
      }

      &__spec-info {
        width: 100%;

        @include mq($screen-md, min) {
          column-count: 2;
          column-gap: 24px;
        }

        h3 {
          @include typography(h3);
          text-align: left;
          column-span: all;
        }
      }

      &__spec-block {
        padding-top: 6px;
        break-inside: avoid;
      }

      &__spec-icon {
        top: -2px;
      }
    }
  }

  // Tabs show/hide toggle
  & [data-tab-item] {
    display: none;

    &.is-active {
      display: block;
    }

    @include mq($screen-sm, min) {
      display: block;
    }
  }

  // .c-comparison-block__header
  &__header {
    width: 100%;
    flex-shrink: 0;
  }

  // .c-comparison-block__header-inner
  &__header-inner {
    max-width: 800px;
    margin: 20px auto;
    text-align: center;

    @include mq($screen-sm, min) {
      margin: 20px auto 40px;
    }

    h2 {
      margin-bottom: 15px;
      font-size: 32px;
      @include typography(h2, null, $global-font-family-2);

      @include mq($screen-md, min) {
        font-size: 36px;
      }
    }
  }

  // .c-comparison-block__tabs
  &__tabs {
    @include mq($screen-sm, min) {
      display: none;
    }

    ul {
      display: block;
      margin-bottom: 20px;
      text-align: center;

      li {
        display: inline-block;

        &:after {
          content: ' | ';
        }

        &:last-child:after {
          content: '';
        }
      }
    }

    button {
      display: inline-block;
      padding: 0;
      margin: 0 6px;
      border: none;
      background-color: transparent;
      font-size: 16px;

      &.is-active {
        color: map-deep-get($theme, mobileTabs, active-color);
      }
    }
  }

  // .c-comparison-block__display-item
  &__display-item {
    padding: 0 15%;
    margin-bottom: 30px;

    @include mq($screen-sm, min) {
      padding: 0;

      img {
        width: calc(100% - 24px);
        margin: 0 12px;
      }
    }
  }

  // .c-comparison-block__spec-info
  &__spec-info {
    h3 {
      @include typography(h3);
      display: block;
      margin-bottom: 30px;
      text-align: center;
      text-transform: uppercase;

      @include mq($screen-sm, min) {
        margin: 0 12px 30px;
      }

      @include mq($screen-md, min) {
        font-size: 22px;
      }
    }
  }

  // .c-comparison-block__spec-block
  &__spec-block {
    padding-left: 50px;
    margin-bottom: 30px;
    position: relative;

    @include mq($screen-sm, min) {
      margin: 0 12px 30px;
    }

    h4 {
      font-size: 16px;
      margin-bottom: 4px;
    }
  }

  // .c-comparison-block__spec-icon
  &__spec-icon {
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    left: 0;
    top: -8px;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__spec-content {
    @extend .c-article__body;
  }
}

.c-comparison-block {
  @include c-comparison-block($c-comparison-block);
}
