






























































































@import 'theme/sass/settings/_settings.vars.scss';

.vas-consent {
  &__title {
    // TODO DKT-COMING SOON: Use variable once fonts in settings.global.scss have been updated.
    font-size: 24px;
    margin-bottom: $u-400;
  }

  &__description {
    margin-bottom: $u-550;

    ::v-deep p {
      margin-bottom: $u-350;
    }
  }
}

.c-btn {
  display: block;
  margin: 0 auto;
}
