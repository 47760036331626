






























































@import '../../styles/tools/_tools.media-query.scss';
@import '../../styles/breakpoints.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.c-information-box {
  background-color: $c-pm-200;
  padding: $u-300 $u-250;
  display: flex;
  flex-direction: row;
  border-radius: $u-150;
  border-width: 2px;
  margin: unset;
  font-size: $global-font-size;
  line-height: $global-line-height-sm;

  a {
    color: $c-pm-700;
    text-decoration: underline;
  }

  // .c-information-box__content
  &__content {
    display: flex;
    flex-direction: column;

    p {
      display: inline-block;
      word-break: break-word;
      margin-bottom: $u-200;
    }

    // .c-information-box__content ul
    ul {
      list-style: disc;
      margin-left: $u-400;

      // .c-information-box__content ul li
      li {
        padding-left: $u-150;
      }
    }
  }

  // .c-information-box__icon
  &__icon {
    padding-right: $u-250;
    display: block;

    svg {
      height: $u-400;
      width: $u-400;
    }

    @include mq($breakpoint-phone-small, min) {
      position: relative;
      top: initial;
      left: initial;
      width: initial;
      height: initial;
    }
  }

  // .c-information-box--type-warning
  &--type-warning {
    color: $c-sp-orange-900;
    background-color: $c-sp-orange-100;
    border: 1px solid $c-sp-orange-300;

    // .c-information-box--type-warning .c-information-box__icon
    .c-information-box__icon {
      padding-right: $u-250;

      svg {
        fill: $c-sp-orange-500;
      }
    }
  }

  // .c-information-box--type-error
  &--type-error {
    color: $c-sp-red-900;
    background-color: $c-sp-red-100;
    border: 1px solid $c-sp-red-300;

    // .c-information-box--type-error .c-information-box__icon
    .c-information-box__icon {
      padding-right: $u-250;

      svg {
        fill: $c-sp-red-500;
      }
    }
  }
}
