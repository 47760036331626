﻿@mixin c-segment($theme: ()) {
  $root: &;
  background-color: map-get($theme, 'bgc');
  border-bottom: 1px solid 1px solid color(primary, dim, 90);
  color: map-get($theme, 'c');
  display: none;
  flex: 0 0 100%;
  font-size: map-get($theme, 'fz');

  &__details {
    flex: 1;
  }

  &__inner {
    border-top: 1px solid color(primary, dim, 90);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    position: relative;
    background-color: white;
    box-shadow: $box-shadow-default;
    box-sizing: border-box;
    flex: 0 0 100%;
    margin-bottom: 0.75em;
    min-height: 8rem;
    padding: 2em 5em;

    svg {
      display: inline-block;
      height: $u-500;
      width: $u-500;
      position: absolute;
      left: map-get($theme, icon-offset);
    }
  }

  &__item-header {
    font-family: 'Pebble', sans-serif;
    font-size: 1.25rem;
    margin: 0;
    position: relative;
    text-transform: uppercase;
  }

  &__item-link {
    color: color(primary, core);
    display: block;
    margin-top: 1em;
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__link {
    align-items: center;
    display: inline-flex;
    flex: 1;
    justify-content: center;
    padding: 0.6875em 0;
    text-decoration: none;
    color: rgba(color(primary, dark), 0.65);

    &:first-of-type {
      border-right: 1px solid 1px solid color(primary, dim, 90);
    }

    &--selected {
      border-bottom: 2px solid color(primary, core);
      color: color(primary, core);
      font-weight: bold;
      position: relative;
    }

    &--toggle {
      display: flex;
      align-items: center;

      svg {
        width: 1em;
        height: 1em;
        fill: rgba(color(primary, dark), 0.5);
        @include margin(left);
      }
    }
  }

  &__panel {
    display: none;
    justify-content: space-between;
    margin-bottom: map-get($theme, 'gutter');
    width: 100%;
  }

  /* STATE */
  .state--chn:checked + .c-segment__link--toggle {
    color: color(primary, core);
    font-weight: bold;
    user-select: none;

    svg {
      transform: scaleY(-1);
    }
  }

  .state--chn:checked + .c-segment__link + .c-segment__panel {
    display: flex;
    @include mq($screen-md, max) {
      flex-wrap: wrap;
      @include margin(horizontal, 1.5);
      @include padding(top, 2);
    }
  }

  /* RWD */
  @include mq($screen-md, min) {
    background-color: #f2f2f2;
    border-bottom: 0;
    border: 0;
    display: flex;

    &__item {
      flex: 0 0 32%;
      max-width: 32%;
    }

    &__inner {
      border: 0;
    }

    &__link {
      flex: 0 1 auto;
      margin-right: 2rem;

      &:first-of-type,
      &--selected {
        border: 0;
      }
    }
  }
}

.c-segment {
  @if variable-exists(c-segment) {
    @include c-segment($c-segment);
  }
}
