@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.credit-check {
    align-items: center;
    display: flex;
    height: 80vh;
    justify-content: center;

    &__inner {
        max-width: 344px;
        min-height: 300px;
    }

    &__title {
        font-weight: 900;
        margin-bottom: $u-700;
        text-align: center;
        text-transform: uppercase;

        &--error {
            font-size: $font-size-h2;
            margin-bottom: $u-200;
        }
    }

    &__steps {
        color: $c-nt-600;
        font-size: $font-size-h3;

        &__item {
            display: flex;
            margin-bottom: $u-400;
            opacity: 0;
            translate: 0 -20px;
            transition: opacity .5s ease-in-out, translate .5s ease-in-out;
            will-change: opacity, translate;

            &.show {
                opacity: 1;
                translate: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &__icon {
                flex-shrink: 0;
                height: 21px;
                margin: 3px $u-250 0 0;
                position: relative;
                width: 21px;

                svg {
                    margin: 0 auto;
                    max-height: 100%;
                    max-width: 100%;
                }

                &.approved svg,
                &.notneeded svg {
                    fill: $c-sp-green-700;
                }

                &.rejected svg,
                &.nofinancing svg {
                    fill: $c-sp-red-500;
                }

                // could be multiple elements - div, span, svg, but they should all animate the same way
                >* {
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    right: 0;
                    scale: 0;
                    top: 0;
                    transition: scale .3s;
                    visibility: hidden;
                    will-change: opacity;

                    &.show {
                        scale: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }

    &__error {
        text-align: center;

        &__icon {
            height: 78px;
            margin-bottom: $u-700;
            width: 78px;
        }

        &__content {
            font-size: $font-size-h5;
            margin-bottom: $u-600;
        }
    }
}