



















































































































































@import 'theme/sass/settings/_settings.vars.scss';

.c-box {
  height: calc(100% - #{$u-300});
  margin-bottom: $u-300;
}

dl {
  display: grid;
  grid-template-columns: 200px auto;
  grid-gap: $u-150;
}

dt {
  grid-column: 1;
}

dd {
  grid-column: 2;
  font-weight: bold;
}

dt,
dd {
  margin-bottom: $u-150;
}
