









































































// TODO TC-4909: settings instead?
$u-300: 20px !default;
$gutter-width: $u-300 !default;
$half-gutter-width: $gutter-width * 0.5;

.c-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$half-gutter-width;
  margin-left: -$half-gutter-width;

  // .c-row--justified
  // .c-row--between
  &--justified,
  &--between {
    justify-content: space-between;
  }

  &--justified-center {
    justify-content: center;
  }

  &--justified-end {
    justify-content: end;
  }

  // TODO TC-4909: add more
  // .c-row--column
  &--column {
    flex-direction: column;
  }

  // TODO TC-4909: add more
  // .c-row--align-items-flex-end
  &--align-items-flex-end {
    align-items: flex-end;
  }

  // .c-row--no-gutters
  &--no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > .c-col,
    & > [class*='c-col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // .c-row--no-wrap
  &--no-wrap {
    flex-wrap: nowrap;
  }

  @for $i from 2 through 5 {
    // .c-row--gutters-{i:2-5}
    &--gutters-#{$i} {
      margin-right: -$half-gutter-width * $i;
      margin-left: -$half-gutter-width * $i;

      & > .c-col {
        padding-right: $half-gutter-width * $i;
        padding-left: $half-gutter-width * $i;
      }
    }
  }
}
