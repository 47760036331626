








































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

$plug-icon-width: 154px;

.plug-config {
  width: 100%;
  display: flex;
  flex-flow: column;

  &__skeleton-loader {
    &__title {
      height: 20px;
      margin-bottom: $u-300;
    }

    &__description {
      height: 40px;
      margin-bottom: $u-500;
    }

    &__plug-type {
      height: 150px;
      margin: 0 $u-500;
      width: $plug-icon-width;
    }

    &__radio {
      height: 58px;
      margin-bottom: $u-200;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__plug {
    margin-bottom: $u-800;

    &__icon {
      margin: 0 $u-500;
      width: $plug-icon-width;

      &:not(:first-child) {
        border-top: 1px dashed $c-nt-500;
        margin-top: $u-250;
        padding-top: $u-250;
      }
    }

    &__information {
      margin-bottom: $u-600;
    }
  }
}
