








































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';

$tooltip-max-w-small: 350px;
$tooltip-max-w-large: 550px;

// Disabling rule since styles are coming from 3rd party plugin
// src: https://github.com/Akryum/v-tooltip#sass--less
// stylelint-disable declaration-no-important
.tooltip {
  display: block !important;
  z-index: 10000;
  position: relative;

  &--small {
    max-width: $tooltip-max-w-small;
  }

  &--large {
    max-width: $tooltip-max-w-large;
  }

  // Show pointer only on the tooltip target element
  &__container {
    cursor: pointer;
    position: relative;
  }

  .tooltip__popover__description {
    font-size: $global-font-size-md;
  }

  .tooltip__popover-close-button {
    border-radius: 0;
    position: absolute;
    padding: $u-200;
    height: auto;
    right: 0;
    top: 0;

    // .config-group__toggle-button-icon
    &-icon {
      height: $u-350;
      width: $u-350;
      fill: $c-pm-500;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .tooltip-inner {
    background: $color-white;
    border-radius: 2px;
    box-shadow: $box-shadow-hover;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: $u-150;
    border-color: $color-white;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: $u-150;

    .tooltip-arrow {
      border-width: $u-150 $u-150 0 $u-150;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -$u-150;
      left: calc(50% - #{$u-150});
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: $u-150;

    .tooltip-arrow {
      border-width: 0 $u-150 $u-150 $u-150;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -$u-150;
      left: calc(50% - #{$u-150});
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: $u-150;

    .tooltip-arrow {
      border-width: $u-150 $u-150 $u-150 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -$u-150;
      top: calc(50% - #{$u-150});
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: $u-150;

    .tooltip-arrow {
      border-width: $u-150 0 $u-150 $u-150;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -$u-150;
      top: calc(50% - #{$u-150});
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    .popover-inner {
      padding: $u-400 $u-800 - $u-150 $u-400 $u-400;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
