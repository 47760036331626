











































































@import '../../styles/tools/_tools.media-query.scss';
@import '../../styles/breakpoints.scss';

// TODO TC-4909: settings instead?
$u-300: 20px !default;
$grid-columns: 12 !default;
$gutter-width: $u-300 !default;
$half-gutter-width: $gutter-width * 0.5;

// TODO TC-4909: add grid mixins etc. and move somewhere out of here
@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;
  @include flex-grow(0);
  @include flex-shrink(0);
}

.c-col {
  position: relative;
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
  width: 100%;

  // TODO TC-4909: add more?
  &--align-self-flex-start {
    align-self: flex-start;
  }

  &--align-content-center {
    align-content: center;
    display: flex;
  }

  &--align-self-flex-end {
    align-self: flex-end;
  }

  &--align-self-center {
    align-self: center;
  }

  @for $i from 1 through $grid-columns {
    // .c-col--{i}
    &--#{$i} {
      max-width: 100% / $grid-columns * $i;
      @include flexboxgrid-sass-col-common;
      @include flex-basis(100% / $grid-columns * $i);
    }

    // .c-col--xs-{i}
    &--xs-#{$i} {
      @include mq($breakpoint-phablet, max) {
        max-width: 100% / $grid-columns * $i;
        @include flexboxgrid-sass-col-common;
        @include flex-basis(100% / $grid-columns * $i);
      }
    }

    // .c-col--sm-{i}
    &--sm-#{$i} {
      @include mq($breakpoint-tablet, max) {
        max-width: 100% / $grid-columns * $i;
        @include flexboxgrid-sass-col-common;
        @include flex-basis(100% / $grid-columns * $i);
      }
    }

    // .c-col--md-{i}
    &--md-#{$i} {
      @include mq($breakpoint-tablet, min) {
        max-width: 100% / $grid-columns * $i;
        @include flexboxgrid-sass-col-common;
        @include flex-basis(100% / $grid-columns * $i);
      }
    }

    // .c-col--lg-{i}
    &--lg-#{$i} {
      @include mq($breakpoint-desktop, min) {
        max-width: 100% / $grid-columns * $i;
        @include flexboxgrid-sass-col-common;
        @include flex-basis(100% / $grid-columns * $i);
      }
    }
  }
}
