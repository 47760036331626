// Find store

$fs-side-block-width: 380px;

$fs-wrapper-min-height: 500px;
$fs-wrapper-height-tablet: 700px;
$fs-wrapper-height-laptop: 600px;
$fs-wrapper-height-monitor: 850px;

$fs-border-color--primary: $c-nt-300;
$fs-border-color--light: $c-nt-200;
$fs-border-thickness: 1px;

$fs-background-color: $color-white;
$fs-hover-background-color: $c-nt-200;

$fs-color--norlys: $c-pm-500;
$fs-color--partner: #40b2ff;

$fs-gap-xsm: $u-150;
$fs-gap-sm: $u-200;
$fs-gap-md: $u-250;
$fs-gap-lg: $u-300;

$fs-font-weight--light: 300;

// fs = "findstore"
.fs {
  // fs__wrapper
  &__wrapper {
    display: flex;
    width: 100%;
    background: $fs-background-color;
    height: auto;

    &--fixed {
      // fs__wrapper--fixed-min-height
      &-min-height {
        min-height: $fs-wrapper-min-height;
      }

      // fs__wrapper--fixed-height
      &-height {
        // Make the map height fill full height, excluding navbar and breadcrumb approx height, give and take
        height: calc(100vh - var(--map-height) - 70px);

        // Larger than mobile
        @include mq($screen-xs, min, width) {
          height: $fs-wrapper-height-tablet;
        }

        // Larger than tablets
        @include mq($screen-md, min, width) {
          height: $fs-wrapper-height-laptop;
        }

        // larger than desktop and laptops
        @include mq($screen-extra, min, width) {
          height: $fs-wrapper-height-monitor;
        }
      }
    }
  }
  // fs__map
  &__map {
    height: 100%;

    // fs__map-wrapper
    &-wrapper {
      width: 100%;
      // TODO DKT-1411: Refactor when new mobile design is introduced, where the map is visible on mobile
      @include till-phablet {
        display: none;
      }
    }

    // fs__map-control
    &-control {
      z-index: 1;
      margin: $u-350;
      padding: 14px $u-350;
      border-radius: $u-350;
      cursor: pointer;
      background-color: $fs-background-color;
      transition: background-color 0.3s;

      // fs__map-control-text
      &-text {
        text-align: center;
        font-size: $global-font-size-md;
        font-family: $global-font-family;

        // fs__map-control-text--gap-left
        &--gap {
          &-left {
            margin-left: $fs-gap-sm;
          }
        }
      }
    }
  }

  // fs__side-block
  &__side-block {
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 1px 1px #50505036;
    z-index: 1;

    // fs__side-block--fixed-width
    &--fixed-width {
      min-width: $fs-side-block-width;
      max-width: $fs-side-block-width;
      // TODO DKT-1411: Should be rewritten when the new mobile design is implemented
      @include till-phablet {
        min-width: 100%;
        max-width: unset;
      }
    }

    @include till-phablet {
      box-shadow: unset;
    }
  }

  // fs__header
  &__header-block {
    margin: $u-350 0px;
  }

  // fs__main-headline
  &__main-headline {
    font-size: $font-size-h1;
    font-weight: 700;
  }

  // fs__search
  &__search {
    position: relative;
  }

  // fs__list
  &__list {
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: $fs-border-thickness solid $fs-border-color--light;
    // fs__list--full-height
    &--full-height {
      height: 100%;
    }

    // fs__list-item
    &-item {
      transition: 0.2s ease-out;
      border-bottom: $fs-border-thickness solid $fs-border-color--light;
      padding-top: $fs-gap-lg;
      padding-bottom: $fs-gap-lg;

      // fs__list-item:hover
      &:hover {
        background-color: $fs-hover-background-color;
      }

      // fs__list-item-details
      &-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
      }

      // fs__list-item-headline
      &-headline {
        font-weight: 700;

        // fs__list-item-headline--sm
        &--sm {
          font-size: $font-size-h5;
        }

        // fs__list-item-headline--md
        &--md {
          font-size: $font-size-h4;
        }

        // fs__list-item-headline--lg
        &--lg {
          font-size: $font-size-h3;
        }

        // fs__list-item-headline--is-bold
        &--is-bold {
          font-weight: 700;
        }
      }

      // fs__list-item-address
      &-address {
        font-size: $global-font-size-md;
        font-style: normal;

        &--is-light {
          font-weight: $fs-font-weight--light;
        }
      }

      // fs__list-item-symbols
      &-symbols {
        white-space: nowrap;

        // fs__list-item-symbols-row
        &-row {
          font-size: $global-font-size-sm;
          text-align: right;
          width: 70px;
          display: flex;
          justify-content: space-between;
          line-height: 22px;
        }

        // fs__list-item-symbols-text
        &-text {
          font-size: $global-font-size-sm;
          display: inline-block;
          // Adding higher specificity to v-cloak, since the parent has the display property set
          &[v-cloak] {
            display: none;
          }
        }
      }

      // fs__list-item-option
      &-option {
        font-style: normal;
        color: $fs-color--norlys;
        text-decoration: none;
        vertical-align: top;
        align-self: center;

        // fs__list-item-option--gap-left
        &--gap {
          &-left {
            margin-left: $fs-gap-sm;
          }
        }

        // fs__list-item-option--small-text
        &--small-text {
          font-size: $global-font-size-md;
        }

        // fs__list-item-option:hover
        &:hover {
          text-decoration: none;
        }
      }

      // fs__list-item-hours
      &-hours {
        display: flex;
        justify-content: space-between;
        font-size: $global-font-size-md;

        // fs__list-item-hours--line-below
        &--line-below {
          border-bottom: 1px solid $c-nt-200;
        }

        // fs__list-item-hours--spacing
        &--spacing {
          padding: $u-200 0;
          line-height: 20px;
        }
      }

      &--flex {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
      }
    }

    // fs__list ul, fs__list ol
    & ul,
    ol {
      list-style-type: none;
      padding: unset;
    }
  }

  // fs__icon
  &__icon {
    height: 16px;
    width: 16px;

    &--fill {
      // fs__icon--fill-primary
      &-primary {
        fill: $fs-color--norlys;
      }

      // fs__icon--fill-secondary
      &-secondary {
        fill: $fs-color--partner;
      }
    }

    &--align {
      // fs__icon--align-text-bottom
      &-text-bottom {
        vertical-align: text-bottom;
      }
    }

    // fs__icon-dropdown-toggle
    &-dropdown-toggle {
      width: 12px;
      height: 12px;
      transition: transform 0.2s ease;
      will-change: transform;

      // fs__icon-dropdown-toggle--expanded
      &--expanded {
        transform: rotate(180deg);
      }
    }
  }

  /* Styles to customize the Google Maps 'InfoWindow' component */
  // fs__info-window
  &__info-window {
    // fs__info-window-wrapper
    &-wrapper {
      background-color: $fs-background-color;
      max-width: 180px;
      min-width: 180px;
    }

    // fs__info-window-image
    &-image {
      width: 180px;
      max-height: 200px;
    }

    // fs__info-window-headline
    &-headline {
      font-size: $font-size-h5;
      font-weight: 700;
      color: $c-nt-900;
      margin-left: $u-250;
      margin-right: $u-200;
      margin-top: $u-200;
      margin-bottom: $u-200;
      max-width: 150px;
    }

    // fs__info-window-description
    &-description {
      color: $c-nt-900;
      font-size: $global-font-size-sm;
      font-family: $global-font-family;
      max-width: 150px;
      margin-left: 12px;
      margin-bottom: 13px;
      margin-top: $fs-gap-xsm;
      overflow: hidden;
    }
  }

  // fs--padding
  &--padding {
    // fs--padding-top
    &-top {
      // fs--padding-top-xsm
      &-xsm {
        padding-top: $fs-gap-xsm;
      }

      // fs--padding-top-sm
      &-sm {
        padding-top: $fs-gap-sm;
      }

      // fs--padding-top-md
      &-md {
        padding-top: $fs-gap-md;
      }

      // fs--padding-top-lg
      &-lg {
        padding-top: $fs-gap-lg;
      }
    }
  }

  // fs--is-focused
  &--is-focused {
    background: $fs-hover-background-color;
  }

  &--is-large-text {
    font-size: $font-size-h3;
  }
}

/* Google Maps default InfoWindow style overwrites */
/* Must be there to stretch the image and text to the window edges */
.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: $fs-background-color;
  border-radius: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
}

// gm = google maps
.gm {
  &-style {
    & .gm-style-iw-c {
      position: absolute;
      box-sizing: border-box;
      overflow: hidden;
      top: 0;
      left: 0;
      transform: translate(-50%, -100%);
      background-color: $fs-background-color;
      border-radius: 0 !important;
      padding: 0 !important;
      box-shadow: none !important;
    }

    & .gm-style-iw-d {
      overflow: hidden !important;
    }
  }

  &-ui-hover-effect {
    opacity: 1 !important;

    & img {
      filter: invert(100%) !important;
    }
  }
}
