





























































































































































































































































































@import 'olympus/styles/media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.c-order-list {
  &__filter {
    margin-bottom: $u-400;

    &__group {
      &--status {
        margin: 0;
      }
      &--status,
      &--date {
        @include till-phablet {
          margin-top: $u-200;
        }
      }
    }
  }

  &__table-container {
    overflow: auto;

    table {
      width: 100%;
    }

    td,
    th {
      border-width: 1px 0 0 0;
    }

    &__action-cell {
      text-align: right;
    }
  }
}

.load-more {
  padding: $u-200;
  background: $color-white;
  text-align: center;
  cursor: pointer;
}
