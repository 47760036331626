




































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.checkout-banselector-payment {
  .c-information-box {
    margin-bottom: $u-600;
  }

  &-type {
    border: 1px solid $c-nt-300;
    padding: 1px; // compensate for the thinner border
    border-radius: $u-200;
    background: $color-white;
    margin-bottom: $u-250;

    // .checkout-banselector-payment-type--active
    &--active {
      border: 2px solid $color-border-focus;
      padding: 0; // no padding due to the thicker border
    }

    &__info {
      width: 100%;
      padding: $u-300;
      display: flex;
      justify-content: space-between;
      font-size: $font-size-h4;

      &__left {
        display: flex;

        &__title {
          display: flex;
          align-items: center;
          color: $c-nt-900;
          font-weight: 700;
          text-align: left;
        }
      }

      &__right__title {
        display: flex;
        align-items: center;
        color: $c-nt-900;
        font-weight: 700;
        text-align: right;
      }
    }

    // .checkout-banselector-payment-type__image
    &__image {
      min-width: $u-600;
      min-height: $u-600;
      overflow: hidden;
      border-radius: $u-200;
      margin-right: $u-250;
      display: flex;
      align-items: center;
      justify-content: center;

      // .checkout-banselector-payment-type__image__icon
      &__icon {
        fill: $color-white;
        width: $u-600;
        height: $u-600;
      }
    }

    // .checkout-banselector-payment-type__content
    &__content {
      margin: 0 $u-250 $u-250;

      @include mq($screen-sm, min) {
        margin: 0 $u-850 $u-700 $u-850;
      }
    }
  }
}
