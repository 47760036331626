/**
 * oneblock-aspect.css
 * @version 1.1.20
 * @summary 18-08-2020
 * @author Mads Stoumann
 * @description OneBlock: Aspect Ratios
 */

/* Aspect Ratio
===============================
Prefix:
-W Background Image/Video Wrapper

Key:
-ar

Value:
0 Default, 16:9
1 1:1 Equal Square
2 1:2 Double Height
3 3:1
4 1.85:1 Cinema Film
5 2:3
6 2.35:1 Cinemascope
7 3:2 35mm Film
8 4:3 SDTV
9 5:4 Computer Display
10 4:1
90 None
===============================
*/
.-W-ar1 .c-ob__bg-wrapper::after,
.c-ob.-W-ar1.-O-ly7::after {
  padding-bottom: 100%;
}

.-W-ar2 .c-ob__bg-wrapper::after,
.c-ob.-W-ar2.-O-ly7::after {
  padding-bottom: 80%;
}

.-W-ar3 .c-ob__bg-wrapper::after,
.c-ob.-W-ar3.-O-ly7::after {
  padding-bottom: 75%;
}

.-W-ar4 .c-ob__bg-wrapper::after,
.c-ob.-W-ar4.-O-ly7::after {
  padding-bottom: 66.666666%;
}

.-W-ar5 .c-ob__bg-wrapper::after,
.c-ob.-W-ar5.-O-ly7::after {
  padding-bottom: 54.054%;
}

.-W-ar6 .c-ob__bg-wrapper::after,
.c-ob.-W-ar6.-O-ly7::after {
  padding-bottom: 42.553%;
}

.-W-ar7 .c-ob__bg-wrapper::after,
.c-ob.-W-ar7.-O-ly7::after {
  padding-bottom: 33.333333%;
}

.-W-ar8 .c-ob__bg-wrapper::after,
.c-ob.-W-ar8.-O-ly7::after {
  padding-bottom: 150%;
}

.-W-ar9 .c-ob__bg-wrapper::after,
.c-ob.-W-ar9.-O-ly7::after {
  padding-bottom: 200%;
}

.-W-ar10 .c-ob__bg-wrapper::after,
.c-ob.-W-ar10.-O-ly7::after {
  padding-bottom: 25%;
}

.-W-ar11 .c-ob__bg-wrapper::after,
.c-ob.-W-ar11.-O-ly7::after {
  padding-bottom: 133.33%;
}

.-W-ar12 .c-ob__bg-wrapper::after,
.c-ob.-W-ar12.-O-ly7::after {
  padding-bottom: 125%;
}

.-W-ar13 .c-ob__bg-wrapper::after,
.c-ob.-W-ar13.-O-ly7::after {
  padding-bottom: 177.78%;
}

.-W-ar90 .c-ob__bg-wrapper::after,
.c-ob.-W-ar90.-O-ly7::after {
  padding-bottom: 0%;
}
