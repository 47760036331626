/*
TODO TC-4896: review this file and eventually remove all icon classes that are not being used.
These classes should target the branded SVG files that are now being served from backend paths 
*/
// sass-lint:disable-all
$icomoon-font-path: '../fonts/icons' !default;
$cm-icon-calendar: '\e903';
$cm-icon-angle-right: '\e902';
$cm-icon-octagon: '\e901';
$cm-icon-gallery: '\e900';
$cm-icon-lyd: '\e93d';
$cm-icon-update: '\e91d';
$cm-icon-adgangskode: '\e91e';
$cm-icon-arrow-left: '\e91f';
$cm-icon-arrow-right: '\e920';
$cm-icon-bluetooth2: '\e921';
$cm-icon-cart2: '\e922';
$cm-icon-computer2: '\e923';
$cm-icon-dokument: '\e924';
$cm-icon-drift2: '\e925';
$cm-icon-facebook2: '\e926';
$cm-icon-favorit2: '\e927';
$cm-icon-forbrug: '\e928';
$cm-icon-forsikring: '\e929';
$cm-icon-forsikring_2: '\e92a';
$cm-icon-gps: '\e92b';
$cm-icon-hjerte: '\e92d';
$cm-icon-indstilliner_2: '\e92e';
$cm-icon-indstillinger: '\e92f';
$cm-icon-info: 'i';
$cm-icon-kamera: '\e930';
$cm-icon-kontakt: '\e931';
$cm-icon-network2: '\e933';
$cm-icon-network_2: '\e934';
$cm-icon-profil: '\e935';
$cm-icon-sim-kort: '\e936';
$cm-icon-smartphone: '\e937';
$cm-icon-smartphone_2: '\e938';
$cm-icon-sms2: '\e939';
$cm-icon-søg: '\e93a';
$cm-icon-udland2: '\e93b';
$cm-icon-wi-fi: '\e93c';
$cm-icon-search: '\e93a';
$cm-icon-delivery-truck: '\e93b';

@font-face {
  font-family: 'callme';
  src: url('#{$icomoon-font-path}/callme.ttf?g8olqu') format('truetype'),
    url('#{$icomoon-font-path}/callme.woff?g8olqu') format('woff'),
    url('#{$icomoon-font-path}/callme.svg?g8olqu#callme') format('svg');
  font-weight: normal;
  font-style: normal;
}

#{$prefix} {
  // .cm-icon
  &icon {
    font-family: 'callme' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // .cm-icon--2x
    &--2x {
      font-size: $global-font-size * 2;
    }
  }
  // .cm-icon-calendar
  &icon-calendar {
    &:before {
      content: $cm-icon-calendar;
    }
  }
  // .cm-icon-angle-right
  &icon-angle-right {
    &:before {
      content: $cm-icon-angle-right;
    }
  }
  // .cm-icon-octagon
  &icon-octagon {
    &:before {
      content: $cm-icon-octagon;
    }
  }
  // .cm-icon-gallery
  &icon-gallery {
    &:before {
      content: $cm-icon-gallery;
    }
  }

  &icon-lyd {
    &:before {
      content: $cm-icon-lyd;
    }
  }

  &icon-update {
    &:before {
      content: $cm-icon-update;
    }
  }

  &icon-adgangskode {
    &:before {
      content: $cm-icon-adgangskode;
    }
  }

  &icon-arrow-left {
    &:before {
      content: $cm-icon-arrow-left;
    }
  }

  &icon-arrow-right {
    &:before {
      content: $cm-icon-arrow-right;
    }
  }

  &icon-bluetooth2 {
    &:before {
      content: $cm-icon-bluetooth2;
    }
  }

  &icon-cart2 {
    &:before {
      content: $cm-icon-cart2;
    }
  }

  &icon-computer2 {
    &:before {
      content: $cm-icon-computer2;
    }
  }

  &icon-dokument {
    &:before {
      content: $cm-icon-dokument;
    }
  }

  &icon-drift2 {
    &:before {
      content: $cm-icon-drift2;
    }
  }

  &icon-facebook2 {
    &:before {
      content: $cm-icon-facebook2;
    }
  }

  &icon-favorit2 {
    &:before {
      content: $cm-icon-favorit2;
    }
  }

  &icon-forbrug {
    &:before {
      content: $cm-icon-forbrug;
    }
  }

  &icon-forsikring {
    &:before {
      content: $cm-icon-forsikring;
    }
  }

  &icon-forsikring_2 {
    &:before {
      content: $cm-icon-forsikring_2;
    }
  }

  &icon-gps {
    &:before {
      content: $cm-icon-gps;
    }
  }

  &icon-hjerte {
    &:before {
      content: $cm-icon-hjerte;
    }
  }

  &icon-indstilliner_2 {
    &:before {
      content: $cm-icon-indstilliner_2;
    }
  }

  &icon-indstillinger {
    &:before {
      content: $cm-icon-indstillinger;
    }
  }

  &icon-info {
    &:before {
      content: $cm-icon-info;
      font-style: italic;
      position: relative;
      top: -2px;

      @supports (-ms-ime-align: auto) {
        top: 0;
      }
    }
  }

  &icon-kamera {
    &:before {
      content: $cm-icon-kamera;
    }
  }

  &icon-kontakt {
    &:before {
      content: $cm-icon-kontakt;
    }
  }

  &icon-network2 {
    &:before {
      content: $cm-icon-network2;
    }
  }

  &icon-network_2 {
    &:before {
      content: $cm-icon-network_2;
    }
  }

  &icon-profil {
    &:before {
      content: $cm-icon-profil;
    }
  }

  &icon-sim-kort {
    &:before {
      content: $cm-icon-sim-kort;
    }
  }

  &icon-smartphone {
    &:before {
      content: $cm-icon-smartphone;
    }
  }

  &icon-smartphone_2 {
    &:before {
      content: $cm-icon-smartphone_2;
    }
  }

  &icon-sms2 {
    &:before {
      content: $cm-icon-sms2;
    }
  }

  &icon-søg {
    &:before {
      content: $cm-icon-søg;
    }
  }

  &icon-search {
    &:before {
      content: $cm-icon-search;
    }
  }

  &icon-udland2 {
    &:before {
      content: $cm-icon-udland2;
    }
  }

  &icon-wi-fi {
    &:before {
      content: $cm-icon-wi-fi;
    }
  }

  &icon-delivery-truck {
    &:before {
      content: $cm-icon-delivery-truck;
    }
  }
}

// sass-lint:enable-all
