@mixin checkmark(
  $left: $u-250,
  $border-color: $color-white,
  $border-width: 2px,
  $width: 6px,
  $degree: 45deg,
  $heightisequal: false,
  $top: 50%
) {
  border-color: $border-color;
  border-width: 0 $border-width $border-width 0;
  border-style: solid;
  content: '';
  //if height is equal to width
  @if $heightisequal == true {
    height: $width;
    margin-top: -$width/2;
  } @else {
    height: $width * 2;
    margin-top: -$width;
  }

  left: $left;
  position: absolute;
  top: $top;
  width: $width;
  transform: rotate(#{$degree});

  @if $left == 50% {
    margin-left: -$width/2;
  }
}

@mixin max-lines($number-of-lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $number-of-lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

@mixin background-blur($background-color: #fff) {
  @supports (backdrop-filter: none) {
    backdrop-filter: blur(27px);

    &:before {
      content: '';
      position: fixed;
      z-index: -1;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background: $background-color;
      opacity: 0.8;
    }
  }

  @supports not (backdrop-filter: none) {
    background-color: $background-color;
  }
}
