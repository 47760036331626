












































































































@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.config-group-item {
  border: 1px solid $c-nt-300;
  border-radius: $u-200;
  background: $color-white;
  margin-bottom: $u-250;

  // .config-group-item--active
  &--active {
    border: 2px solid $color-border-focus;
  }

  // .config-group-item--integrated
  &--integrated {
    border: none !important;
  }

  // .config-group-item__image
  &__image {
    min-width: $u-600;
    min-height: $u-600;
    overflow: hidden;
    margin-right: $u-250;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq($screen-sm, min) {
      margin-right: $u-350;
    }
  }

  // .config-group-item__icon
  &__icon {
    color: $color-black;
    fill: $color-black;
    width: $u-400;
    height: $u-400;
  }

  // .config-group-item__info
  &__info {
    width: 100%;
    padding: $u-250;
    display: flex;
  }

  // .config-group-item__text
  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $c-nt-600;
    text-align: left;
    font-size: $font-size-h5;
    font-weight: 400;
  }

  // .config-group-item__title
  &__title {
    display: flex;
    color: $c-nt-900;
    font-size: $font-size-h4;
    font-weight: 700;
  }

  // .config-group-item__right-text
  &__right-text {
    color: $c-nt-900;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-weight: 700;
    justify-content: flex-start;

    &--center {
      justify-content: center;
    }
  }

  // .config-group-item__button
  &__button {
    padding: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  // .congig-group-item__body
  &__body {
    margin: 0 $u-250 $u-250;

    // config-group-item_body--integrated
    &--integrated {
      margin: 0 !important;
    }

    @include mq($screen-sm, min) {
      margin: 0 $u-850 $u-700 $u-850;
    }
  }
}
