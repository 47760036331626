






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.shipping {
  position: relative;

  &__options {
    margin-bottom: $u-750;
  }

  &__disclaimer {
    margin-bottom: $u-400;
  }

  &__radio {
    &__title {
      display: flex;
      font-weight: 700;
      justify-content: space-between;
    }

    &__text {
      font-size: $font-size-h5;
    }
  }

  &__locations {
    margin-top: $u-300;
    max-height: 450px;
    overflow: auto;
    padding-right: $u-250;
    position: relative;

    .cm-spinner {
      min-height: 300px;
    }

    &__pickuppoint {
      color: $c-nt-600;
      font-size: $font-size-h5;
      line-height: 20px;

      &__text {
        margin-bottom: $u-100;

        strong {
          color: $c-nt-900;
          font-size: $font-size-h4;
        }

        p {
          margin-bottom: $u-100;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &__distance {
        float: right;
      }

      &__opening-hours {
        width: 100%;

        p {
          margin-bottom: $u-100;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__overlay {
    &__title {
      font-family: $global-font-family-2;
    }

    &__body {
      margin: $u-400 auto $u-550;

      ::v-deep p {
        margin-bottom: $u-350;
      }
    }

    &__footer {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__geolocation {
    align-items: center;
    display: flex;
    margin-top: $u-450;
  }

  .c-btn--icon {
    height: auto;
    padding: $u-150 $u-300;
    // TODO: Icon button needs attention
    max-width: unset;
    max-height: unset;

    svg {
      fill: $color-white;
      height: 17px;
      margin-right: $u-200;
      width: 13px;
    }
  }

  .c-btn--link {
    height: 1rem;
    vertical-align: initial;
  }
}
