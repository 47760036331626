












.c-container {
  position: relative;
  display: block;
  max-width: 960px; // TODO TC-4923: breakpoint instead?
  margin: 0 auto;
  overflow-x: hidden;
}
