/**
 * oneblock-hover.css
 * @version 1.0.00
 * @summary 15-10-2019
 * @author Mads Stoumann
 * @description OneBlock: Hover Effect
*/
/*
===============================
Prefix:
-O OneBlock

Key:
-hv

Value:
1-?
===============================
*/

.-O-hv1,
.-O-hv2 {
  transition: all 0.16s ease-out;
}
.-O-hv1:hover {
  transform: scale(1.03);
}
.-O-hv2:hover {
  box-shadow: var(--ob-O-bx-hv2);
}
