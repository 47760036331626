/* Units */
$u-100: 1px !default;
$u-125: 2px !default;
$u-150: 4px !default;
$u-200: 8px !default;
$u-250: 12px !default;
$u-300: 16px !default;
$u-350: 20px !default;
$u-400: 24px !default;
$u-450: 28px !default;
$u-500: 32px !default;
$u-550: 36px !default;
$u-600: 40px !default;
$u-650: 44px !default;
$u-700: 48px !default;
$u-750: 52px !default;
$u-800: 64px !default;
$u-850: 72px !default;
$u-900: 80px !default;

/* Legacy box-shadows */
// TODO:10298: Variables to delete when going through this task
$bxsh-100: 0 0 2px 0 rgba(153, 153, 153, 0.12),
  0 2px 2px 0 rgba(153, 153, 153, 0.24);
$bxsh-200: 0 2px 2px rgba(153, 153, 153, 0.24),
  0 0 2px rgba(153, 153, 153, 0.12);