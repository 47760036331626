























































@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'src/shared/styles/tools/_tools.target.scss';

// .product-short-description
.product-short-description {
  order: map-deep-get($product-order, 'description', 'xs');
  text-align: center;
  line-height: 1.3;
  /**
        * TODO DKT-1433: Remove when Edge 44 is not supported anymore
        *
        * HACK: overriding inherited and too specific styling
        * from product-wizard.vue
        */
  /* stylelint-disable declaration-no-important */
  @include targetEdge() {
    width: 100% !important;
  }

  @include mq($screen-md, min) {
    order: map-deep-get($product-order, 'description', 'md');
    text-align: left;
  }

  p {
    font-family: $global-font-family-2;
    font-size: $font-size-h3;
    line-height: $global-line-height-sm;
  }

  &__link {
    display: inline-block;
    font-size: $font-size-h5;
    line-height: 1.6rem;
    text-transform: none;
    color: $c-pm-500;
    transition: 0.3s color;
    margin-top: $u-150;

    &:hover,
    &:focus {
      transition: 0.3s color;
      color: $c-pm-800;
    }
  }
}
