



















.checkout-payment-acceptance {
  // .checkout-payment-acceptance__description
  &__description {
    & ul {
      list-style-type: disc;
      margin: 16px 0;
      padding-left: 40px;
    }
  }
}
