






















.c-table {
  width: 100%;

  & th, & td {
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
  }

  & > thead {
    text-align: left;

    & th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
    }
  }

  & > tbody {
    & > tr {
      &:last-child {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }

  &--striped {
    & tbody {
      & tr {
        &:nth-of-type(odd) {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
