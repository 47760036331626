






























































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';

$content-width: 384px;

.extra-data-card-overlay {
  margin: 0 auto;
  max-width: $content-width;
  text-align: center;

  &__title {
    font-family: $global-font-family-2;
    margin-bottom: $u-150;
    text-transform: uppercase;
  }

  &__description {
    margin-bottom: $u-800;
  }

  .cm-c-product-card-simple {
    margin-left: 0;
    text-align: initial;
  }
}
