









































































































































































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.subscription-page {
  @include mq($screen-md, max) {
    background: $color-white;
    padding: 0 $u-300;
  }

  // .subscription-page__attribute-selector
  &__attribute-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    width: 100%;
    padding: 0 $u-250 $u-850 $u-250;
  }
}
