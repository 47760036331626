













































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
.c-compare__icon-row {
  display: flex;
  gap: $u-400;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: $u-300 0 $u-500;
  padding: 0 $u-400;
  &--item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: $u-150;
    svg {
      height: 128px;
      width: 128px;
      margin-bottom: $u-150;
      @include mq($screen-md, max) {
        height: 92px;
        width: 92px;
      }
    }
  }
}
