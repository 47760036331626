























































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$button-size: $u-350;
$arrow-width: 3px;
$button-rounded-size: 68px;
$button-rounded-size-small: $u-600;

#carousel .VueCarousel-navigation-button {
  align-items: center;
  background: $color-white;
  border-radius: 50%;
  box-shadow: $box-shadow-default;
  display: flex;
  height: $button-rounded-size;
  width: $button-rounded-size;
  justify-content: center;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  will-change: box-shadow;
  top: 190px;

  &__arrow {
    border-bottom: $arrow-width solid black;
    border-right: $arrow-width solid black;
    content: '';
    display: block;
    height: $button-size;
    width: $button-size;
    border-color: $c-pm-500;
    border-width: 2px;
    transition: border-color 0.2s ease;
    will-change: border-color, transform;

    &--next {
      transform: translateX(-25%) rotate(-45deg);
    }

    &--prev {
      transform: translateX(25%) rotate(135deg);
    }
  }
}

#carousel .VueCarousel-navigation-prev {
  transform: translateY(-55%) translateX(-110%);
}

#carousel .VueCarousel-navigation-next {
  transform: translateY(-55%) translateX(35%);

  .broadband-offerings-page & {
    transform: translateY(-55%) translateX(110%);

    @include mq($screen-xxl, max) {
      transform: translateY(-55%) translateX(10%);
    }
  }
}

#carousel .VueCarousel-navigation--disabled {
  display: none;
}

.home-office {
  top: 0;

  .VueCarousel-inner {
    padding-bottom: $u-800;
  }
}
