// Breakpoints
// Usage: @include mq($screen-sm, max) {}
$container-tiny: 320px;
$container-xs: 480px;
$container-sm: 600px;
$container-md: 800px;
$container-lg: 960px;
$container-xl: 1200px;
$container-xxl: 1400px;
$container-extra: 1800px;

$screen-tiny: $container-tiny;
$screen-xs: $container-xs;
$screen-sm: $container-sm;
$screen-md: $container-md;
$screen-lg: $container-lg;
$screen-xl: $container-xl;
$screen-xxl: $container-xxl;
$screen-extra: $container-extra;

:root {
  --screen-tiny: #{$container-tiny};
  --screen-xs: #{$container-xs};
  --screen-sm: #{$container-sm};
  --screen-md: #{$container-md};
  --screen-lg: #{$container-lg};
  --screen-xl: #{$container-xl};
  --screen-xxl: #{$container-xxl};
  --screen-extra: #{$container-extra};
}
