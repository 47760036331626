/* Colors */

/**** WARNING ***/
/* Before using these variables in other themes or common components,
  make sure they are defined in Callme and Mittele as well */

/* Color: Primary */
$c-pm-900: #1f002e;
$c-pm-850: #29003e;
$c-pm-800: #30004b;
$c-pm-700: #5e0092;
$c-pm-600: #7800b4;
$c-pm-500: #990ae3;
$c-pm-400: #c158f8;
$c-pm-300: #de99ff;
$c-pm-200: #edc8ff;
$c-pm-100: #fbf0ff; // Use this as text-color on purple backgrounds

/* Color: Greyscale */
$c-nt-1000: #121214;
$c-nt-900: $c-nt-1000;
$c-nt-850: #28282a;
$c-nt-800: #303032;
$c-nt-700: #48484b;
$c-nt-600: #606064;
$c-nt-500: #8c8c90;
$c-nt-400: #b0b0b5;
$c-nt-300: #c8c8cd;
$c-nt-200: #dcdce1;
$c-nt-150: #ececee;
$c-nt-100: #e8e8ed;
$c-nt-50: #f4f4f9;

/* Color: Blue */
$c-sp-blue-900: #00253d;
$c-sp-blue-800: #003d66;
$c-sp-blue-700: #00558f;
$c-sp-blue-600: #007aac;
$c-sp-blue-500: #0099ff;
$c-sp-blue-400: #33adff;
$c-sp-blue-300: #66c2ff;
$c-sp-blue-200: #a6dbff;
$c-sp-blue-100: #ccebff;

/* Color: Green */
$c-sp-green-900: #00361a;
$c-sp-green-800: #00572a;
$c-sp-green-700: #008641;
$c-sp-green-600: #00af56;
$c-sp-green-500: #26bb6f;
$c-sp-green-400: #4cc789;
$c-sp-green-300: #73d3a2;
$c-sp-green-200: #a6e3c4;
$c-sp-green-100: #ccefdd;

/* Color: Supporting: Orange */
$c-sp-orange-900: #402700;
$c-sp-orange-800: #663e00;
$c-sp-orange-700: #995d00;
$c-sp-orange-600: #cc7c00;
$c-sp-orange-500: #ff9b00;
$c-sp-orange-400: #ffb84d;
$c-sp-orange-300: #ffd06b;
$c-sp-orange-200: #ffdca6;
$c-sp-orange-100: #ffebcc;

/* Color: Supporting: Red */
$c-sp-red-900: #390617;
$c-sp-red-800: #5b0925;
$c-sp-red-700: #890e37;
$c-sp-red-600: #be0040;
$c-sp-red-500: #e4175c;
$c-sp-red-400: #eb5185;
$c-sp-red-300: #f07fa5;
$c-sp-red-200: #f6aec6;
$c-sp-red-100: #fad1de;

/* Color: Supporting: Teal */
$c-sp-teal-500: #00827c;

/* Color: Beige */
$c-sp-beige-900: #1c1818;
$c-sp-beige-800: #282420;
$c-sp-beige-700: #403a36;
$c-sp-beige-600: #5c524e;
$c-sp-beige-500: #827672;
$c-sp-beige-400: #988a84;
$c-sp-beige-350: #b4a49c;
$c-sp-beige-300: $c-sp-beige-350;
$c-sp-beige-200: #d4c4ba;
$c-sp-beige-100: #ecded4;
$c-sp-beige-50: #f8eee8;

/* Color: Black, White etc. */
$color-black: $c-nt-900;
$color-border-focus: $c-pm-500;
$color-display-text: $c-nt-700;
$color-shadow-focus: inset 0 1px 1px rgba(0, 0, 0, 0.075),
  0 0 8px rgba(102, 175, 233, 0.6);
$color-white: #fff;

/* Checkout Colors */
$color-checkout-module: #fff;

/*
BELOW
===============================================================
Not directly in use for Telia, but used by shared components */
$color-gray: $c-nt-50;
$color-gray-10: $c-nt-600;

//tooltip background and border
$tooltiparrow: 24px;


/* payment consent colors */

$c-pc-background: #F4F4F9;
$c-pc-checkbox: $c-pm-600;