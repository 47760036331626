
















































































































































































































































































.signature {
  position: relative;

  &__canvas {
    &--hidden {
      display: none;
    }
  }
}
