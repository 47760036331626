@mixin c-attention-banner($theme) {
  $root: &;
  background: map-get($theme, background);
  position: relative;

  @include padding(vertical, map-deep-get($theme, spacing, default));

  @include mq($screen-md, min) {
    @include padding(vertical, map-deep-get($theme, spacing, desktop));
  }

  &--hidden {
    display: none;
  }

  & + #{$root} {
    padding-top: #{(map-deep-get($theme, spacing, default) * 8) + 1}px;

    @include mq($screen-md, min) {
      padding-top: #{(map-deep-get($theme, spacing, desktop) * 8) + 1}px;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: map-deep-get($theme, divider, height);
      background: map-deep-get($theme, divider, background);
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
    }
  }

  .c-wrapper {
    align-items: flex-start;
    @include padding(left);
    @include padding(right, 4);

    @include mq($screen-md, min) {
      @include padding(horizontal, 3);
      align-items: center;
    }
  }

  svg {
    width: map-deep-get($theme, icon, width);
    @include margin(right, map-deep-get($theme, icon, margin, default));

    @include mq($screen-md, min) {
      @include margin(right, map-deep-get($theme, icon, margin, desktop));
    }

    use {
      fill: map-get($theme, color);
    }
  }

  img {
    @include margin(right, map-deep-get($theme, icon, margin, default));

    @include mq($screen-md, min) {
      @include margin(right, map-deep-get($theme, icon, margin, desktop));
    }
  }

  &__content {
    flex: 1;
    @include typography(map-get($theme, typography));
    color: map-get($theme, color);

    text-align: map-get($theme, text-align);

    @include mq($screen-md, min) {
      text-align: left;
    }

    a {
      color: inherit;
    }
  }

  &__close {
    appearance: none;
    background: transparent;
    position: absolute;
    right: map-deep-get($theme, close, right);
    top: map-deep-get($theme, close, top);
    border: 0;
    margin: 0;
    padding: 0;

    svg {
      width: map-deep-get($theme, close, width);
      margin: 0;
      use {
        fill: map-get($theme, color);
      }
    }
  }
}

@if variable-exists(c-attention-banner) {
  .c-attention-banner {
    @include c-attention-banner($c-attention-banner);
  }
}
