﻿/// @name targetEdge
/// @group mixins
/// @author Mads Stoumann

@mixin targetEdge {
  @supports (-ms-ime-align: auto) {
    @content;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

/// @name targetFF
/// @group mixins
/// @author Mads Stoumann

@mixin targetFF {
  @-moz-document url-prefix() {
    @content;
  }
}

/// @name targetIE
/// @group mixins
/// @author Mads Stoumann

@mixin targetIE {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

/// @name targetSafari
/// @group mixins
/// @author Mads Stoumann

@mixin targetSafari {
  @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
    @content;
  }
}
