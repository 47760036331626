














































































































































@import 'theme/sass/settings/_settings.vars.scss';

.checkout-navigation {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: $u-800;

  &--flex-end {
    justify-content: flex-end;
  }

  &--flex-center {
    justify-content: center;
  }
}
