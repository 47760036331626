
















































































































































































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'src/shared/styles/breakpoints.scss';

$employee-button-width: 111px;

.subscription-card {
  position: relative;
  width: 100%;

  $subscription-card: &;

  // .subscription-card__top
  &__top {
    position: relative;
    padding-top: $u-550;
    min-height: 200px;

    &--bg {
      background-color: var(--card-bg-color);
    }
  }

  // .subscription-card__bot
  &__bot {
    padding: $u-300 $u-400;
  }

  // .subscription-card__title
  &__title {
    font-family: $global-font-family-2;
    font-size: $font-size-h5;
    line-height: 24px;
    white-space: normal;
  }

  // .subscription-card__attr
  &__attr {
    font-family: $global-font-family-2;
    font-size: $font-size-m + 2px; /* TODO DKT-1417: update design with font-size that corresponds to our variables? */
    font-weight: 700;
    text-transform: uppercase;
  }

  // .subscription-card__description
  &__description {
    font-size: $font-size-h5;
    line-height: 20px;
    white-space: normal;
  }

  // .subscription-card__footer
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // .subscription-card__prices
  &__prices {
    position: relative;
  }

  // .subscription-card__price
  &__price {
    font-size: $global-font-size;
    font-weight: 700;
    line-height: 22px;

    // .subscription-card__price--original
    &--original {
      color: $c-pm-800;
      text-decoration: line-through;
    }
  }

  // .subscription-card__action
  &__action {
    position: relative;
    height: $u-500;

    // TODO DKT-1417: c-button styles are not properly
    &-button {
      font-size: $global-font-size-md !important;
      height: 2rem !important;
      min-width: 70px;
      padding: 0 1rem !important;
      position: absolute !important;
      right: 0;
      top: 0;
    }

    // .subscription-card__action--selected
    &--selected {
      display: flex;
      align-items: center;
      color: #222;
      flex: 0 0 100%;
      font-size: $global-font-size-md;
      line-height: $u-500;
      padding: 0 $u-300;

      & > svg {
        fill: currentColor;
        height: 17px;
        margin-right: -2px;
        width: 17px;
      }
    }

    &--white {
      color: $color-white;
    }
  }

  &__tongue {
    font-size: $global-font-size-sm;
    line-height: 18px;

    &-trigger {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: $u-350;

      & > svg {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 0px;
        top: calc(50% - 6px);
        transition: transform 0.2s ease;
      }

      &--expanded > svg {
        transform: rotateZ(180deg);
      }
    }

    &-collapse {
      padding-top: $u-200;
    }

    $vasImageSize: 24;
    $vasImageMargin: 2;
    $vasMinCount: 2;
    $vasMaxCount: 6;

    &-vas-container {
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
    }

    &-vas {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      min-width: #{($vasImageSize + $vasImageMargin) * $vasMinCount}px;
      max-width: #{($vasImageSize + $vasImageMargin) * $vasMaxCount}px;
      max-height: #{$vasImageSize}px;

      & > img {
        height: #{$vasImageSize}px;
        width: #{$vasImageSize}px;
        max-width: 100%;
        height: auto;
        vertical-align: middle;

        &:not(:last-child) {
          margin-right: #{$vasImageMargin}px;
        }
      }
    }

    &-text {
      flex: 1 0 0;
      white-space: nowrap;
    }
  }

  &__scale {
    &-enter-active,
    &-leave-active {
      transition: transform 0.2s;
    }

    &-enter,
    &-leave-to {
      transform: scale(0);
    }
  }

  &--fluid {
    // Note: card is used inside carousel and min-width can break
    // carousel offsets. Instead width is controlled by slide count
    // and breakpoints.

    min-width: unset;
  }

  &.subscription-card--no-details {
    div.subscription-card__top {
      border: 2px solid $c-pm-900;
    }
    &.c-card--selected {
      .subscription-card__top {
        border: 2px solid $c-pm-500;
      }
    }
  }

  &__employee-button {
    min-width: $employee-button-width;
    margin-top: $u-500;

    &--selected.c-btn {
      margin-top: $u-700;
      padding: 0;

      #{$subscription-card}__action {
        padding: 0;
      }
    }
  }
}
