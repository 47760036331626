







































$heading-sizes: (
  1: 36px,
  2: 24px,
  3: 20px,
  4: 16px,
  5: 14px,
  6: 12px
);

$heading-large-sizes: (
  1: 72px,
  2: 60px,
  3: 48px,
  4: 36px,
  5: 24px,
  6: 18px
);
.c-heading {
  @each $size, $value in $heading-sizes {
    // .c-heading--{size}

    &--#{$size} {
      font-size: $value;
    }
  }
  @each $size, $value in $heading-large-sizes {
    // .c-heading--{size}.c-heading--large
    &--#{$size}.c-heading--large {
      font-size: $value;
    }
  }
}
