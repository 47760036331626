



























































































.c-accordion {
  &-item {
    &__trigger {
      cursor: pointer;
      user-select: none;
    }
  }
}
