/**
 * oneblock-color.css
 * @version 1.2.00
 * @summary 21-02-2020
 * @author Mads Stoumann
 * @description OneBlock: Colors, blend-modes
 */

/* Color
===============================
Prefix:
-A Additional headline
-C Image Caption
-E Content Box
-H Headline
-L Link (Button)
-M Meta
-N Tagline
-O OneBlock
-S Splash
-Dh Display headline
-X Section

Key:
-bg OR -c

Value:
0: Reserved for default value
1-?
===============================
*/

/* Additional headline Color */
.-A-cl1 .c-ob__additional-headline {
  color: var(--ob-A-c1);
}
.-A-cl2 .c-ob__additional-headline {
  color: var(--ob-A-c2);
}
.-A-cl3 .c-ob__additional-headline {
  color: var(--ob-A-c3);
}
.-A-cl4 .c-ob__additional-headline {
  color: var(--ob-A-c4);
}
.-A-cl5 .c-ob__additional-headline {
  color: var(--ob-A-c5);
}

/* OneBlock: Main Background */
.-O-bg1 {
  background: var(--ob-O-bg1);
}
.-O-bg2 {
  background: var(--ob-O-bg2);
}
.-O-bg3 {
  background: var(--ob-O-bg3);
}
.-O-bg4 {
  background: var(--ob-O-bg4);
}
.-O-bg5 {
  background: var(--ob-O-bg5);
}
.-O-bg6 {
  background: var(--ob-O-bg6);
}
.-O-bg7 {
  background: var(--ob-O-bg7);
}
.-O-bg8 {
  background: var(--ob-O-bg8);
}
.-O-bg9 {
  background: var(--ob-O-bg9);
}

/* Content Box */
.-E-bg1 .c-ob__content {
  background: rgba(var(--ob-E-bg1), var(--ob-alpha));
}
.-E-bg2 .c-ob__content {
  background: rgba(var(--ob-E-bg2), var(--ob-alpha));
}
.-E-bg3 .c-ob__content {
  background: rgba(var(--ob-E-bg3), var(--ob-alpha));
}
.-E-bg4 .c-ob__content {
  background: rgba(var(--ob-E-bg4), var(--ob-alpha));
}
.-E-bg5 .c-ob__content {
  background: rgba(var(--ob-E-bg5), var(--ob-alpha));
}
.-E-bg6 .c-ob__content {
  background: rgba(var(--ob-E-bg6), var(--ob-alpha));
}
.-E-bg7 .c-ob__content {
  background: rgba(var(--ob-E-bg7), var(--ob-alpha));
}
.-E-bg8 .c-ob__content {
  background: rgba(var(--ob-E-bg8), var(--ob-alpha));
}
.-E-bg9 .c-ob__content {
  background: rgba(var(--ob-E-bg9), var(--ob-alpha));
}
.-E-cl1 .c-ob__content {
  color: var(--ob-E-c1);
}
.-E-cl2 .c-ob__content {
  color: var(--ob-E-c2);
}
.-E-cl3 .c-ob__content {
  color: var(--ob-E-c3);
}
.-E-cl4 .c-ob__content {
  color: var(--ob-E-c4);
}
.-E-cl5 .c-ob__content {
  color: var(--ob-E-c5);
}

/* Inner Text Elements. !important is added because this section inherits font-sizes from RichText-block, but need to control these from the OB Editor. */
.-E-cl1 .c-ob__content .c-ob__text * {
  color: var(--ob-E-c1) !important;
}
.-E-cl2 .c-ob__content .c-ob__text * {
  color: var(--ob-E-c2) !important;
}
.-E-cl3 .c-ob__content .c-ob__text * {
  color: var(--ob-E-c3) !important;
}
.-E-cl4 .c-ob__content .c-ob__text * {
  color: var(--ob-E-c4) !important;
}
.-E-cl5 .c-ob__content .c-ob__text * {
  color: var(--ob-E-c5) !important;
}

/* Headline Color */
.-H-cl1 .c-ob__headline {
  color: var(--ob-H-c1);
}
.-H-cl2 .c-ob__headline {
  color: var(--ob-H-c2);
}
.-H-cl3 .c-ob__headline {
  color: var(--ob-H-c3);
}
.-H-cl4 .c-ob__headline {
  color: var(--ob-H-c4);
}
.-H-cl5 .c-ob__headline {
  color: var(--ob-H-c5);
}

/* Image Caption */
.-C-bg1 .c-ob__bg-caption {
  background: var(--ob-C-bg1);
}
.-C-bg2 .c-ob__bg-caption {
  background: var(--ob-C-bg2);
}
.-C-bg3 .c-ob__bg-caption {
  background: var(--ob-C-bg3);
}
.-C-bg4 .c-ob__bg-caption {
  background: var(--ob-C-bg4);
}
.-C-bg5 .c-ob__bg-caption {
  background: var(--ob-C-bg5);
}
.-C-cl1 .c-ob__bg-caption {
  color: var(--ob-C-c1);
}
.-C-cl2 .c-ob__bg-caption {
  color: var(--ob-C-c2);
}
.-C-cl3 .c-ob__bg-caption {
  color: var(--ob-C-c3);
}
.-C-cl4 .c-ob__bg-caption {
  color: var(--ob-C-c4);
}

/* Link and Tagline colors and styles, see: oneblock-config.css */

/* Meta */
.-M-cl1 .c-ob__meta {
  color: var(--ob-M-c1);
}
.-M-cl2 .c-ob__meta {
  color: var(--ob-M-c2);
}
.-M-cl3 .c-ob__meta {
  color: var(--ob-M-c3);
}
.-M-cl4 .c-ob__meta {
  color: var(--ob-M-c4);
}

/* Splash */
.-S-bg1 .c-ob__splash {
  color: var(--ob-S-c1);
  fill: var(--ob-S-bg1);
}
.-S-bg2 .c-ob__splash {
  color: var(--ob-S-c2);
  fill: var(--ob-S-bg2);
}
.-S-bg3 .c-ob__splash {
  color: var(--ob-S-c3);
  fill: var(--ob-S-bg3);
}
.-S-bg4 .c-ob__splash {
  color: var(--ob-S-c4);
  fill: var(--ob-S-bg4);
}
.-S-bg5 .c-ob__splash {
  color: var(--ob-S-c5);
  fill: var(--ob-S-bg5);
}
.-S-bg6 .c-ob__splash {
  color: var(--ob-S-c6);
  fill: var(--ob-S-bg6);
}
.-S-bg7 .c-ob__splash {
  color: var(--ob-S-c7);
  fill: var(--ob-S-bg7);
}

.-S-ol1 .c-ob__splash,
.-S-ol2 .c-ob__splash,
.-S-ol3 .c-ob__splash {
  stroke-width: 2px;
}

.-S-ol1 .c-ob__splash {
  stroke: var(--ob-S-ol1);
  color: var(--ob-S-ol1);
}

.-S-ol2 .c-ob__splash {
  stroke: var(--ob-S-ol2);
  color: var(--ob-S-ol2);
}

.-S-ol3 .c-ob__splash {
  stroke: var(--ob-S-ol3);
  color: var(--ob-S-ol3);
}

/* Blend Modes */
.-W-bm1 .c-ob__bg {
  mix-blend-mode: color;
}
.-W-bm2 .c-ob__bg {
  mix-blend-mode: color-burn;
}
.-W-bm3 .c-ob__bg {
  mix-blend-mode: color-dodge;
}
.-W-bm4 .c-ob__bg {
  mix-blend-mode: darken;
}
.-W-bm5 .c-ob__bg {
  mix-blend-mode: exclusion;
}
.-W-bm6 .c-ob__bg {
  mix-blend-mode: hard-light;
}
.-W-bm7 .c-ob__bg {
  mix-blend-mode: hue;
}
.-W-bm8 .c-ob__bg {
  mix-blend-mode: lighten;
}
.-W-bm9 .c-ob__bg {
  mix-blend-mode: luminosity;
}
.-W-bm10 .c-ob__bg {
  mix-blend-mode: multiply;
}
.-W-bm11 .c-ob__bg {
  mix-blend-mode: overlay;
}
.-W-bm12 .c-ob__bg {
  mix-blend-mode: saturation;
}
.-W-bm13 .c-ob__bg {
  mix-blend-mode: screen;
}
.-W-bm14 .c-ob__bg {
  mix-blend-mode: soft-light;
}

/* Shadow Overlays */
.-W-ov1 .c-ob__bg-wrapper::before,
.-W-ov2 .c-ob__bg-wrapper::before,
.-W-ov3 .c-ob__bg-wrapper::before,
.-W-ov4 .c-ob__bg-wrapper::before,
.-W-ov5 .c-ob__bg-wrapper::before,
.-W-ov6 .c-ob__bg-wrapper::before,
.-W-ov7 .c-ob__bg-wrapper::before,
.-W-ov8 .c-ob__bg-wrapper::before {
  border-radius: inherit;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.-W-ov1 a,
.-W-ov2 a,
.-W-ov3 a,
.-W-ov4 a,
.-W-ov5 a,
.-W-ov6 a,
.-W-ov7 a,
.-W-ov8 a {
  z-index: 2;
}
.-W-ov1 .c-ob__content,
.-W-ov2 .c-ob__content,
.-W-ov3 .c-ob__content,
.-W-ov4 .c-ob__content,
.-W-ov5 .c-ob__content,
.-W-ov6 .c-ob__content,
.-W-ov7 .c-ob__content,
.-W-ov8 .c-ob__content {
  z-index: 2;
}
.-W-ov1 .c-ob__bg-wrapper::before {
  background-image: linear-gradient(
    to bottom,
    transparent 20%,
    rgba(0, 0, 0, 0.5)
  );
}
.-W-ov2 .c-ob__bg-wrapper::before {
  background-image: linear-gradient(
    to top,
    transparent 20%,
    rgba(0, 0, 0, 0.5)
  );
}
.-W-ov3 .c-ob__bg-wrapper::before {
  background-image: linear-gradient(
    315deg,
    transparent 20%,
    rgba(0, 0, 0, 0.5)
  );
}
.-W-ov4 .c-ob__bg-wrapper::before {
  background-image: linear-gradient(45deg, transparent 20%, rgba(0, 0, 0, 0.5));
}
.-W-ov5 .c-ob__bg-wrapper::before {
  background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
}
.-W-ov6 .c-ob__bg-wrapper::before {
  background-image: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 43, 73, 0.8)
  );
}
.-W-ov7 .c-ob__bg-wrapper::before {
  background-image: linear-gradient(
    to bottom,
    rgba(60, 0, 112, 0),
    rgba(60, 0, 112, 0.8)
  );
}
.-W-ov8 .c-ob__bg-wrapper::before {
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
}

/* Ribbon */
.-R-bg1 .c-ob__ribbon {
  background: var(--ob-R-bg1);
}
.-R-bg2 .c-ob__ribbon {
  background: var(--ob-R-bg2);
}
.-R-bg3 .c-ob__ribbon {
  background: var(--ob-R-bg3);
}
.-R-bg4 .c-ob__ribbon {
  background: var(--ob-R-bg4);
}
.-R-cl1 .c-ob__ribbon {
  color: var(--ob-R-c1);
}
.-R-cl2 .c-ob__ribbon {
  color: var(--ob-R-c2);
}
.-R-cl3 .c-ob__ribbon {
  color: var(--ob-R-c3);
}
.-R-cl4 .c-ob__ribbon {
  color: var(--ob-R-c4);
}

/* Display headline Color */
.-Dh-cl0 .c-ob__display-headline {
  color: var(--ob-Dh-cl0);
}
.-Dh-cl1 .c-ob__display-headline {
  color: var(--ob-Dh-cl1);
}
.-Dh-cl2 .c-ob__display-headline {
  color: var(--ob-Dh-cl2);
}
.-Dh-cl3 .c-ob__display-headline {
  color: var(--ob-Dh-cl3);
}
.-Dh-cl4 .c-ob__display-headline {
  color: var(--ob-Dh-cl4);
}
.-Dh-cl5 .c-ob__display-headline {
  color: var(--ob-Dh-cl5);
}
