

























































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.empty-basket {
  // .empty-basket__button-link
  &__button-link {
    display: flex;
    align-items: flex-end;
    color: $c-pm-500;
    font-weight: 400;
    font-size: $global-font-size-sm;
    height: fit-content;
    padding: 0;

    @include mq($screen-lg, min) {
      font-size: $global-font-size-md;
    }

    &--left {
      flex-direction: row-reverse;
    }
  }

  // .empty-basket__button-link-icon
  &__button-link-icon {
    width: 18px;
    height: 18px;
    margin: 0 0 2px $u-150;
    fill: $c-pm-500;
  }

  // .shopping-cart__modal-content
  &__modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $u-850 $u-250 $u-850;
  }

  // .shopping-cart__modal-accept-button
  &__modal-accept-button {
    margin-top: $u-350;
    margin-bottom: $u-200;
  }

  //shopping-cart__modal-message
  &__modal-message {
    text-align: center;
    font-weight: 700;
    font-size: $font-size-h4;
  }
}
