/**
 * oneblock-margin.css
 * @version 1.2.40
 * @summary 01-03-2020
 * @author Mads Stoumann
 * @description OneBlock: Margin
 */

/* Margin
===============================
Prefix:
-A Additional headline
-H Headline
-L Link
-M Meta
-Dh Display headline
-T Text

Key:
-mb, -mt

Value:
0 (Default)-9
===============================
*/
.-A-mb0 .c-ob__additional-headline,
.-Dh-mb0 .c-ob__display-headline,
.-H-mb0 .c-ob__headline,
.-L-mb0 .c-ob__link-wrapper,
.-M-mb0 .c-ob__meta,
.-N-mb0 .c-ob__tagline,
.-P-mb0 .c-ob__offer,
.-T-mb0 .c-ob__text {
  margin-bottom: 0;
}

.-A-mb1 .c-ob__additional-headline,
.-Dh-mb1 .c-ob__display-headline,
.-H-mb1 .c-ob__headline,
.-L-mb1 .c-ob__link-wrapper,
.-M-mb1 .c-ob__meta,
.-N-mb1 .c-ob__tagline,
.-P-mb1 .c-ob__offer,
.-T-mb1 .c-ob__text {
  margin-bottom: var(--ob-margin-unit);
}

.-A-mb2 .c-ob__additional-headline,
.-Dh-mb2 .c-ob__display-headline,
.-H-mb2 .c-ob__headline,
.-L-mb2 .c-ob__link-wrapper,
.-M-mb2 .c-ob__meta,
.-N-mb2 .c-ob__tagline,
.-P-mb2 .c-ob__offer,
.-T-mb2 .c-ob__text {
  margin-bottom: calc(var(--ob-margin-unit) * 2);
}

.-A-mb3 .c-ob__additional-headline,
.-Dh-mb3 .c-ob__display-headline,
.-H-mb3 .c-ob__headline,
.-L-mb3 .c-ob__link-wrapper,
.-M-mb3 .c-ob__meta,
.-N-mb3 .c-ob__tagline,
.-P-mb3 .c-ob__offer,
.-T-mb3 .c-ob__text {
  margin-bottom: calc(var(--ob-margin-unit) * 3);
}

.-A-mb4 .c-ob__additional-headline,
.-Dh-mb4 .c-ob__display-headline,
.-H-mb4 .c-ob__headline,
.-L-mb4 .c-ob__link-wrapper,
.-M-mb4 .c-ob__meta,
.-N-mb4 .c-ob__tagline,
.-P-mb4 .c-ob__offer,
.-T-mb4 .c-ob__text {
  margin-bottom: calc(var(--ob-margin-unit) * 4);
}
.-A-mb5 .c-ob__additional-headline,
.-Dh-mb5 .c-ob__display-headline,
.-H-mb5 .c-ob__headline,
.-N-mb5 .c-ob__tagline {
  margin-bottom: calc(var(--ob-margin-unit) * 0.5);
}

/* Content Box Margin */
.-E-mt1 .c-ob__content {
  margin-top: calc(var(--ob-margin-unit) * -1);
}

.-E-mt2 .c-ob__content {
  margin-top: calc(var(--ob-margin-unit) * -2);
}

.-E-mt3 .c-ob__content {
  margin-top: calc(var(--ob-margin-unit) * -4);
}

.-E-mt4 .c-ob__content {
  margin-top: calc(var(--ob-margin-unit) * -8);
}

.-E-mt5 .c-ob__content {
  margin-top: calc(var(--ob-margin-unit) * -12);
}

.-E-mt6 .c-ob__content {
  margin-top: var(--ob-margin-unit);
}

.-E-mt7 .c-ob__content {
  margin-top: calc(var(--ob-margin-unit) * 2);
}

.-E-mt8 .c-ob__content {
  margin-top: calc(var(--ob-margin-unit) * 4);
}

.-E-mt9 .c-ob__content {
  margin-top: calc(var(--ob-margin-unit) * 8);
}

@supports (-ms-ime-align: auto) {
  .-E-mt1 .c-ob__content,
  .-E-mt2 .c-ob__content,
  .-E-mt3 .c-ob__content,
  .-E-mt4 .c-ob__content,
  .-E-mt5 .c-ob__content {
    margin-top: 0;
  }
}
