












































































// TODO TC-4919: maybe use global styles (ex. text-right), so we
// ensure reusability around?
.c-text {
  // .c-text--right
  &--right {
    text-align: right;
  }

  // .c-text--center
  &--center {
    text-align: center;
  }

  // .c-text--striked
  &--striked {
    text-decoration: line-through;
  }

  // .c-text--block
  &--block {
    display: block;
  }
}
