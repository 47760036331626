/**
 * oneblock-shapes.css
 * @version 1.0.10
 * @summary 18-02-2020
 * @author Mads Stoumann
 * @description OneBlock: Shapes (for image)
 */

/* Shapes: https://bennettfeely.com/clippy/
===============================
Prefix:
-B Background Image/Media

Key:
-sh

Value:
1-9
===============================
*/

/* Arrow Down */
.-B-sh1 .c-ob__bg {
	clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
}
/* Arrow Up */
.-B-sh2 .c-ob__bg {
	clip-path: polygon(100% 0, 100% 100%, 50% 85%, 0 100%, 0 0);
}
/* Cut Right */
.-B-sh3 .c-ob__bg {
	clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
}
/* Cut Left */
.-B-sh4 .c-ob__bg {
	clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
}
/* Curve Down */
.-B-sh5 .c-ob__bg {
	clip-path: url('#svg-curve-dwn');
}
/* Curve Up */
.-B-sh6 .c-ob__bg {
	clip-path: ellipse(80% 80% at 50% 20%);
}
/* Parallel Right */
.-B-sh7 .c-ob__bg {
	clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0 100%);
}
/* Parallel Left */
.-B-sh8 .c-ob__bg {
	clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 85%);
}
/* Circle */
.-B-sh9 .c-ob__bg {
	clip-path: circle(50% at 50% 50%);
}