

































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.chip {
  border-radius: $u-200;
  border: 1px solid transparent;
  position: relative;

  $root: &;

  // .chip__container
  &__input {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    cursor: pointer;

    + span {
      align-items: center;
      background: $color-white;
      border: 1px solid $c-nt-300;
      border-radius: $u-200;
      box-sizing: border-box;
      color: $color-black;
      display: flex;
      font-size: $font-size-h5;
      font-weight: 700;
      min-height: $u-600;
      justify-content: center;
      padding: $u-200 $u-500;
      text-align: center;
      width: 100%;

      @include mq($screen-md, max) {
        padding: $u-200 $u-300;
      }
    }

    &:checked,
    .checked > & {
      + span {
        border: 2px solid $c-pm-500;
      }
    }
  }

  &--wide {
    margin-right: $u-250;

    &:last-child {
      margin-right: 0;
    }

    #{$root}__input {
      + span {
        padding: $u-200 $u-550;
      }
    }
  }

  &--link {
    #{$root}__input {
      height: 0;
      width: 0;
    }
  }
}
