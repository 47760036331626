




















































































@import 'theme/sass/settings/_settings.global.scss';

.confirmation-details {
  font-size: $font-size-h5;
}
