@mixin c-wrapper($wrapper-theme) {
  $root: &;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: map-deep-get($wrapper-theme, width, default);
  flex-wrap: wrap;
  @include padding(horizontal, map-deep-get($wrapper-theme, spacing, default));

  @include mq($screen-md, min) {
    @include padding(
      horizontal,
      map-deep-get($wrapper-theme, spacing, desktop)
    );
  }

  &--wide {
    max-width: map-deep-get($wrapper-theme, width, wide);

    &--collapse {
      @include mq($screen-md, min) {
        @if (map-deep-get($wrapper-theme, width, outer) != 100%) {
          max-width: map-deep-get($wrapper-theme, width, collapse) !important;
          @include padding(horizontal, 0);
        }
      }
    }
  }

  &--small {
    max-width: map-deep-get($wrapper-theme, width, small);
  }

  &--background {
    background-color: map-get($wrapper-theme, background);
    width: 100%;
  }

  &--outer {
    background-color: map-deep-get($wrapper-theme, outer, background);
    max-width: map-deep-get($wrapper-theme, outer, max-width);
  }

  &--bottom-spacing {
    @include padding(
      bottom,
      map-deep-get($wrapper-theme, padding-bottom, default)
    );
    @include mq($screen-md, min) {
      @include padding(
        bottom,
        map-deep-get($wrapper-theme, padding-bottom, desktop)
      );
    }
  }

  &--collapse {
    &--small {
      @include mq($screen-md, max) {
        @include padding(horizontal, 0);
      }
    }
  }
}

.c-wrapper {
  @include c-wrapper($c-wrapper);
}
