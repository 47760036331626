@import '/src/apps/webshop/shared/styles/sass/settings/_settings.vars.scss';

/* Updated box-shadow */
$box-shadow-default: 0px 4px 16px rgba(0, 0, 0, 0.08);
$box-shadow-hover: 0px 8px 32px rgba(0, 0, 0, 0.24);

/* Menu / Filter */
$menu-height-mobile: 90px;
$menu-height-desktop: 125px;
$menu-subnav: 50px;
$servicemenu-height: 30px;
$filter-menu-top: $menu-height-desktop + $u-200;
$notifications-bar: 40px;

/* Toast item*/
$toast-icon-container: 48px;
$toast-icon-height: 2em;
$toast-icon-width: 2em;
$toast-max-height: 300px;

/* Filter checkbox/ radio */
$control-filters-width: 20px;
$control-filters-width-small: 6px;
$infoiconwidth: 16px;

/* Skew Angles */
$skew-up: -2.5deg;

/* z-index-map, use z("label") in components, ie.: z-index: z("modal"); */
$zindex: ( 'skew': -1, 'app': 0, 'slider': 10, 'panel': 100, 'shop-footer': 190, 'menu': 200, 'tooltip': 250, 'channel': 700, 'header': 800, 'modal': 850, 'filter-nav': 900, 'cookie': 900, 'toast': 1000, 'shop-footer-overlay': 1000, 'activity-indicator': 1100 );

/* Product vars */
$product-order: ( 'heading': ( 'xs': 0, 'md': 10 ), 'image': ( 'xs': 10, 'md': 0 ), 'inventory': ( 'xs': 20 ), 'description': ( 'xs': 30, 'md': 30 ), 'countdown': ( 'xs': 35 ), 'promotion': ( 'xs': 35 ), 'color': ( 'xs': 15, 'md': 40 ), 'storage': ( 'xs': 50 ), 'payment': ( 'xs': 60 ), 'insurance': ( 'xs': 62 ), 'configuration-footer':('xs': 63), 'prompt': ( 'xs': 65 ), 'subscription-offer': ( 'xs': 70 ), 'total': ( 'xs': 80 ), 'subscription': ( 'xs': 90 ), 'cta': ( 'xs': 95 ), 'usp': ( 'xs': 100 ), 'tabs': ( 'xs': 110 ) );

$product-gallery-height: 440px;
$product-image-height: 380px;
$product-image-width: 290px;
