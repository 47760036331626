

































































































.c-form-radio-group {
  position: relative;

  // .c-form-radio-group-wrapper
  &-wrapper {
    & > legend {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bolder;
    }
  }

  // .c-form-radio-group [role="radiogroup"]
  & [role='radiogroup'] {
    display: flex;
  }

  // .c-form-radio-group--stacked
  &--stacked {
    & [role='radiogroup'] {
      display: block;
    }
  }

  // .c-form-radio-group--inline
  &--inline {
    & [role='radiogroup'] {
      .c-form-radio {
        display: inline-block;
      }
    }
  }
}
