



















































@import 'olympus/styles/media-query.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';

.c-quick-links {
  .c-wrapper {
    padding-top: $u-450;
    padding-bottom: $u-450;
    overflow-x: auto;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    @include mq($screen-md, max) {
      display: grid;
      grid-template-columns: repeat(6, minmax(140px, 1fr));
    }
  }

  &__stacked-list {
    @include mq($screen-md, max) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $u-200;
    flex: 1;
    width: 100%;
    flex: 1 0 0;
    svg {
      max-width: 30px;
      height: 21px;
    }
    &:hover {
      text-decoration: none;
      div {
        span {
          width: 100%;
        }
      }
    }
  }

  &__link-text {
    font-size: $global-font-size-md;
    padding: $u-150 0;
    font-weight: 500;
    border-bottom: 1px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      width: 0%;
      border-bottom: 1px solid var(--ob-E-c2);
      display: block;
      transition: all 0.125s ease-in-out;
      margin: 0 auto;
      margin-top: 2px;
    }
  }
}
