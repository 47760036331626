









































































































































































































* {
  will-change: max-height, padding;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.c-collapse {
  max-height: 0;
  overflow: hidden;

  // .c-collapse--padding
  &--padding {
    padding: 0;
  }

  // .c-collapse--expanded
  &--expanded {
    max-height: calc(
      var(--maxheight) + var(--paddingtop) + var(--paddingbottom)
    );
    padding-top: var(--paddingtop);
    padding-bottom: var(--paddingbottom);

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      // IE fallback since IE doesn't support custom properties/CSS vars
      max-height: 4000px;
    }
  }

  // .c-collapse--fogged
  &--fogged {
    padding-bottom: 15px;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 15px;
      position: absolute;
      left: 0;
      bottom: 0px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}

.expand-enter-active,
.expand-leave-active {
  transition-property: max-height, padding;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
}
