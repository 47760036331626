




















































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';

.product-footer {
  order: map-deep-get($product-order, 'cta', 'xs');
  margin-top: $u-500;

  &.cm-c-total {
    margin-bottom: $u-700;
  }
}
