




















.checkout {
  &-consents {
    // .checkout-consents-label
    &-label {
      font-style: normal;
      margin: 0 auto;
      justify-content: center;
    }

    // .checkout-consents-more
    &-more {
      font-style: normal;
      margin-bottom: 10px;
      text-align: center;
    }
  }
}
