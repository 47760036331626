#{$prefix} {
	// .cm-o-layout
	&o-layout {
		display: inline-block;

		// .cm-o-layout--main
		&--main {
			flex-grow: 1;

		}
	}
}
// body {
// 	min-height: 100vh;
// }
