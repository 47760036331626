





























































// TODO TC-4916: areas (apart from top-left) have issues.
#c-toast-manager {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;

  & > div {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    &.top-left,
    &.bottom-left {
      justify-content: flex-start;
    }

    &.top-right,
    &.bottom-right {
      align-items: flex-end;
      justify-content: flex-end;
    }

    &.bottom-right,
    &.bottom-left {
      align-items: flex-end;
      height: 100%;
    }

    & > * {
      pointer-events: auto;
    }
  }
}
