



































































































































































































































































































































































































































































































































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'olympus/styles/breakpoints.scss';

.checkout-payment-adyen-b2c {
  .cm-c-checkout__module {
  }
  &__form {
    .c-row {
      padding: 0 $u-300;
      @include mq($breakpoint-desktop, min) {
        padding: 0 $u-800;
      }
    }
  }

  &__checkbox-container {
    outline: $u-100 solid $c-nt-300;
    outline-offset: 0;
    padding: $u-300 $u-250;
    border-radius: $u-150;
    display: flex;
    gap: $u-250;
    &.selected {
      outline-color: $color-black;
      outline-width: 2px;
      outline-offset: -$u-100;
      input[type='checkbox'] {
        accent-color: $color-black;
      }
    }
    input[type='checkbox'] {
      width: $u-300;
      height: $u-300;
      border: $u-100 solid $c-nt-300;
    }
    &-label {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: $u-200;
      small {
        font-size: $font-size-h6;
        color: $c-nt-700;
        .clickable {
          cursor: pointer;
          color: $c-pm-500;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__input-container {
    outline: $u-100 solid $c-nt-300;
    outline-offset: 0;
    padding: $u-200 0.75rem;
    &.valid,
    &.invalid,
    &.active {
      outline-width: 2px;
      outline-offset: -$u-100;
    }
    &.valid {
      outline-color: $color-black;
    }
    &.invalid {
      outline-color: $c-pm-500;
    }
    &.active {
      outline-color: $c-sp-teal-500;
      label {
        color: $c-sp-teal-500;
      }
    }
    .card-input {
      display: block;
      height: $u-300;
    }
    label {
      font-size: $font-size-h6;
      color: $c-nt-800;
    }
  }
  .error-message {
    margin-top: $u-150;
    font-size: $font-size-h6;
  }

  .input-help {
    cursor: pointer;
    color: $c-pm-500;
    font-size: $font-size-h6;
    margin-top: $u-150;
    &:hover {
      text-decoration: underline;
    }
  }
  &__accepted-cards {
    display: flex;
    gap: $u-200;
    div {
      height: $u-300;
      border-radius: $u-150;
      overflow: hidden;
      border: $u-100 solid $c-nt-200;
      img {
        height: 100%;
        display: block;
      }
    }
  }
}
