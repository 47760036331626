











































































.c-tab {
  display: none;

  &--active {
    display: block;
  }
}
