// Headings
h1,
h2,
h3,
h4 {
	font-weight: 700;
	line-height: $title-line-height;
}
h1 {
	font-size: $font-size-h1;
}
h2 {
	font-size: $font-size-h2;
}
h3 {
	font-size: $font-size-h3;
}
h4 {
	font-size: $font-size-h4;
}
