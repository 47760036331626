




































.c-badge {
  position: absolute;
  top: 0;
  right: -1px; // visually align dot badge with icon
  transform: scale(0.5);
  transform-origin: left center;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;

  &--active {
    opacity: 1;
    transform: scale(1);
  }
}
