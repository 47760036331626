













































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

// classes for transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// .checkout-finance-summary
.checkout-finance-summary {
  // .checkout-finance-summary__section
  &__section {
    display: flex;
    flex-direction: column;
    padding-bottom: $u-400;
    gap: $u-200;

    // .checkout-finance-summary__section-title
    &-title {
      font-size: $font-size-h4;
      font-weight: 600;
    }

    // .checkout-finance-summary__section-content
    &-content {
      margin-bottom: $u-200;
    }
  }

  // .checkout-finance-summary__details
  &__details {
    margin: $u-400 0;
    border-top: $u-100 solid $c-nt-600;
    padding-top: $u-400;
  }

  // .checkout-finance-summary__section:not(:last-child)
  &__section:not(:last-child) {
    border-bottom: 1px solid $c-nt-500;
    margin-bottom: $u-400;
  }
}
