

























































































































































































.c-collapse {
  height: 0;

  // .c-collapse--expanded
  &--expanded {
    height: auto;
  }
}

.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
  padding-bottom: 0;
  transition-property: height;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
