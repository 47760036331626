



















































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.product-gallery {
  display: contents;

  @include mq($screen-md, min) {
    display: flex;
    flex-direction: column;
    order: 0;
    position: sticky;
    z-index: 0;
    top: $u-300;
    left: 0;
    height: $product-gallery-height;
    width: 50%;
    align-self: flex-start;
    align-items: flex-end;

    @include mq($screen-md, min) {
      padding-right: $u-900;
    }
  }

  // .product-gallery__image
  &__image {
    order: map-deep-get($product-order, 'image', 'xs');

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-top: $u-400;
    position: relative;

    @include mq($screen-md, min) {
      padding-top: 0;
    }

    &:focus {
      outline: none;
    }

    img {
      display: block;
      margin: 0;

      @include mq($screen-sm, max) {
        max-height: 45vh;
      }

      @include mq($screen-sm, min) {
        max-height: $product-image-height;
      }
    }

    @include mq($screen-md, min) {
      order: map-deep-get($product-order, 'image', 'md');
    }
  }

  // .product-gallery__container
  &__container {
    & .c-gallery__thumbnails {
      & > li {
        border-color: transparent;
        transition: border-color 0.2s ease-in-out;

        &.selected {
          border-width: 3px;
          border-color: $c-pm-500;
        }

        &:not(.selected):hover {
          border-color: $c-nt-600;
        }
      }
    }

    .c-carousel {
      min-height: 340px;
      padding: 0 0 0 $u-400;
    }

    &--simple {
      margin: 0 $u-800 * 2;

      @include mq($screen-xl, max) {
        margin: 0 $u-800;
      }

      @include mq($screen-lg, max) {
        margin: 0 $u-800 0 0;
      }
    }
  }

  // .product-gallery__footer
  &__footer {
    order: map-deep-get($product-order, 'inventory', 'xs');
    margin-top: $u-400;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $u-350;

    @include mq($screen-md, min) {
      width: $product-image-width;
    }
  }

  // .product-gallery--bottom-thumbnails
  &--bottom-thumbnails {
    .c-gallery__thumbnails {
      margin-top: $u-400;
      justify-content: center;

      & > li:last-child {
        margin-right: 0;
      }
    }

    .c-carousel {
      padding: 0;
    }
  }
}
