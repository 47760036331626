@mixin c-article($article-theme) {
  $root: &;
  $c-article-base-unit: 8;

  display: flex;
  background-color: map-get($article-theme, background);
  position: relative;
  width: calc(
    100% - #{$c-article-base-unit *
      (map-deep-get($article-theme, margin, horizontal, default) * 2)}px
  );
  max-width: map-get($article-theme, width);
  flex-wrap: wrap;
  flex-direction: column;
  @include justify-content(center);
  @include padding(bottom, 6);
  @include padding(horizontal, map-deep-get($article-theme, spacing, default));
  @include margin(
    horizontal,
    map-deep-get($article-theme, margin, horizontal, default)
  );
  @include margin(
    bottom,
    map-deep-get($article-theme, margin, bottom, default)
  );

  @include mq($screen-md, min) {
    width: 100%;
    @include margin(
      bottom,
      map-deep-get($article-theme, margin, bottom, desktop)
    );
    @include padding(bottom, 10);
    @include padding(
      horizontal,
      map-deep-get($article-theme, spacing, desktop)
    );
    margin-left: auto;
    margin-right: auto;
  }

  /* 
    TODO DKT-1413: temp fix for .cm-grid--center-* classes centering all the text
  */
  text-align: left;

  &__body {
    width: 100%;

    & > {
      p,
      ul,
      ol {
        &:not(.c-list) {
          overflow: hidden;
        }
      }
    }
    & >,
    ul li,
    ol li {
      [class*='c-typography-h']:not([class*='c-typography-body']) {
        color: map-deep-get($article-theme, headings-color);
        text-transform: map-deep-get(
          $article-theme,
          header,
          headline,
          text-transform
        );
      }
    }

    a:not(.c-link) {
      @extend .link;
    }

    & > p,
    & > ul,
    & > ol {
      &:not([class*='c-margin']) {
        @include margin(bottom, 3);
      }

      &:not([class*='c-typography']) {
        @include typography(body);

        @include mq($screen-md, min) {
          line-height: rem-calc(24);
        }
      }
    }

    ul:not(.c-list) {
      li {
        padding-left: 36px;
        position: relative;

        &:before {
          content: '\2022';
          @include padding(right, 3);
          position: absolute;
          left: 0;
        }
      }
    }

    ol:not(.c-list) {
      @include padding(left, 3);
      li {
        @include padding(left, 2.5);
        list-style-type: decimal;
      }
    }

    li:not(:last-of-type) {
      @include margin(bottom);
    }
  }

  &__header {
    @include padding(
      top,
      map-deep-get($article-theme, header, padding, top, default)
    );
    @include margin(bottom, 2);

    @include mq($screen-md, min) {
      @include padding(
        top,
        map-deep-get($article-theme, header, padding, top, desktop)
      );
      @include margin(bottom, 4);
    }

    & > :last-child {
      @include margin(bottom, 0);
    }
  }

  &__byline,
  &__headline,
  &__teaser,
  &__author {
    @include mq($screen-md, min) {
      padding: 0;
    }
  }

  &__byline {
    display: flex;
    flex-wrap: wrap;

    @include margin(bottom);
    @include typography(body-sm);
    @include justify-content(space-between);

    @include mq($screen-md, min) {
      @include margin(bottom, 2);
    }

    time {
      color: color(primary, dark);
    }
  }

  &__subheadline {
    text-transform: uppercase;
    &:before {
      content: '\200b';
    }
  }

  &__headline {
    text-transform: uppercase;
    @include typography(h1);

    @include margin(
      bottom,
      map-deep-get($article-theme, header, headline, spacing, default)
    );

    @include mq($screen-md, min) {
      @include margin(
        bottom,
        map-deep-get($article-theme, header, headline, spacing, desktop)
      );
    }
  }

  &__teaser {
    @include margin(bottom, 3);
    @include typography(body-xl);

    @include mq($screen-md, min) {
      @include margin(bottom, 6);
      @include typography(body-lg);
    }
  }

  &__author {
    text-align: left;
    font-style: map-deep-get($article-theme, header, author, font-style);
    text-transform: map-deep-get(
      $article-theme,
      header,
      author,
      text-transform
    );
    @include margin(bottom, 2);
    @include typography(
      map-deep-get($article-theme, header, author, typography, default)
    );

    @include mq($screen-md, min) {
      @include typography(
        map-deep-get($article-theme, header, author, typography, desktop)
      );
      @include margin(bottom, 3);
    }
  }

  &__hero {
    @include margin(bottom, 2);

    @include mq($screen-md, min) {
      @include margin(bottom, 3);
    }

    &-wrapper {
      user-select: none;
      border-radius: 2px;
      overflow: hidden;

      /* HACK: Grid is not ready yet so using self-calculated negative margins to replicate the design */
      margin-left: rem-calc(-20);
      margin-right: rem-calc(-20);

      @include mq($screen-md, min) {
        $compensation-margin: -#{$c-article-base-unit * (
            map-deep-get($article-theme, spacing, desktop) + 3
          )}px;

        /* HACK: Grid is not ready yet so using self-calculated negative margins to replicate the design */
        margin-left: $compensation-margin;
        margin-right: $compensation-margin;
      }

      video {
        width: 100%;
        object-fit: cover;
      }

      &__iframe {
        position: relative;
        width: 100%;

        &-inner {
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          min-height: calc(
            #{map-deep-get($article-theme, hero, height, default)} + #{$c-article-base-unit *
              15}px
          );

          @include mq($screen-md, min) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-height: none;
          }
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      video,
      &__iframe {
        @include mq(420px, min) {
          height: map-deep-get($article-theme, hero, height, tablet);
        }

        height: map-deep-get($article-theme, hero, height, default);

        @include mq(700px, min) {
          height: map-deep-get($article-theme, hero, height, desktop);
        }
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      min-height: map-deep-get($article-theme, hero, height, default);
    }
  }

  @if (map-deep-get($article-theme, hero, placed-top) == false) {
    &__subheadline {
      text-transform: none;
    }

    &__teaser {
      @include margin(bottom, 4);
    }

    @include mq($screen-md, min) {
      &__teaser {
        /* Unique font-size, only appears in callme teaser */
        font-size: rem-calc(26);
        line-height: rem-calc(32);
        @include margin(bottom, 5);
      }

      &__author {
        /* 
          Persist mobile version of body-sm
        */
        font-size: rem-calc(12);
        line-height: rem-calc(16);
        @include margin(bottom, 5);
      }
    }
  }

  @if (map-deep-get($article-theme, hero, placed-top) == true) {
    $height-compensation: $c-article-base-unit * 6;
    $height-compensation-dt: $c-article-base-unit * 9;

    &--hero-wide {
      /* HACK: Article header will appear to be over the hero */
      transform: translateY(-#{$height-compensation}px);
      @include mq($screen-md, min) {
        transform: translateY(-#{$height-compensation-dt}px);
      }

      #{$root}__subheadline {
        order: 2;
        flex: 0 0 100%;
        letter-spacing: 1px;
      }

      #{$root}__header {
        text-align: center;
        display: flex;
        flex-direction: column;

        @include padding(top, 0);

        & > * {
          order: 2;
        }

        #{$root} {
          &__byline {
            @include padding(top, 0);
            @include justify-content(flex-end);

            time {
              @include margin(bottom, 1.5);
              color: color(accent, grey) !important;
              position: relative;
            }

            @include mq($screen-md, min) {
              @include padding(top, 3.5);

              #{$root}__subheadline {
                @include padding(top, 3);
              }

              time {
                position: absolute;
                right: $u-300;
                /* HACK */
                transform: translateY(-100%);
              }
            }
          }

          &__hero {
            order: 1;
            position: relative;
            z-index: -1;
            transform: translateY(#{$height-compensation}px);
            min-height: #{$height-compensation}px;
            @include margin(bottom);

            @include mq($screen-md, min) {
              /* HACK: Article header will appear to be over the hero */
              transform: translateY(#{$height-compensation-dt}px);
              min-height: #{$height-compensation-dt}px;
            }

            &-wrapper {
              /* HACK: Grid is not ready yet so using self-calculated negative margins to replicate the design */
              @include mq($screen-md, min) {
                margin-left: -33%;
                margin-right: -33%;
              }
            }

            &:after {
              content: '';
              position: absolute;
              background-color: map-get($article-theme, background);
              bottom: -1px;
              height: rem-calc($height-compensation + 1);
              left: rem-calc(-12);
              right: rem-calc(-12);
              border-top-left-radius: map-get($article-theme, border-radius);
              border-top-right-radius: map-get($article-theme, border-radius);

              @include mq($screen-md, min) {
                /* HACK: Trying to fill full article with */
                left: -#{map-deep-get($article-theme, spacing, desktop) * $c-article-base-unit}px;
                right: -#{map-deep-get($article-theme, spacing, desktop) * $c-article-base-unit}px;

                /* HACK: Article header will appear to be over the hero */
                height: rem-calc($height-compensation-dt + 1);
              }
            }

            img {
              height: map-deep-get($article-theme, hero, height, default);
              @include mq($screen-md, min) {
                height: map-deep-get($article-theme, hero, height, desktop);
              }
            }
          }
        }
      }

      &#{$root}--hero-empty {
        transform: translateY(0) !important;

        #{$root}__header {
          @include padding(top);
        }
      }

      &#{$root}--author-empty {
        #{$root}__header > :nth-last-child(2) {
          @include margin(bottom, 0);
        }
      }
    }
  }
}

.c-article {
  @include c-article($c-article);
}
