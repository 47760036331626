




















































































































































































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.c-employee-contact-info {
  &__contact {
    &__headline,
    &__description {
      margin-bottom: $u-300;
    }
  }

  &__disclaimer {
    [class^='c-typography-h'] {
      font-size: $font-size-h3;
      margin-bottom: $u-150;
    }

    p {
      font-size: $font-size-h5;
      margin-bottom: $u-250;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
