/// Expand / Overflow Component
/// - Version 1.0.0
/// - Date last edited: 2020-03-12
/// @name expand
/// @author Mads Stoumann, Valtech

@mixin c-exp($theme) {
  $root: &;
  &__content {
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    &:not(.c-exp__content--toggle)::after {
      background-image: linear-gradient(
        to bottom,
        map-deep-get($theme, overlay, start),
        map-deep-get($theme, overlay, end) 100%
      );
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      /* When used within a section with background */
      .c-section--bg2 & {
        background-image: linear-gradient(
          to bottom,
          map-deep-get($theme, overlay-dark, start),
          map-deep-get($theme, overlay-dark, end)
        );
      }
    }
    &--toggle {
      max-height: none;
      overflow: initial;
    }
  }
  &__headline {
    @include typography(h3, null, $global-font-family-2);
    color: map-deep-get($theme, headline, color);
    margin: map-deep-get($theme, headline, margin);
    text-align: map-deep-get($theme, headline, text-align);
  }
  &__toggle {
    @include arrow($theme);
    background-color: transparent;
    border: 0;
    color: map-deep-get($theme, toggle, color);
    display: flex;
    flex-direction: column;
    margin: map-deep-get($theme, toggle, margin);
    &[aria-expanded='true']::after {
      margin-top: map-deep-get($theme, arrow, size);
      transform: rotate(225deg);
    }
  }
  /* Modifiers: Available Heights: */
  &--small .c-exp__content:not(.c-exp__content--toggle) {
    max-height: map-deep-get($theme, height, small);
    transition: max-height 0.2s ease-out;
  }
  &--medium .c-exp__content:not(.c-exp__content--toggle) {
    max-height: map-deep-get($theme, height, medium);
    transition: max-height 0.2s ease-out;
  }
  &--large .c-exp__content:not(.c-exp__content--toggle) {
    max-height: map-deep-get($theme, height, large);
    transition: max-height 0.2s ease-out;
  }
}
@mixin arrow($theme) {
  &::after {
    background-color: transparent;
    border-color: map-deep-get($theme, arrow, border-color);
    border-style: solid;
    border-width: 0px map-deep-get($theme, arrow, border-width)
      map-deep-get($theme, arrow, border-width) 0px;
    content: '';
    display: block;
    height: map-deep-get($theme, arrow, size);
    margin: 0 auto;
    transform: rotate(45deg);
    width: map-deep-get($theme, arrow, size);
  }
}
.c-exp {
  @include c-exp($c-exp);
}
