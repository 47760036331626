






























































































































































































































































































@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';

// .store-autocomplete
.store-autocomplete {
  position: relative;

  // .store-autocomplete__search
  &__search {
    position: relative;

    // .store-autocomplete__search__icon
    &__icon {
      height: $u-400;
      position: absolute;
      right: $u-300;
      top: 14px;
      width: $u-400;

      &--fill > svg {
        fill: $c-nt-500;
      }

      &--stroke {
        height: $u-350;
        top: $u-300;
        width: $u-350;

        > svg {
          stroke: $c-nt-500;
          stroke-width: $u-150;
        }
      }

      // .store-autocomplete__search__icon svg
      > svg {
        height: 100%;
        width: 100%;

        // .store-autocomplete__search__icon svg.close
        &.close {
          cursor: pointer;
        }
      }
    }
  }

  // .store-autocomplete__results
  &__results {
    background: $color-white;
    border: 1px solid;
    border-color: $c-nt-300;
    font-size: $global-font-size-md;
    max-height: 500px;
    overflow: auto;
    position: absolute;

    z-index: 1;

    // .store-autocomplete__results--is-full-width
    &--is-full-width {
      width: 100%;
    }

    // .store-autocomplete__results__item
    &__item {
      border-bottom: 1px solid $c-nt-200;
      cursor: pointer;
      padding: $u-300 $u-200;
      transition: 0.2s ease-out;

      // .store-autocomplete__results__item--is-focused
      // .store-autocomplete__results__item:hover
      &--is-focused,
      &:hover {
        background: $c-nt-200;
      }

      // .store-autocomplete__results__item--is-caption
      &--is-caption {
        font-size: 10px;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: initial;
        color: $c-nt-500;

        // .store-autocomplete__results__item--is-caption:hover
        &:hover {
          background: initial;
        }
      }

      &--flex {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
      }

      // .store-autocomplete__results__item__headline
      &__headline {
        font-size: $font-size-h4;
      }

      // .store-autocomplete__results__item__address
      &__address {
        font-size: $global-font-size-md;
        font-style: normal;
      }
    }
  }
}
