/**
 * oneblock-boxshadow.css
 * @version 1.0.00
 * @summary 24-02-2020
 * @author Mads Stoumann
 * @description OneBlock: Boxshadow
*/
/*
===============================
Prefix:
-O OneBlock

Key:
-bx

Value:
1-?
===============================
*/

.-O-bx1 { box-shadow: var(--ob-O-bx1); }
.-O-bx2 { box-shadow: var(--ob-O-bx2); }
.-O-bx3 { box-shadow: var(--ob-O-bx3); }
.-O-bx4 { box-shadow: var(--ob-O-bx4); }