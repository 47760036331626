/**
 * oneblock-opacity.css
 * @version 1.0.00
 * @summary 30-09-2019
 * @author Mads Stoumann
 * @description OneBlock: Opacity
 */

/* Opacity
===============================
Prefix:
-E Content Box

Key:
-op

Value:
1-9
===============================
*/

.-E-op1 .c-ob__content {
	--ob-alpha: 0.1;
}
.-E-op2 .c-ob__content {
	--ob-alpha: 0.2;
}
.-E-op3 .c-ob__content {
	--ob-alpha: 0.3;
}
.-E-op4 .c-ob__content {
	--ob-alpha: 0.4;
}
.-E-op5 .c-ob__content {
	--ob-alpha: 0.5;
}
.-E-op6 .c-ob__content {
	--ob-alpha: 0.6;
}
.-E-op7 .c-ob__content {
	--ob-alpha: 0.7;
}
.-E-op8 .c-ob__content {
	--ob-alpha: 0.8;
}
.-E-op9 .c-ob__content {
	--ob-alpha: 0.9;
}