// Helpers
#{$prefix} {
  // .cm-sr-only
  &sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }
  // .cm-is-hidden
  &is-hidden {
    visibility: hidden;
  }
  // .cm-text-left
  &text-left {
    text-align: left;
  }
  &text-left-md {
    @include mq($screen-sm, min) {
      text-align: left;
    }
  }
  // .cm-text-center
  &text-center {
    text-align: center;
  }
  &text-center-md {
    @include mq($screen-sm, min) {
      text-align: center;
    }
  }
  // .cm-text-right
  &text-right {
    text-align: right;
  }
  &text-right-md {
    @include mq($screen-sm, min) {
      text-align: right;
    }
  }
  // .cm-text-bold
  &text-bold {
    font-weight: 700;
  }
  // .cm-text-disabled
  &text-disabled {
    color: $color-gray;
  }
  // .cm-text-brand-main
  &text-brand-main {
    color: $c-pm-500;
  }
  // .cm-text-light
  &text-light {
    color: $color-gray-10;
  }
  // .cm-text-sm
  &text-sm {
    font-size: 12px;
  }

  // Margins

  // .cm-mb
  &mb {
    margin-bottom: 20px;
    // .cm-mb--sm
    &--sm {
      margin-bottom: 30px;
    }
    // .cm-mb--lg
    &--lg {
      margin-bottom: 75px;
    }
  }

  // .cm-centered-content
  &centered-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    // .cm-centered-content--lowered
    &--lowered {
      top: 60%;
    }
  }
}
