@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

$margin-padding-base: 8;

@mixin margin($direction: all, $size: 1, $important: false) {
  $amount: #{$size * $margin-padding-base}px;

  @if ($important) {
    $amount: #{$size * $margin-padding-base}px !important;
  }

  @if $direction == all {
    margin: $amount;
  } @else if $direction == top {
    margin-top: $amount;
  } @else if $direction == left {
    margin-left: $amount;
  } @else if $direction == right {
    margin-right: $amount;
  } @else if $direction == vertical {
    margin-top: $amount;
    margin-bottom: $amount;
  } @else if $direction == horizontal {
    margin-right: $amount;
    margin-left: $amount;
  } @else {
    margin-bottom: $amount;
  }
}

@mixin padding($direction: all, $size: 1, $important: false) {
  $amount: #{$size * $margin-padding-base}px;

  @if ($important) {
    $amount: #{$size * $margin-padding-base}px !important;
  }

  @if $direction == all {
    padding: $amount;
  } @else if $direction == top {
    padding-top: $amount;
  } @else if $direction == left {
    padding-left: $amount;
  } @else if $direction == right {
    padding-right: $amount;
  } @else if $direction == vertical {
    padding-top: $amount;
    padding-bottom: $amount;
  } @else if $direction == horizontal {
    padding-right: $amount;
    padding-left: $amount;
  } @else {
    padding-bottom: $amount;
  }
}

@mixin generateTypographyStyles(
  $size,
  $line-height,
  $font-family,
  $weight,
  $color,
  $text-transform
) {
  font-size: rem-calc(nth($size, 1));
  line-height: rem-calc(nth($line-height, 1));
  font-family: #{$font-family};
  font-weight: #{$weight};

  @if ($color) {
    color: $color;
  }

  @if ($text-transform) {
    text-transform: $text-transform;
  }

  @include mq($screen-md, min) {
    font-size: rem-calc(nth($size, 2));
    line-height: rem-calc(nth($line-height, 2));
  }
}

@mixin typography(
  $type,
  $overwriteWeight: null,
  $overwriteFont: null,
  $overwriteFontSizes: null
) {
  $rules: map-get($typography-list, $type);

  $font-size: map-get($rules, font-size);
  $line-height: map-get($rules, line-height);
  $font-family: map-get($rules, font-family);
  $text-transform: map-get($rules, text-transform);
  $weight: map-get($rules, weight);
  $color: map-get($rules, color);

  @if ($font-family == null) {
    $font-family: $global-font-family-2;
  }

  @if ($weight == null) {
    $weight: normal;
  }

  @if ($overwriteFont != null) {
    $font-family: $overwriteFont;
  }

  @if ($overwriteWeight != null) {
    $weight: $overwriteWeight;
  }

  @if ($overwriteFontSizes != null) {
    $font-size: $overwriteFontSizes;
  }

  @include generateTypographyStyles(
    $font-size,
    $line-height,
    $font-family,
    $weight,
    $color,
    $text-transform
  );
}

/// - Retrieves a key from a theme-map, returns $val if not exists
/// @name val
/// @author Mads Stoumann
/// @param {String} $map - Theme-map to use
/// @param {String} $key - Key
/// @param {String} $val - Fallback value

@function val($map, $key, $val) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  } @else {
    @return $val;
  }
}

/// - Renders a down-pointing arrow as a pseudo-element
/// @name arrow
/// @author Mads Stoumann
/// @param {Object} $theme - Theme-map to use
@mixin arrow($theme) {
  &::after {
    background-color: transparent;
    border-color: map-deep-get($theme, arrow, border-color);
    border-style: solid;
    border-width: 0px map-deep-get($theme, arrow, border-width)
      map-deep-get($theme, arrow, border-width) 0px;
    content: '';
    display: block;
    height: map-deep-get($theme, arrow, size);
    margin: 0 auto;
    transform: rotate(45deg);
    width: map-deep-get($theme, arrow, size);
  }
}
/// - Use clip-path to "cut corners" of a box
/// @name cut-corners
/// @author Mads Stoumann
/// @param {String} $top
/// @param {String} $right
/// @param {String} $bottom
/// @param {String} $left
@mixin cut-corners($top, $right, $bottom, $left) {
  clip-path: polygon(
    0% $top,
    $top 0%,
    calc(100% - #{$right}) 0%,
    100% $right,
    100% calc(100% - #{$bottom}),
    calc(100% - #{$bottom}) 100%,
    $left 100%,
    0% calc(100% - #{$left})
  );
}
