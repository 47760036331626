
































































@import 'theme/sass/settings/_settings.global.scss';

.billing-address {
  font-size: $font-size-h5;
}
