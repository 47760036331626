




























































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.ui-autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  display: none;
  float: left;
  min-width: 160px;
  padding: $u-150 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: $font-size-h5;
  text-align: left;
  background-color: $color-white;
  border: $u-100 solid $c-nt-300;
  border: $u-100 solid rgba($color-black, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: $box-shadow-default;
  box-shadow: $box-shadow-default;
  background-clip: padding-box;
}

.ui-autocomplete > li > div {
  display: block;
  padding: $u-350 $u-350;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: $c-nt-850;
  white-space: nowrap;
}

.ui-state-hover,
.ui-state-active,
.ui-state-focus {
  text-decoration: none;
  color: $c-nt-800;
  background-color: $c-nt-50;
  cursor: pointer;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: $u-100;
  margin: -$u-100;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: $u-100;
}

.autocomplete {
  position: relative;
  width: 130px;
}

.autocomplete-results {
  margin: 0;
  border: $u-100 solid $c-nt-300;
  background-color: $color-white;
  height: 200px;
  overflow: auto;
  width: 250px;
  color: $color-black;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: $u-150 $u-250;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: $c-nt-50;
  color: $color-black;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: $c-nt-50;
  color: $color-black;
}

.question-item {
  padding: $u-350 $u-250 $u-350 0;
  display: inline-flex;
  align-items: center;
  width: 300px;
}

.form-control {
  width: 100%;
  padding: $u-250;
  border: $u-100 solid $c-nt-300;
  border-radius: 2px;
  height: 54px;
}

.form-control:hover {
  border: $u-100 solid #6e6e6e;
}

.form-control:focus {
  border: $u-100 solid #990ae3;
}

.replace-retail-header {
  font-family: 'Pebble';
  text-transform: uppercase;
  font-size: $font-size-h2;
}

.replace-retail-description {
  padding: $u-150 0;
}

.replace-retail-price {
  font-family: Pebble;
  font-size: $font-size-h2;
  text-transform: uppercase;
  color: $color-black;
  padding-top: $u-400;
  padding-bottom: $u-400;
}

.replace-retail-wrapper {
  padding: $u-250;
}

.answer-item {
  display: inline-flex;
  align-items: center;
  margin-left: $u-350;
}

.question-item-wrapper {
  display: inline-grid;
}

.retail-radio-text {
  padding-left: $u-250;
}
