
































































































.c-input {
  &__error {
    position: initial;
  }

  &__label {
    pointer-events: none;
  }
}
