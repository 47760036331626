




















































































.c-layer-panel {
  position: relative;
  height: 100%;
  width: 75%;
  box-shadow: 15px 0 250px 15px #2d2d2d;
  pointer-events: auto;
  background-color: white;
  overflow: auto;

  & > header {
    padding: 20px 15px 20px;
    position: relative;
    margin-bottom: 20px;
    color: black;
  }

  &-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  &--slide {
    &-enter-active,
    &-leave-active {
      transition: transform .3s ease-in-out;
    }

    &-enter,
    &-leave-to {
      transform: translateX(-100%);
    }
  }

  &--no-close {
    padding-top: 0;
  }
}
