











































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.c-checkbox {
  position: relative;
  min-height: $u-400;
  padding-left: $u-400;
  display: flex;

  // c-checkbox__input
  &__input {
    position: absolute;
    top: $u-150;
    left: 0;
    height: $u-300;
    width: $u-300;
    z-index: -1;
    opacity: 0;

    &:checked {
      & + .c-checkbox__label {
        &:before {
          color: $color-white;
          border-color: #007bff;
          background-color: #007bff;
        }
      }
    }
  }

  &:not(.c-checkbox--switch) {
    .c-checkbox {
      &__input:checked {
        & + .c-checkbox__label {
          &:after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 9'%3E%3Cpath fill='%23fff' d='M10.122 0.334089L4.36991 6.25806L1.88276 3.69661C1.45304 3.25404 0.754426 3.25404 0.322297 3.69661C-0.107432 4.14165 -0.107432 4.85866 0.322297 5.3037L3.58728 8.66622C3.79374 8.87885 4.07702 9 4.36751 9C4.658 9 4.94128 8.87885 5.14774 8.66622L11.6777 1.9387C12.1074 1.49614 12.1074 0.776657 11.6777 0.331617C11.2504 -0.11095 10.5518 -0.11095 10.122 0.334089Z'/%3E%3C/svg%3E");
          }
        }
      }

      &__label:hover:before {
        border: 2px solid $c-nt-900;
        border-radius: 2px;
      }

      &__input:checked {
        & + .c-checkbox__label {
          &:hover:before {
            background-color: $c-pm-400;
            border-color: $c-pm-400;
            border-radius: 2px;
          }
        }
      }
    }
  }

  // c-checkbox__label
  &__label {
    position: relative;
    margin-bottom: 0;
    vertical-align: middle;
    line-height: $u-400;
    min-height: $u-400;
    display: inline-flex;

    &:before,
    &:after {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: -$u-400;
      display: block;
      width: $u-400;
      height: $u-400;
      content: '';
    }

    &:before {
      border-radius: 3px;
      pointer-events: none;
      background-color: $color-white;
      border: $u-100 solid $c-nt-500;
      transition: all 0.15s ease-in-out;
    }

    &:after {
      background: no-repeat 50%/50% 50%;
    }

    &--error {
      &:before {
        border-color: $c-sp-red-500;
      }
    }

    .description {
      display: none;
    }
  }

  // c-checkbox--switch
  &--switch {
    padding-left: $u-550;
    height: $u-500;

    .c-checkbox {
      &__label-slot-wrapper {
        margin-left: 42px;
        display: flex;
      }

      &__label {
        &:before {
          left: -$u-550;
          width: 65px;
          height: $u-500;
          pointer-events: all;
          border-radius: 100px;
          border: none;
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        &:after {
          top: 3px;
          left: calc(-2.25rem + 3px);
          width: 26px;
          height: 26px;
          background-color: #a0a0a0;
          border-radius: 100px;
          transition: left 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            -webkit-transform 0.15s ease-in-out;
        }
      }

      &__input {
        &:checked + .c-checkbox__label {
          &:after {
            left: 0;
            background-color: $color-white;
          }
        }
      }
    }
  }

  // .c-checkbox--only
  &--only {
    padding-left: 0;

    .c-checkbox__label {
      &:before {
        position: relative;
        left: 0px;
      }
      &:after {
        left: 0px;
      }
    }

    &.c-checkbox--switch {
      .c-checkbox {
        &__label:after {
          left: 2px;
        }

        &__input {
          &:checked + .c-checkbox__label {
            &:after {
              left: calc(1rem - 2px);
            }
          }
        }
      }
    }
  }

  &__label-slot-wrapper {
    display: flex;
    align-items: center;
    margin-left: $u-250;
  }

  &--styled {
    .c-checkbox__label-slot-wrapper {
      margin-left: $u-200;
    }

    .c-checkbox__label {
      &:before,
      &:after {
        width: $u-350;
        height: $u-350;
        top: 2px;
      }

      &:before {
        border-radius: 2px;
        border: 2px solid #222;
      }

      /*
       * The width in '&after' is set larger than the element to make the checkmark fill the entire checkbox.
       * Below numbers are deliberately written in pixels values instead of using spacing variables etc
       * because they are independent from the variables.
       */
      &:after {
        left: -34px;
        width: $u-600;
      }
    }
  }

  // .c-checkbox--large
  &--large {
    width: 100%;
    background-color: $color-white;
    border: $u-100 solid $c-nt-300;
    border-radius: 3px;
    padding: $u-300 $u-250 $u-300 $u-600;

    // .c-checkbox--large--checked
    &--checked {
      background-color: $c-pm-100;
      border-color: $c-pm-500;
    }

    &--error {
      border-color: $c-sp-red-500;
    }

    .c-checkbox {
      // .c-checkbox__inner-wrapper
      &__inner-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: $u-200;
      }

      // .c-checkbox__description
      &__description {
        font-size: $global-font-size-md;
        line-height: $u-350;
        color: $c-nt-700;
      }
    }
  }
}
