






















































































































































































































@import 'theme/sass/settings/_settings.vars.scss';

.employee-config {
  &__legend {
    margin-bottom: $u-500;
  }

  &__headline {
    margin-bottom: $u-200;
  }

  &__footer {
    margin-top: $u-300;
  }
}
