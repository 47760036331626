$c-badge-small-size: 8px;

@mixin c-badge($theme) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: map-get($theme, min-height);
  min-width: map-get($theme, min-width);
  color: map-get($theme, color);
  background-color: map-get($theme, background-color);
  border-radius: map-get($theme, border-radius);
  font-size: map-get($theme, font-size);
  margin-left: map-get($theme, margin-left);
  padding: map-get($theme, padding);
  font-style: map-get($theme, font-style);
  font-weight: map-get($theme, weight);

  // .c-badge--round
  &--round {
    min-height: map-deep-get($theme, round, min-height);
    min-width: map-deep-get($theme, round, min-width);
    color: map-deep-get($theme, round, color);
    background-color: map-deep-get($theme, round, background-color);
    border-radius: map-deep-get($theme, round, border-radius);
    font-size: map-deep-get($theme, round, font-size);
    line-height: map-deep-get($theme, round, font-size);
    padding: 0;
    width: map-deep-get($theme, round, width);
    height: map-deep-get($theme, round, height);

    // .c-badge--small
    &--small {
      min-height: 0;
      min-width: 0;
      width: $c-badge-small-size;
      height: $c-badge-small-size;
    }
  }

  // .c-badge--primary
  &--primary {
    background-color: map-deep-get($theme, primary, background-color);
    color: map-deep-get($theme, primary, color);
  }

  // .c-badge--secondary
  &--secondary {
    background-color: map-deep-get($theme, secondary, background-color);
    color: map-deep-get($theme, secondary, color);
  }

  // .c-badge--tertiary
  &--tertiary {
    background-color: map-deep-get($theme, tertiary, background-color);
    color: map-deep-get($theme, tertiary, color);
  }

  &--presale {
    background-color: map-deep-get($theme, presale, background-color);
    color:  map-deep-get($theme, presale, color);
  }

  &--news {
    background-color: map-deep-get($theme, news, background-color);
    color: map-deep-get($theme, news, color);
  }

  &--black-friday {
    background-color: map-deep-get($theme, black-friday, background-color);
    color: map-deep-get($theme, black-friday, color);
  }

  // .c-badge--top-left
  &--top-left {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    left: -2px;
    top: -2px;
  }
}

.c-badge {
  @include c-badge($c-badge);
}
