





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$product-bg-color: $c-nt-200;

.product-page,
.broadband-offerings-page {
  // .product-page--in-overlay
  &--in-overlay {
    margin-bottom: 150px; // Height of shop footer
  }

  // .product-page__header
  &__header {
    position: relative;
    z-index: 1;
  }

  // .product-page__wizard-footer
  &__wizard-footer {
    margin-top: $u-250;
    order: map-deep-get($product-order, 'usp', 'xs');

    @include mq($screen-md, min) {
      width: 100%;
    }

    .c-section__item {
      margin-bottom: $u-300;

      @include mq($screen-md, max) {
        margin-bottom: 0;
      }
    }
  }

  // .product-page__footer-cta
  &__footer-cta {
    & > button:first-child {
      margin-bottom: $u-200;
    }
  }

  // .product-page__price-disclaimer
  &__price-disclaimer {
    font-size: $global-font-size-sm;
  }

  // .product-page__pebble-bg-wrapper
  &__pebble-bg-wrapper {
    position: absolute;
    top: -5vw;
    width: 80vw;
    height: 80vw;
    max-height: 900px;
    max-width: 900px;
    overflow: hidden;
    right: 0;
    left: 20vw;
    margin: 0 auto;

    @include mq($screen-xxl, max) {
      right: 0;
      left: auto;
      margin: 0;
    }
    @include mq($screen-md, max) {
      top: $u-800;
    }
    @include mq($screen-sm, max) {
      width: 100vw;
      height: 100vw;
    }
  }

  // .product-page__pebble-bg
  &__pebble-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: url('#{$images-path}/pebble-bg.svg') center / contain no-repeat;
    @include mq($screen-xxl, max) {
      transform: translateX(5vw);
    }
  }

  // .product-page__promotion
  &__promotion {
    order: map-deep-get($product-order, 'promotion', 'xs');
  }

  // .product-page__countdown
  &__countdown {
    order: map-deep-get($product-order, 'countdown', 'xs');

    @include mq($screen-md, min) {
      order: map-deep-get($product-order, 'countdown', 'md');
    }
  }

  // .cm-o-layout
  .cm-o-layout {
    z-index: unset;
    overflow-x: hidden;
    padding-top: 0;
    position: relative;

    @include mq($screen-md, min) {
      margin-top: $u-600;
      padding: 0 $u-700;
      overflow: unset;
    }

    // .cm-o-layout--bg-white
    &--bg-white & {
      background-color: $color-white;
    }

    // .cm-o-layout--no-padding-desktop
    &--no-padding-desktop {
      @include mq($screen-sm, min) {
        padding: 0 0 0 0;
      }
    }

    &__link-cloud {
      margin: $u-600 0;
    }
  }
}
