.c-enterprise-blocks {
  width: calc(100% + 24px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px 50px -12px;

  @include mq($screen-sm, max) {
    display: block;
  }

  & > li:not(.c-enterprise-blocks__overlay) {
    width: 100%;

    @include mq($screen-sm, min) {
      width: 50%;
      flex-shrink: 0;
    }

    @include mq($screen-md, min) {
      width: calc(100% / 3);
    }
  }

  // .c-enterprise-blocks__overlay
  &__overlay {
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(color(accent, grey, 90), 0.3);
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 100%;
    opacity: 0;
    transition: opacity 0.3s ease, left 0s ease 0.3s;

    @include mq($screen-md, min) {
      z-index: 9990;
      .enterprise-blocks-is-active & {
        left: 0;
        opacity: 1;
        transition: opacity 0.3s ease, left 0s ease 0s;
      }
    }
  }
}

.c-enterprise-block {
  height: calc(100% - 48px);
  padding: 0 20px 40px;
  @include margin(horizontal, 1.5);
  @include margin(top, 0);
  @include margin(bottom, 2);
  background-color: color(primary, light, 100);
  box-shadow: $box-shadow-default;
  border-radius: 3px;
  position: relative;
  transition: box-shadow 0.3s ease 0.1s;

  @include mq($screen-sm, min) {
    margin: 30px 12px 17px;
    padding: 0 20px 40px;
    text-align: center;
  }

  &.enterprise-block-is-active {
    box-shadow: $box-shadow-default;

    @include mq($screen-sm, min) {
      z-index: 9999;
    }
  }

  // .c-enterprise-block__icon
  &__icon {
    display: block;
    width: 48px;
    height: 48px;
    margin-bottom: -30px;
    border-radius: 50%;
    background-color: color(primary, light);
    box-shadow: $box-shadow-default;
    position: relative;
    z-index: 0;
    left: -28px;
    top: $u-200;
    transition: box-shadow 0.3s ease 0.1s;

    @include mq($screen-sm, min) {
      width: 60px;
      height: 60px;
      left: 50%;
      top: -30px;
      transform: translateX(-50%);
    }

    .enterprise-block-is-active & {
      box-shadow: $box-shadow-default;
    }

    img,
    svg {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      transform: translate(-50%, -50%);

      @include mq($screen-sm, min) {
        width: 36px;
        height: 36px;
      }
    }

    svg {
      fill: color(primary, dark);
    }

    &:after {
      content: '';
      display: block;
      width: 60px;
      height: 100px;
      background-color: color(primary, light, 100);
      position: absolute;
      z-index: 2;
      left: 38px;
      top: -$u-200;
      transform: translateX(-50%);

      @include mq($screen-sm, min) {
        width: 100px;
        height: 60px;
        left: 50%;
        top: 50%;
      }
    }
  }

  // .c-enterprise-block__content
  &__content {
    padding-left: 22px;
    position: relative;
    z-index: 1;

    @include mq($screen-sm, min) {
      padding-left: 0;
    }

    & > a {
      color: color(primary, core);
    }
  }

  // .c-enterprise-block__headline
  &__headline {
    @include margin(bottom, 0.5);
    @include typography(h5);
    text-transform: uppercase;
    color: color(primary, core);

    @include mq($screen-md, min) {
      @include typography(h6);
    }
  }

  // .c-enterprise-block__body
  &__body {
    @extend .c-article__body;

    p {
      @include margin(bottom, 0.5, true);

      @include mq($screen-sm, min) {
        @include margin(bottom, 3, true);
      }

      .enterprise-block-is-active & {
        @include mq($screen-sm, max) {
          @include margin(bottom, 2, true);
        }
      }
    }
  }

  // .c-enterprise-block__links
  &__links {
    width: 100%;
    padding: 0 20px;
    background-color: color(primary, light, 100);
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    text-align: center;
    transition: box-shadow 0.3s ease 0.1s;

    @include mq($screen-md, max) {
      position: relative;
      width: calc(100% + #{$u-600});
      @include margin(horizontal, -2.5);
      @include margin(bottom, -5);
    }

    .enterprise-block-is-active & {
      bottom: auto;
      box-shadow: 0 25px 16px 3px rgba(color(primary, dark, 100), 0.08);
    }

    ul {
      display: block;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;

        &:last-child {
          margin-bottom: 15px;
        }

        @include margin(bottom, 2);

        @include mq($screen-md, min) {
          @include margin(bottom);
        }

        a {
          @extend .link;

          font-size: rem-calc(14);
          line-height: rem-calc(20);
        }
      }
    }
  }

  // .c-enterprise-block__text
  &__links-text {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.4;
  }

  // .c-enterprise-block__links-collapse
  &__links-collapse {
    width: 100%;
    height: 0;
    padding-left: 22px;
    overflow: hidden;
    text-align: left;

    @include mq($screen-sm, min) {
      padding-left: 0;
      text-align: center;
    }

    .enterprise-block-is-active & {
      height: auto;
    }
  }

  // .c-enterprise-block__links-toggle
  &__links-toggle {
    display: inline-block;
    width: 24px;
    padding: 0 0 8px;
    margin: 0;
    border: none;
    outline: none;
    background-color: transparent;

    &:hover svg {
      transform: translateY(4px);
    }

    .enterprise-block-is-active & svg {
      transform: rotate(180deg);

      &:hover {
        transform: rotate(180deg) translateY(4px);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: color(primary, core, 100);
      transition: transform 0.4s ease;
    }
  }
}
