
























































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';

h2 {
  font-family: $global-font-family-2;
  font-size: $font-size-h3;
  margin-top: $u-350;
}

h3 {
  font-size: $font-size-h4;
  margin-top: $u-200;
}

.product-number-selector {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 601px) {
    padding: 0 $u-800 $u-350 $u-800;
  }

  // .product-number-selector__icon
  &__icon {
    width: 30px;
    height: 30px;
  }

  // .product-number-selector__numbers
  &__numbers {
    width: 100%;
    max-width: 430px;
    margin-top: $u-300;
  }

  // .product-number-selector__selected-label
  &__selected-label {
    display: flex;
    align-items: center;
    padding: 0 $u-300;

    svg {
      $checkmark-icon-size: 24px;

      width: $checkmark-icon-size;
      height: $checkmark-icon-size;
    }
  }
}
