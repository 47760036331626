





































































































































































































































































































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';

.existing-number-config {
  // .existing-number-config__number-options
  &__number-options {
    display: flex;
    flex-direction: column;
    margin-top: $u-200;

    // .existing-number-config__number-options__show-more
    &__show-more {
      align-self: flex-start;
    }

    &__legend {
      margin-bottom: $u-250;
      font-weight: 700;
    }

    &__subscription-name {
      font-weight: 700;
    }
  }

  // .existing-number-config__aditional-options
  &__additional-options {
    // .existing-number-config__aditional-options-label
    &-label {
      font-weight: 700;
      margin-top: $u-450;
      margin-bottom: $u-300;
    }
  }

  // .existing-number-config__aditional-option
  &__additional-option {
    display: flex;
    align-items: center;
    margin-bottom: $u-300;

    // .existing-number-config__additional-option-icon
    &-icon {
      margin-left: $u-150;
      width: 25px;
      height: 25px;
      vertical-align: middle;
    }
  }

  &__info-box {
    margin-top: 48px;
  }

  // existing-number-config__simcard-selection
  &__simcard-selection {
    margin-top: $u-500;
  }

  &__container {
    margin-right: 0.5em;
    margin-block: 0.5em;
    margin-bottom: 1em;
  }

  &__brand {
    display: inline;
    color: $c-pm-600;
  }

  &__header {
    display: inline;
  }

  &__info-footer {
    display: inline;
    margin-top: 1em;
  }
}

.c-btn--link {
  height: 1rem;
  vertical-align: initial;
  margin: 0px;
}
