




































@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.upfront-payment {
  margin: $u-600 0;

  .c-card__body {
    $card-padding-v: $u-800;
    padding-top: $card-padding-v;
    padding-bottom: $card-padding-v;

    h1{
      margin-bottom: $u-200;
    }

    p{
      font-size: $global-font-size;
    }
  }
}
