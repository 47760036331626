// Page
html,
body {
  width: 100%;
  height: 100%;
}

body {
  overflow-x: hidden;  
}

html {
  height: 100%;
  color: $global-text-color;
  font-family: $global-font-family;
  font-size: $global-font-size;
  line-height: $global-line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
