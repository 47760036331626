






























































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
.c-compare {
  $root: &;
  max-width: 960px;
  margin: auto;
  &__headline {
    text-align: center;
    margin-bottom: $u-600;
  }
  &__header {
    display: flex;
    justify-content: space-evenly;
    gap: $u-400;
    margin-bottom: $u-800;
    @include mq($screen-md, max) {
      gap: $u-250;
    }
  }
  &__specification_container {
    border-top: 1px solid $c-nt-500;
    padding-top: $u-500;
  }
  &__toggle-button {
    background: transparent;
    border: none;
    color: $c-pm-500;
    display: flex;
    align-items: center;
    gap: $u-250;
    margin-bottom: $u-300;
    svg {
      width: $u-300;
      height: $u-300;
      fill: $c-pm-500;
    }
  }
  &__specification-list {
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0;
    pointer-events: none;
    transition: all 0.125s ease-in-out;
    max-height: 0;
    will-change: opacity, transform, max-height;
    h3 {
      padding-top: $u-300;
      font-weight: 600;
    }
    &.expanded {
      border-top: 1px solid $c-nt-300;
      opacity: 1;
      transform: scaleY(1);
      pointer-events: inherit;
      max-height: 100%;
    }
  }
}
