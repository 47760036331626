﻿// TOOLS
@import 'tools/tools.flexbox';
@import 'tools/tools.clearfix';
@import 'tools/tools.functions';
@import 'tools/tools.media-query';
@import 'tools/tools.add-icon';

// GENERIC
@import 'generic/generic.box-sizing';
@import 'generic/generic.normalize';
@import 'generic/generic.reset';
@import 'generic/generic.grid';
@import 'generic/generic.helpers';

// ELEMENTS
@import 'elements/elements.page';
@import 'elements/elements.headings';
@import 'elements/elements.images';
@import 'elements/elements.links';
@import 'elements/elements.form';
@import 'elements/elements.fieldset';
@import 'elements/elements.select-box';

// OBJECTS
@import 'objects/objects.icons';
@import 'objects/objects.layout';
@import 'objects/objects.form-section';

#svg-icons {
  display: none;
}
