/**
 * oneblock-hide.css
 * @version 1.0.10
 * @summary 29-02-2020
 * @author Mads Stoumann
 * @description OneBlock: Hide elements
 */

/* Hide - Toggle visibility of individual parts
===============================
Prefix:
-A Additional headline
-C Image Caption
-E Content Wrapper
-H Headline
-I Image
-K Secondary Link
-L Primary Link
-M Meta
-N Tagline
-S Splash
-Dh Display headline
-T Text
-W Background Image/Video Wrapper
-X Section

Key:
-hd

Value:
1 Hide element
2 Hide sub-element
===============================
*/

.-C-hd1 .c-ob__bg-caption,
.-E-hd1 .c-ob__content,
.-A-hd1 .c-ob__additional-headline,
.-H-hd1 .c-ob__headline,
.-I-hd1 .c-ob__img,
.-K-hd1 .c-ob__link-text-sec,
.-L-hd1 .c-ob__link-text,
.-M-hd1 .c-ob__meta,
.-N-hd1 .c-ob__tagline,
.-P-hd1 .c-ob__offer,
.-R-hd1 .c-ob__ribbon,
.-S-hd1 .c-ob__splash,
.-Dh-hd1 .c-ob__display-headline,
.-T-hd1 .c-ob__text,
.-W-hd1 .c-ob__bg-wrapper {
  display: none;
}

/* Hide Background Image/Video when Background Media */
.-W-hd2 .c-ob__bg {
  display: none;
}
