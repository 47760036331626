/// EpiServer Form - Choice
/// - Version 1.0.0
/// - Date last edited: 2020-03-16
/// @name epi-form-choice
/// @author Bjarke Rasmussen, Valtech

@mixin epi-form-choice($theme) {
  &__label {
    display: inline-block;

    font-size: map-deep-get($theme, font-size, input);
    line-height: 18px;
    color: map-deep-get($theme, color, label, default);

    &:hover {
      .epi-form-choice__ui {
        background-color: map-deep-get($theme, ui, hit-area, hover);
      }
    }
  }

  &__input {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    overflow: hidden;

    &:checked {
      & ~ .epi-form-choice__ui {
        &:after {
          opacity: 1;
        }
      }
    }

    &:focus {
      & ~ .epi-form-choice__ui {
        background-color: map-deep-get($theme, ui, hit-area, focus);

        &:before {
          border-color: map-deep-get($theme, ui, outline, focus);
        }

        &:after {
          background-color: map-deep-get($theme, ui, inner, focus);
        }
      }
    }

    &:focus:checked {
      & ~ .epi-form-choice__ui {
        background-color: map-deep-get($theme, ui, hit-area, focus-checked);
      }
    }

    &[disabled] {
      & ~ .epi-form-choice__ui {
        background-color: transparent;

        &:before {
          border-color: map-deep-get($theme, ui, outline, disabled);
        }

        &:after {
          background-color: map-deep-get($theme, ui, inner, disabled);
        }

        & ~ .epi-form-choice__text {
          color: map-deep-get($theme, color, label, disabled);
        }
      }
    }
  }

  &__ui {
    display: inline-block;
    width: 36px;
    height: 36px;

    border-radius: 50%;

    background-color: map-deep-get($theme, ui, hit-area, default);

    position: relative;
    bottom: -12px;

    transition: background-color 0.15s ease;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;

      border-radius: 50%;
      border: 2px solid map-deep-get($theme, ui, outline, default);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      transition: opacity 0.15s ease, border-color 0.15s ease,
        background-color 0.15s ease;
    }

    &:after {
      content: '';
      display: block;
      width: $u-200;
      height: $u-200;

      border-radius: 50%;

      background-color: map-deep-get($theme, ui, inner, default);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      opacity: 0;

      transition: opacity 0.15s ease, background-color 0.15s ease;
    }
  }

  &__input[type='checkbox'] {
    & ~ .epi-form-choice__ui {
      &:before {
        width: 18px;
        height: 18px;

        border-radius: 2px;

        background-color: transparent;
      }

      &:after {
        width: 8px;
        height: 13px;

        border: 3px solid white;
        border-top: none;
        border-left: none;
        border-radius: 1px;

        background-color: transparent;

        transform: rotate(35deg) scale(0.85);

        left: 14px;
        top: 11px;
      }
    }

    &:checked {
      & ~ .epi-form-choice__ui {
        &:before {
          background-color: map-deep-get($theme, ui, outline, default);
        }
      }
    }

    &:focus:checked {
      & ~ .epi-form-choice__ui {
        &:before {
          background-color: map-deep-get($theme, ui, outline, focus);
        }
      }
    }

    &[disabled]:checked {
      & ~ .epi-form-choice__ui {
        &:before {
          background-color: map-deep-get($theme, ui, outline, disabled);
        }
      }
    }
  }

  .ValidationRequired & .Form__Element__Caption {
    &:after {
      content: '*';
      color: map-deep-get($theme, color, label, required);
    }
  }

  .ValidationFail.Form__Element & {
    .epi-form-choice__input {
      &:focus ~ .epi-form-choice__ui {
        background-color: map-deep-get($theme, ui, hit-area, invalid);
      }

      &:checked ~ .epi-form-choice__ui {
        &:after {
          background-color: map-deep-get($theme, ui, inner, invalid);
        }
      }

      &:focus:checked ~ .epi-form-choice__ui {
        &:after {
          background-color: map-deep-get($theme, ui, inner, invalid);
        }
      }

      &[type='checkbox']:checked ~ .epi-form-choice__ui,
      &[type='checkbox']:checked:focus ~ .epi-form-choice__ui {
        &:before {
          background-color: map-deep-get($theme, ui, outline, invalid);
        }

        &:after {
          background-color: map-deep-get($theme, ui, inner, invalid);
        }
      }
    }

    .epi-form-choice__ui {
      &:before {
        border-color: map-deep-get($theme, ui, outline, invalid);
      }
    }
  }

  .Form__Element__ValidationError {
    padding-left: 0;
    padding-right: 0;
  }
}

.epi-form-choice {
  @include epi-form-choice($epi-form-choice);
}
