








































































































































































































































































































































































































































@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.credit-check {
  &__body {
    margin: $u-200 auto $u-500;
    padding: 0 $u-200;
    width: 100%;

    & dd {
      margin-left: $u-250;
      text-align: left;
      word-wrap: break-word;
    }

    & dt {
      font-weight: 600;
      margin-right: $u-250;
      text-align: right;
    }

    & dt,
    & dd {
      flex: 1 1 calc(50% - #{$u-250});
      margin-bottom: $u-150;
    }
  }

  &__description {
    font-weight: 700;
  }

  &__result {
    width: 600px;

    &__status {
      font-weight: 600;

      &--approved {
        color: $c-sp-green-500;
      }

      &--denied {
        color: $c-sp-red-500;
      }
    }

    @include mq($container-sm, max) {
      width: 400px;
    }

    @include mq($container-xs, max) {
      width: 230px;
    }
  }

  &-info {
    border: $u-100 solid $c-nt-300;
    margin: $u-500 0;
    padding: $u-400 $u-300;

    &__description {
      color: $c-nt-600;
      font-size: 14px;
    }

    &__button {
      margin-top: $u-300;
      text-align: center;
    }
  }

  &__form {
    &__input {
      margin-bottom: $u-250;
      position: relative;

      &__icon {
        height: $u-350;
        position: absolute;
        right: $u-350;
        stroke: $c-nt-900;
        top: 50%;
        transform: translateY(-50%);
        width: $u-350;
        z-index: 1;
      }

      &--error {
        margin-bottom: $u-400;
      }
    }

    &__error-message {
      margin-top: $u-250;
      text-align: center;
      font-size: $font-size-h2;
      line-height: 2rem;
    }

    &__nr-porting-checkbox {
      margin-bottom: $u-300;
    }

    &__header {
      margin-top: $u-400;
      margin-bottom: $u-300;
      line-height: $global-line-height-sm;
    }

    &__terms-checkbox {
      @include mq($container-sm, max) {
        margin: $u-400 auto;
      }

      @include mq($container-xs, max) {
        margin: $u-600 auto;
      }
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-direction: column;

    &__initiate-btn {
      margin-bottom: $u-200;
    }
  }
}
