




































































































































































































@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.c-vas-configuration {
  padding-bottom: $u-350;

  &__disclaimer {
    font-size: $font-size-h5;
  }

  &__item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $u-350;

    > svg {
      flex-shrink: 0;
      height: 19px;
      margin: 0 $u-450;
      width: 19px;

      @include mq($screen-md, max) {
        margin: 0 $u-150;
      }
    }
  }

  &--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      margin-bottom: $u-350;
    }
  }

  &__divider {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
  }
}
