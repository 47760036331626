//.c-checkout-resurs-financing
.c-checkout-resurs-financing {

    //.c-checkout-resurs-financing__phone-secure-icon
    &__phone-secure-icon {

      //.c-checkout-resurs-financing__phone-secure-icon__container
      &__container {
        text-align: center;
      }
    }

    // c-checkout-resurs-financing__header
    &__header {
        text-align: center;
    }

    //.c-checkout-resurs-financing__subtitle
    &__subtitle {
        font-family: $global-font-family-2;
        font-size: $font-size-h2;
        font-weight: 700;
        margin: $u-200 auto $u-300;
    }

    //.c-checkout-resurs-financing__failure-section
    &__failure-section {
        text-align: center;

        //.c-checkout-resurs-financing__failure-section__icon
        &__icon {
            height: $u-750;
            margin-bottom: $u-250;
        }
    }

    //.c-checkout-resurs-financing__content
    &__content {
        margin-bottom: $u-350;
        font-size: $font-size-h5;
    }

    //.c-checkout-resurs-financing__btn-container
    &__btn-container {
        text-align: center;
        margin-top: $u-600;
    }

    //.c-checkout-resurs-financing__resurs-logo
    &__resurs-logo {
        margin-top: $u-600;
        height: $u-300;
        width: 96px;

        //.c-checkout-resurs-financing__resurs-logo__container
        &__container {
            text-align: center;
        }
    }

    //.c-checkout-resurs-financing__validation-error
    &__validation-error {
        color: $c-sp-red-500;
        display: inline-flex;
        padding: $u-150 $u-250 0 0;
        font-size: $font-size-h5;

        //.c-checkout-resurs-financing__validation-error__icon
        &__icon {
            height: $u-350;
            width: $u-350;
            fill: $c-sp-red-500;
            padding-right: $u-150;
        }

        //.c-checkout-resurs-financing__validation-error__text
        &__text {
            line-height: $global-line-height-sm;
        }
    }

    //.c-checkout-resurs-financing__redirect-error
    &__redirect-error {
        text-align: center;
        color: $c-sp-red-500;
        font-size: $font-size-h5;
        margin-top: $u-150;

        //.c-checkout-resurs-financing__redirect-error__text
        &__text {
            line-height: $global-line-height-sm;
        }
    }
}