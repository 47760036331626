//.c-checkout-receipt
.c-checkout-receipt {
  //.c-checkout-receipt__content
  &__content {
    font-size: $font-size-h5;
  }

  &__disclaimer {
    margin-bottom: $u-400;
  }

  //.c-checkout-receipt .secondary-receipt-part
  .secondary-receipt-part {
    clear: both;
    margin: 0 -$u-700 0 -$u-600;
    padding: $u-500 $u-700 $u-700 $u-600;

    @include mq($screen-md, max) {
      margin: 0 -$u-350;
    }

    //.c-checkout-receipt .secondary-receipt-part h3
    h3 {
      font-size: $font-size-h2;
      margin-bottom: $u-200;
    }

    //.c-checkout-receipt .secondary-receipt-part p
    p {
      margin-bottom: $u-250;
    }

    //.c-checkout-receipt .secondary-receipt-part a
    a {
      color: $c-pm-500;
    }

    //.c-checkout-receipt .secondary-receipt-part ul
    ul {
      list-style: disc;
      margin-left: $u-400;

      //.c-checkout-receipt .secondary-receipt-part ul li
      li {
        padding-left: 7px;
      }
    }
  }

  .cm-c-total__name {
    flex-direction: column;
  }
}
