/**
 * oneblock-typography.css
 * @version 1.1.20
 * @summary 02-03-2020
 * @author Mads Stoumann
 * @description OneBlock: Typography, font-sizes, line-heights etc.
 */

/* Font Name
===============================
Prefix:
-A Additional headline
-C Caption
-H Headline
-S Splash + Number, indicating text-line
-Dh Display headline
-T Text

Key:
-fn, -fz, -fw

Value:
1-?
===============================
*/

/* 1. Body Text Font */
.-C-fn1 .c-ob__bg-caption,
.-A-fn1 .c-ob__additional-headline,
.-H-fn1 .c-ob__headline,
.-N-fn1 .c-ob__tagline,
.-S1-fn1 .c-ob__splash-line-1,
.-S2-fn1 .c-ob__splash-line-2,
.-S3-fn1 .c-ob__splash-line-3,
.-T-fn1 .c-ob__text,
.-P-fn1 .c-ob__offer-price {
  font-family: var(--ob-font-1);
  text-transform: none;
}

/* 2. Headline Font */
.-C-fn2 .c-ob__bg-caption,
.-A-fn2 .c-ob__additional-headline,
.-Dh-fn2 .c-ob__display-headline,
.-H-fn2 .c-ob__headline,
.-N-fn2 .c-ob__tagline,
.-S1-fn2 .c-ob__splash-line-1,
.-S2-fn2 .c-ob__splash-line-2,
.-S3-fn2 .c-ob__splash-line-3,
.-T-fn2 .c-ob__text {
  font-family: var(--ob-font-2);
}

/* 3. Playful Numbers Font */
.-S1-fn3 .c-ob__splash-line-1,
.-S2-fn3 .c-ob__splash-line-2,
.-S3-fn3 .c-ob__splash-line-3,
.-P-fn3 .c-ob__offer-price {
  font-family: var(--ob-font-4);
}

/* Font Size
===============================
*/

/* Additional headline */
.-A-fz1 .c-ob__additional-headline {
  font-size: var(--ob-A-fz1);
  line-height: var(--ob-A-lh1);
}
.-A-fz2 .c-ob__additional-headline {
  font-size: var(--ob-A-fz2);
  line-height: var(--ob-A-lh2);
}
.-A-fz3 .c-ob__additional-headline {
  font-size: var(--ob-A-fz3);
  line-height: var(--ob-A-lh3);
}
.-A-fz4 .c-ob__additional-headline {
  font-size: var(--ob-A-fz4);
  line-height: var(--ob-A-lh4);
}
.-A-fz5 .c-ob__additional-headline {
  font-size: var(--ob-A-fz5);
  line-height: var(--ob-A-lh5);
}
.-A-fz6 .c-ob__additional-headline {
  font-size: var(--ob-A-fz6);
  line-height: var(--ob-A-lh6);
}

/* Display headline */
.-Dh-fz1 .c-ob__display-headline {
  font-size: var(--ob-Dh-fz1);
  line-height: var(--ob-Dh-lh1);
}
.-Dh-fz2 .c-ob__display-headline {
  font-size: var(--ob-Dh-fz2);
  line-height: var(--ob-Dh-lh2);
}
.-Dh-fz3 .c-ob__display-headline {
  font-size: var(--ob-Dh-fz3);
  line-height: var(--ob-Dh-lh3);
}
.-Dh-fz3 .c-ob__display-headline {
  font-size: var(--ob-Dh-fz3);
  line-height: var(--ob-Dh-lh3);
}
.-Dh-fz4 .c-ob__display-headline {
  font-size: var(--ob-Dh-fz4);
  line-height: var(--ob-Dh-lh4);
}
.-Dh-fz5 .c-ob__display-headline {
  font-size: var(--ob-Dh-fz5);
  line-height: var(--ob-Dh-lh5);
}
.-Dh-fz6 .c-ob__display-headline {
  font-size: var(--ob-Dh-fz6);
  line-height: var(--ob-Dh-lh6);
}
.-Dh-fz7 .c-ob__display-headline {
  font-size: var(--ob-Dh-fz7);
  line-height: var(--ob-Dh-lh7);
}

/* Image Caption */
.-C-fz1 .c-ob__bg-caption {
  font-size: var(--ob-C-fz1);
  line-height: var(--ob-C-lh1);
}
.-C-fz2 .c-ob__bg-caption {
  font-size: var(--ob-C-fz2);
  line-height: var(--ob-C-lh2);
}
.-C-fz3 .c-ob__bg-caption {
  font-size: var(--ob-C-fz3);
  line-height: var(--ob-C-lh3);
}
.-C-fz4 .c-ob__bg-caption {
  font-size: var(--ob-C-fz4);
  line-height: var(--ob-C-lh4);
}
.-C-fz5 .c-ob__bg-caption {
  font-size: var(--ob-C-fz5);
  line-height: var(--ob-C-lh5);
}
.-C-fz6 .c-ob__bg-caption {
  font-size: var(--ob-C-fz6);
  line-height: var(--ob-C-lh6);
}
.-C-fz7 .c-ob__bg-caption {
  font-size: var(--ob-C-fz7);
  line-height: var(--ob-C-lh7);
}
.-C-fz8 .c-ob__bg-caption {
  font-size: var(--ob-C-fz8);
  line-height: var(--ob-C-lh8);
}
.-C-fz9 .c-ob__bg-caption {
  font-size: var(--ob-C-fz9);
  line-height: var(--ob-C-lh9);
}

/* Headline */
.-H-fz14 .c-ob__headline {
  font-size: var(--ob-H-fz14);
  line-height: var(--ob-H-lh14);
}
.-H-fz1 .c-ob__headline {
  font-size: var(--ob-H-fz1);
  line-height: var(--ob-H-lh1);
}
.-H-fz20 .c-ob__headline {
  font-size: var(--ob-H-fz20);
  line-height: var(--ob-H-lh20);
}
.-H-fz2 .c-ob__headline {
  font-size: var(--ob-H-fz2);
  line-height: var(--ob-H-lh2);
}
.-H-fz3 .c-ob__headline {
  font-size: var(--ob-H-fz3);
  line-height: var(--ob-H-lh3);
}
.-H-fz4 .c-ob__headline {
  font-size: var(--ob-H-fz4);
  line-height: var(--ob-H-lh4);
}
.-H-fz5 .c-ob__headline {
  font-size: var(--ob-H-fz5);
  line-height: var(--ob-H-lh5);
}
.-H-fz6 .c-ob__headline {
  font-size: var(--ob-H-fz6);
  line-height: var(--ob-H-lh6);
}
.-H-fz7 .c-ob__headline {
  font-size: var(--ob-H-fz7);
  line-height: var(--ob-H-lh7);
}
.-H-fz8 .c-ob__headline {
  font-size: var(--ob-H-fz8);
  line-height: var(--ob-H-lh8);
}
.-H-fz9 .c-ob__headline {
  font-size: var(--ob-H-fz9);
  line-height: var(--ob-H-lh9);
}
.-H-fz10 .c-ob__headline {
  font-size: var(--ob-H-fz10);
  line-height: var(--ob-H-lh10);
}

/* Offer */
.-P-bf1 .c-ob__offer-base {
  font-size: var(--ob-P-bf1);
}
.-P-bf2 .c-ob__offer-base {
  font-size: var(--ob-P-bf2);
}
.-P-bf3 .c-ob__offer-base {
  font-size: var(--ob-P-bf3);
}
.-P-bf4 .c-ob__offer-base {
  font-size: var(--ob-P-bf4);
}
.-P-pf1 .c-ob__offer-price {
  font-size: var(--ob-P-pf1);
}
.-P-pf2 .c-ob__offer-price {
  font-size: var(--ob-P-pf2);
}
.-P-pf3 .c-ob__offer-price {
  font-size: var(--ob-P-pf3);
}
.-P-pf4 .c-ob__offer-price {
  font-size: var(--ob-P-pf4);
}
.-P-pf5 .c-ob__offer-price {
  font-size: var(--ob-P-pf5);
}
.-P-cf1 .c-ob__offer-currency {
  font-size: var(--ob-P-cf1);
}
.-P-cf2 .c-ob__offer-currency {
  font-size: var(--ob-P-cf2);
}
.-P-cf3 .c-ob__offer-currency {
  font-size: var(--ob-P-cf3, var(--ob-P-curr-fz0));
}
.-P-cf4 .c-ob__offer-currency {
  font-size: var(--ob-P-cf4, var(--ob-P-curr-fz0));
}
.-P-tf1 .c-ob__offer-text {
  font-size: var(--ob-P-tf1);
}
.-P-tf2 .c-ob__offer-text {
  font-size: var(--ob-P-tf2);
}
.-P-tf3 .c-ob__offer-text {
  font-size: var(--ob-P-tf3, var(--ob-P-text-fz0));
}
.-P-tf4 .c-ob__offer-text {
  font-size: var(--ob-P-tf4, var(--ob-P-text-fz0));
}
.-P-lf1 .c-ob__offer-price-label {
  font-size: var(--ob-P-lf1);
}
.-P-lf2 .c-ob__offer-price-label {
  font-size: var(--ob-P-lf2);
}
.-P-lf3 .c-ob__offer-price-label {
  font-size: var(--ob-P-lf3);
}

/* Splash, does not require line-height, as lines are placed using `x` and `y` */
.-S1-fz1 .c-ob__splash-line-1,
.-S2-fz1 .c-ob__splash-line-2,
.-S3-fz1 .c-ob__splash-line-3 {
  font-size: var(--ob-S-fz1);
}
.-S1-fz2 .c-ob__splash-line-1,
.-S2-fz2 .c-ob__splash-line-2,
.-S3-fz2 .c-ob__splash-line-3 {
  font-size: var(--ob-S-fz2);
}
.-S1-fz3 .c-ob__splash-line-1,
.-S2-fz3 .c-ob__splash-line-2,
.-S3-fz3 .c-ob__splash-line-3 {
  font-size: var(--ob-S-fz3);
}
.-S1-fz4 .c-ob__splash-line-1,
.-S2-fz4 .c-ob__splash-line-2,
.-S3-fz4 .c-ob__splash-line-3 {
  font-size: var(--ob-S-fz4);
}
.-S1-fz5 .c-ob__splash-line-1,
.-S2-fz5 .c-ob__splash-line-2,
.-S3-fz5 .c-ob__splash-line-3 {
  font-size: var(--ob-S-fz5);
}
.-S1-fz6 .c-ob__splash-line-1,
.-S2-fz6 .c-ob__splash-line-2,
.-S3-fz6 .c-ob__splash-line-3 {
  font-size: var(--ob-S-fz6);
}
.-S1-fz7 .c-ob__splash-line-1,
.-S2-fz7 .c-ob__splash-line-2,
.-S3-fz7 .c-ob__splash-line-3 {
  font-size: var(--ob-S-fz7);
}

/* Text */
.-T-fz7 .c-ob__text {
  font-size: var(--ob-T-fz7);
  line-height: var(--ob-T-lh7);
}
.-T-fz1 .c-ob__text {
  font-size: var(--ob-T-fz1);
  line-height: var(--ob-T-lh1);
}
.-T-fz2 .c-ob__text {
  font-size: var(--ob-T-fz2);
  line-height: var(--ob-T-lh2);
}
.-T-fz3 .c-ob__text {
  font-size: var(--ob-T-fz3);
  line-height: var(--ob-T-lh3);
}
.-T-fz4 .c-ob__text {
  font-size: var(--ob-T-fz4);
  line-height: var(--ob-T-lh4);
}
.-T-fz5 .c-ob__text {
  font-size: var(--ob-T-fz5);
  line-height: var(--ob-T-lh5);
}
.-T-fz6 .c-ob__text {
  font-size: var(--ob-T-fz6);
  line-height: var(--ob-T-lh6);
}

/* Inner Text Elements. !important is added because this section inherits font-sizes from RichText-block, but need to control these from the OB Editor. */
.-T-fz1 .c-ob__text * {
  font-size: var(--ob-T-fz1) !important;
  line-height: var(--ob-T-lh1) !important;
}
.-T-fz2 .c-ob__text * {
  font-size: var(--ob-T-fz2) !important;
  line-height: var(--ob-T-lh2) !important;
}
.-T-fz3 .c-ob__text * {
  font-size: var(--ob-T-fz3) !important;
  line-height: var(--ob-T-lh3) !important;
}
.-T-fz4 .c-ob__text * {
  font-size: var(--ob-T-fz4) !important;
  line-height: var(--ob-T-lh4) !important;
}
.-T-fz5 .c-ob__text * {
  font-size: var(--ob-T-fz5) !important;
  line-height: var(--ob-T-lh5) !important;
}
.-T-fz6 .c-ob__text * {
  font-size: var(--ob-T-fz6) !important;
  line-height: var(--ob-T-lh6) !important;
}
.-T-fz7 .c-ob__text * {
  font-size: var(--ob-T-fz7) !important;
  line-height: var(--ob-T-lh7) !important;
}

/* Tagline */
.-N-fz1 .c-ob__tagline {
  font-size: var(--ob-N-fz1);
}
.-N-fz2 .c-ob__tagline {
  font-size: var(--ob-N-fz2);
}

/* Font Weight
===============================
*/
.-A-fw1 .c-ob__additional-headline {
  font-weight: var(--ob-A-fw1);
}
.-C-fw1 .c-ob__bg-caption {
  font-weight: var(--ob-C-fw1);
}
.-H-fw1 .c-ob__headline {
  font-weight: var(--ob-H-fw1);
}
.-S1-fw1 .c-ob__splash-line-1 {
  font-weight: var(--ob-S1-fw1);
}
.-S2-fw1 .c-ob__splash-line-2 {
  font-weight: var(--ob-S2-fw1);
}
.-S3-fw1 .c-ob__splash-line-3 {
  font-weight: var(--ob-S3-fw1);
}
.-Dh-fw1 .c-ob__display-headline {
  font-weight: var(--ob-Dh-fw1);
}

.-A-fw2 .c-ob__additional-headline {
  font-weight: var(--ob-A-fw2);
}
.-C-fw2 .c-ob__bg-caption {
  font-weight: var(--ob-C-fw2);
}
.-H-fw2 .c-ob__headline {
  font-weight: var(--ob-H-fw2);
}
.-S1-fw2 .c-ob__splash-line-1 {
  font-weight: var(--ob-S1-fw2);
}
.-S2-fw2 .c-ob__splash-line-2 {
  font-weight: var(--ob-S2-fw2);
}
.-S3-fw2 .c-ob__splash-line-3 {
  font-weight: var(--ob-S3-fw2);
}
.-Dh-fw2 .c-ob__display-headline {
  font-weight: var(--ob-Dh-fw2);
}

.-A-fw3 .c-ob__additional-headline {
  font-weight: var(--ob-A-fw3);
}
.-C-fw3 .c-ob__bg-caption {
  font-weight: var(--ob-C-fw3);
}
.-H-fw3 .c-ob__headline {
  font-weight: var(--ob-H-fw3);
}
.-S1-fw3 .c-ob__splash-line-1 {
  font-weight: var(--ob-S1-fw3);
}
.-S2-fw3 .c-ob__splash-line-2 {
  font-weight: var(--ob-S2-fw3);
}
.-S3-fw3 .c-ob__splash-line-3 {
  font-weight: var(--ob-S3-fw3);
}
.-Dh-fw3 .c-ob__display-headline {
  font-weight: var(--ob-Dh-fw3);
}

.-A-fw4 .c-ob__additional-headline {
  font-weight: var(--ob-A-fw4);
}
.-C-fw4 .c-ob__bg-caption {
  font-weight: var(--ob-C-fw4);
}
.-H-fw4 .c-ob__headline {
  font-weight: var(--ob-H-fw4);
}
.-S1-fw4 .c-ob__splash-line-1 {
  font-weight: var(--ob-S1-fw4);
}
.-S2-fw4 .c-ob__splash-line-2 {
  font-weight: var(--ob-S2-fw4);
}
.-S3-fw4 .c-ob__splash-line-3 {
  font-weight: var(--ob-S3-fw4);
}
.-Dh-fw4 .c-ob__display-headline {
  font-weight: var(--ob-Dh-fw4);
}

.-A-fw5 .c-ob__additional-headline {
  font-weight: var(--ob-A-fw5);
}
.-C-fw5 .c-ob__bg-caption {
  font-weight: var(--ob-C-fw5);
}
.-H-fw5 .c-ob__headline {
  font-weight: var(--ob-H-fw5);
}
.-S1-fw5 .c-ob__splash-line-1 {
  font-weight: var(--ob-S1-fw5);
}
.-S2-fw5 .c-ob__splash-line-2 {
  font-weight: var(--ob-S2-fw5);
}
.-S3-fw5 .c-ob__splash-line-3 {
  font-weight: var(--ob-S3-fw5);
}
.-Dh-fw5 .c-ob__display-headline {
  font-weight: var(--ob-Dh-fw5);
}

.-A-fw6 .c-ob__additional-headline {
  font-weight: var(--ob-A-fw6);
}
.-C-fw6 .c-ob__bg-caption {
  font-weight: var(--ob-C-fw6);
}
.-H-fw6 .c-ob__headline {
  font-weight: var(--ob-H-fw6);
}
.-S1-fw6 .c-ob__splash-line-1 {
  font-weight: var(--ob-S1-fw6);
}
.-S2-fw6 .c-ob__splash-line-2 {
  font-weight: var(--ob-S2-fw6);
}
.-S3-fw6 .c-ob__splash-line-3 {
  font-weight: var(--ob-S3-fw6);
}
.-Dh-fw6 .c-ob__display-headline {
  font-weight: var(--ob-Dh-fw6);
}

/* Line-Clamp */
.-T-lc2 .c-ob__text,
.-T-lc3 .c-ob__text,
.-T-lc4 .c-ob__text,
.-T-lc5 .c-ob__text {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.-T-lc2 .c-ob__text {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.-T-lc3 .c-ob__text {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.-T-lc4 .c-ob__text {
  -webkit-line-clamp: 4;
  line-clamp: 4;
}
.-T-lc5 .c-ob__text {
  -webkit-line-clamp: 5;
  line-clamp: 5;
}

/* Text Decoration */
.-P-bt0 .c-ob__offer-base {
  text-decoration: none;
}
.-P-bt1 .c-ob__offer-base {
  text-decoration: line-through;
}
.-P-bt2 .c-ob__offer-base {
  text-decoration: underline;
}

/* Countdown */
.-Cnt-fz1 .c-ob__countdown .c-cnt__item,
.-Cnt-fz1 .c-ob__countdown .c-cnt__divider {
  font-size: var(--ob-Cnt-digit-fz1);
}
.-Cnt-fz2 .c-ob__countdown .c-cnt__item,
.-Cnt-fz2 .c-ob__countdown .c-cnt__divider {
  font-size: var(--ob-Cnt-digit-fz2);
}
.-Cnt-fz3 .c-ob__countdown .c-cnt__item,
.-Cnt-fz3 .c-ob__countdown .c-cnt__divider {
  font-size: var(--ob-Cnt-digit-fz3);
}
.-Cnt-fz4 .c-ob__countdown .c-cnt__item,
.-Cnt-fz4 .c-ob__countdown .c-cnt__divider {
  font-size: var(--ob-Cnt-digit-fz4);
}
.-Cnt-fz5 .c-ob__countdown .c-cnt__item,
.-Cnt-fz5 .c-ob__countdown .c-cnt__divider {
  font-size: var(--ob-Cnt-digit-fz5);
}

.-Cnt-fz1 .c-ob__countdown .c-cnt__label {
  font-size: var(--ob-Cnt-label-fz1);
}
.-Cnt-fz2 .c-ob__countdown .c-cnt__label {
  font-size: var(--ob-Cnt-label-fz2);
}
.-Cnt-fz3 .c-ob__countdown .c-cnt__label {
  font-size: var(--ob-Cnt-label-fz3);
}
.-Cnt-fz4 .c-ob__countdown .c-cnt__label {
  font-size: var(--ob-Cnt-label-fz4);
}
.-Cnt-fz5 .c-ob__countdown .c-cnt__label {
  font-size: var(--ob-Cnt-label-fz5);
}

.-Cnt-fz1 .c-ob__countdown .c-cnt__headline {
  font-size: var(--ob-Cnt-headline-fz1);
}
.-Cnt-fz2 .c-ob__countdown .c-cnt__headline {
  font-size: var(--ob-Cnt-headline-fz2);
}
.-Cnt-fz3 .c-ob__countdown .c-cnt__headline {
  font-size: var(--ob-Cnt-headline-fz3);
}
.-Cnt-fz4 .c-ob__countdown .c-cnt__headline {
  font-size: var(--ob-Cnt-headline-fz4);
}
.-Cnt-fz5 .c-ob__countdown .c-cnt__headline {
  font-size: var(--ob-Cnt-headline-fz5);
}

.-Cnt-fz1 .c-ob__countdown .c-cnt__divider {
  margin: 0 6px;
}
.-Cnt-fz2 .c-ob__countdown .c-cnt__divider {
  margin: 0 6px;
}
.-Cnt-fz3 .c-ob__countdown .c-cnt__divider {
  margin: 0 4px;
}
.-Cnt-fz4 .c-ob__countdown .c-cnt__divider {
  margin: 0 4px;
}
.-Cnt-fz5 .c-ob__countdown .c-cnt__divider {
  margin: 0 4px;
}

.-Cnt-fz1 .c-ob__countdown .c-cnt__item:last-of-type {
  width: var(--ob-Cnt-digit-width-sz1);
}
.-Cnt-fz2 .c-ob__countdown .c-cnt__item:last-of-type {
  width: var(--ob-Cnt-digit-width-sz2);
}
.-Cnt-fz3 .c-ob__countdown .c-cnt__item:last-of-type {
  width: var(--ob-Cnt-digit-width-sz3);
}
.-Cnt-fz4 .c-ob__countdown .c-cnt__item:last-of-type {
  width: var(--ob-Cnt-digit-width-sz4);
}
.-Cnt-fz5 .c-ob__countdown .c-cnt__item:last-of-type {
  width: var(--ob-Cnt-digit-width-sz5);
}
