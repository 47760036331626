























































@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.redirection-popup {
  h2 {
    font-family: $global-font-family-2;
    margin-bottom: $u-300;
  }

  &__description {
    margin-bottom: $u-550;
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: $u-450;

    .c-btn--primary {
      margin-bottom: $u-200;
    }

    .c-btn--as-link {
      font-size: $font-size-h4;
    }
  }
}
