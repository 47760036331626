































































































































































































































































































































































@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.checkout-summary-configuration {
  &__subscription {
    font-size: $font-size-h5;
    margin-bottom: $u-300;

    &:last-of-type {
      margin-bottom: unset;
    }
  }

  $information-icon-size: 20px;

  &__technician-information-icon {
    margin-right: $u-100;
    max-height: $information-icon-size;
    max-width: $information-icon-size;
    vertical-align: text-bottom;
  }
}
