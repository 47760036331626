



































































































































































































































































































































































































































































































































































































































@import '../../styles/tools/_tools.media-query.scss';
@import '../../styles/breakpoints.scss';

$button-size: 18px;
$arrow-width: 3px;
$dot-size: 4px;

.c-carousel {
  position: relative;
  user-select: none;
  padding: 0 $button-size;

  &__list {
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
  }

  &__item {
    display: none;
    margin: 0;

    img {
      &:not(.allow-click) {
        pointer-events: none;
      }

      &.allow-click {
        cursor: pointer;
        user-select: none;
      }
    }

    &--active,
    &--right,
    &--left {
      display: block;
      transition: transform 0.3s ease-in-out;
      width: 100%;
      will-change: transform, opacity;

      &.c-carousel__item--center {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }

    &--right,
    &--left {
      left: 0;
      position: absolute;
      top: 0;
    }

    &--to-right {
      transform: translateX(-100%);
    }

    &--to-left {
      transform: translateX(100%);
    }

    &--right {
      transform: translateX(100%);
    }

    &--left {
      transform: translateX(-100%);
    }

    &--active {
      position: relative;
      transform: translate(0, 0);

      &.c-carousel__item--to-right {
        transform: translateX(100%);
      }

      &.c-carousel__item--to-left {
        transform: translateX(-100%);
      }
    }

    &--flex {
      flex: 0 0 var(--card-flex);
      opacity: 1;
      padding: 0 10px;
      transform: var(--card-transform);
      transition: var(--transition);
      will-change: transform;

      @include mq($breakpoint-desktop, min) {
        padding: 0 12px;
      }
    }
  }

  // .c-carousel__navigation
  &__navigation {
    & button {
      border: none;
      border-radius: 0;
      outline: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      color: inherit;
      font: inherit;
      line-height: normal;
      appearance: none;
      position: absolute;
      top: calc(50% - #{$button-size / 2});

      &:first-child {
        left: $arrow-width;
      }

      &:last-child {
        right: $arrow-width;
      }
    }

    &-text {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    &-arrow {
      border-bottom: $arrow-width solid black;
      border-right: $arrow-width solid black;
      content: '';
      display: block;
      height: $button-size;
      width: $button-size;

      &--prev {
        transform: rotate(135deg);
      }

      &--next {
        transform: rotate(-45deg);
      }
    }
  }

  // .c-carousel__page-dots
  &__page-dots {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &__dot {
      width: $dot-size;
      height: $dot-size;
      margin: $dot-size * 3 $dot-size * 1.5 0 $dot-size * 1.5;
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.1);

      &:after {
        // Create a larger invisble touch area to interact with each dots for increased a11y.
        content: '';
        cursor: pointer;
        display: inline-block;
        width: $dot-size * 3;
        height: $dot-size * 3;
        flex-shrink: 0;
      }

      &--active {
        background: #222;
      }
    }
  }

  // .c-carousel--draggable
  &--draggable {
    cursor: pointer;
  }

  // .c-carousel--dots
  &--dots {
    margin-bottom: $dot-size * 3;
  }
}
