






















































































































































































































@import 'theme/sass/settings/_settings.vars.scss';

.basket-coupon {
  &__modal__message {
    text-align: center;
    margin: $u-250;
  }
}
