




































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.product-stock-status {
  font-size: $global-font-size-md;
  line-height: $global-line-height-xs;
  text-align: center;

  // .product-stock-status__container
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  // .product-stock-status__text
  &__text {
    &::before {
      border-radius: 50%;
      content: '';
      height: $u-200;
      width: $u-200;
      margin-right: $u-150;
      display: inline-block;
    }
    &.green::before {
      background-color: $c-sp-green-500;
    }

    &.yellow::before {
      background-color: $c-sp-orange-400;
    }

    &.red::before {
      background-color: $c-sp-red-500;
    }
  }

  // .product-stock-status__text,
  // .product-stock-status__date
  &__text,
  &__date {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: $u-150;
  }

  // .product-stock-status__text,
  // .product-stock-status__date
  &__footer,
  &__text {
    width: 100%;
    flex-shrink: 0;
  }

  // TODO DKT-1431: could this be a variant of the countdown (add prop to countdown.vue?).
  .cm-c-countdown {
    $height: $font-size-h6;
    $width: 14px;

    height: $height;
    padding: 0;
    margin: 0;
    display: inline-flex;
    background: transparent;
    border-radius: 0;
    overflow: hidden;

    &__watch {
      display: inline-flex;
      margin: 0;
      font-size: $height;
      font-family: $global-font-family;
    }

    &__title,
    &__label {
      display: none;
    }

    &__number-container {
      width: $width;
      height: $height;
      line-height: $height;
    }

    &__separator {
      height: $height;
      line-height: $height;

      &::after {
        content: ':';
        width: auto;
        padding: 0;
      }
    }

    &__number {
      background: transparent;
      height: $height;

      &.-animating {
        margin-top: -$height;
        margin-bottom: 0;
      }
    }
  }
}
