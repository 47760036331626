// Grid
// sass-lint:disable-all
$grid-columns: 12 !default;

/* Local vars */
$gutter-width: $u-300 !default;
$outer-margin: $u-350;

$breakpoints: xs 0px 0px, sm 480px 480px, md 600px 600px, lg 800px 800px,
  xl 1024px 1024px, xxl 1500px 1500px;
$flexboxgrid-max-width: 1200px;
// sass-lint:enable-all
