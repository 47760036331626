






























































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

.cm-c-total__description {
  &__list-item {
    list-style-type: disc;
    margin-left: 16px;
  }

  &__link-item {
    margin-top: 8px;
    a {
      color: $c-pm-500;
      line-height: $u-300;
      font-size: $font-size-h5;
    }
  }
}
