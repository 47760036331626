











































































































































































































































































@import './../../../../../shared/styles/media-query.scss';
@import './../../../../../shared/styles/settings/settings.media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

$cardColor: #fff;
$iconAddWidth: 28px;
$iconAddHeight: 26px;
$iconRemoveHeight: 18px;
$iconRemoveWidth: 16px;
$imageSize: 40px;
$borderRadius: 3px;
$promotionHeightSmall: 124px;
$promotionHeightMedium: 184px;
$priceSmall: 18px;
$priceLarge: 22px;
$priceLabel: 14px;

.c-vas-card {
  position: relative;
  flex-basis: 100%;
  display: flex;
  flex-direction: column-reverse;

  $card: '';

  &--bottom-gap {
    margin-bottom: $u-300;
  }

  input[type='radio'] {
    align-self: center;
    width: 20px;
    height: 20px;
  }

  @include mq($screen-md, min) {
    flex-basis: calc(50% - #{$u-300});
  }

  &__price-disclaimer {
    text-align: center;
    color: $c-nt-400;
    font-size: $global-font-size-md;
    margin-top: $u-150;
    text-align: right;
    order: -1;
  }

  &__promotion {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $promotionHeightSmall;
    padding: $u-300;
    background-size: cover;
    background-position: center;
    order: 1;

    @include mq($screen-md, min) {
      height: $promotionHeightMedium;
    }
  }

  &__badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &.c-badge {
      min-height: 24px;
      line-height: 24px;
    }
  }

  &__card {
    background-color: $cardColor;
    border: 2px solid transparent;
    border-radius: $borderRadius;
    box-shadow: $box-shadow-default;
    cursor: pointer;
    display: flex;
    padding: $u-300;
    transition: border-color 0.3s ease, box-shadow 0.3s ease,
      opacity 0.5s linear;
    will-change: border-color, box-shadow, opacity;

    $card: &;

    @include mq($screen-md, min) {
      &:not(.c-vas-card--disabled):hover {
        box-shadow: $box-shadow-hover;
      }
    }

    &--block-item {
      padding: $u-500 $u-300;
      flex-grow: 1;
    }
  }

  &__image {
    flex-shrink: 0;
    flex-basis: $imageSize;
    margin-right: $u-300;
  }

  &__info {
    flex-basis: calc(100% - #{$imageSize});
  }

  &__hgroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: $imageSize;
  }

  &__title,
  &__status {
    display: block;
    font-size: $global-font-size-md;
  }

  &__title {
    font-weight: 700;
  }

  &__price {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    & > span + span {
      margin-left: $u-150;
    }

    &--gray {
      margin-right: $u-150;
      color: $c-nt-400;
    }

    &--original,
    &--new {
      font-weight: 400;
      font-family: $global-font-family-2;
    }

    &--original {
      font-size: $priceSmall;
    }

    &--new {
      font-size: $priceLarge;
    }

    &-label {
      font-size: $priceLabel;
    }
  }

  &__description {
    padding: $u-200 0;
    margin-bottom: $u-200;
  }

  &__action {
    position: relative;
    flex-shrink: 0;
    width: $iconAddWidth;
    margin-left: $u-300;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    &--add {
      fill: $c-pm-500;
      height: $iconAddHeight;
      width: $iconAddWidth;
    }

    &--remove {
      fill: $c-nt-500;
      height: $iconRemoveHeight;
      width: $iconRemoveWidth;
    }
  }

  &__expand {
    color: $c-pm-500;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    // c-vas-card__expand--is-hidden
    &--is-hidden {
      visibility: hidden;
    }
  }

  &__description,
  &__expand,
  &__summary,
  &__badge {
    font-size: $global-font-size-md;
    line-height: 20px;
  }

  &__summary {
    margin-bottom: $u-150;
  }

  &__banner {
    align-items: center;
    background: $color-white;
    border-radius: 0 0 3px 3px;
    display: flex;
    font-size: $font-size-h6;
    margin: 0 $u-300;
    padding: 6px;

    > svg {
      fill: $c-pm-500;
      height: $u-350;
      margin-right: $u-200;
      width: $u-350;
    }
  }

  &--promotion {
    flex-basis: 100%;

    @include mq($screen-md, max) {
      margin-left: $u-300;
      margin-right: $u-300;
    }
  }

  &--added {
    #{$card} {
      border-color: $c-pm-500;
      box-shadow: $box-shadow-default;

      @include mq($screen-md, min) {
        &:hover {
          box-shadow: $box-shadow-default;
        }
      }
    }
  }

  .cm-c-spinner {
    background-color: transparent;

    &::before {
      height: $u-350;
      left: calc(50% - (#{$u-350} / 2));
      top: calc(50% - (#{$u-350} / 2));
      width: $u-350;
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: default;

    & > * {
      opacity: 0.5;
    }
  }
}
