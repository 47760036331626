



















































































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.checkout-select-payment {
  border: 1px solid $c-nt-300;
  padding: 1px;
  border-radius: $u-200;
  background: $color-white;
  margin-bottom: $u-250;

  // .checkout-select-payment--active
  &--active {
    border: 2px solid $color-border-focus;
    padding: 0;
  }

  // .checkout-select-payment--disabled
  &--disabled {
    // .checkout-select-payment--disabled .checkout-select-payment__info
    .checkout-select-payment__info {
      cursor: default;
      // .checkout-select-payment--disabled .checkout-select-payment__info__left__title
      // .checkout-select-payment--disabled .checkout-select-payment__info__right__title
      &__left__title,
      &__right__title,
      &__left__sub-title {
        color: $c-nt-500;
      }
    }

    .checkout-select-payment__content__description {
      color: $c-nt-500;
    }
  }

  // .checkout-select-payment__info
  &__info {
    width: 100%;
    padding: $u-300;
    display: flex;
    justify-content: space-between;
    font-size: $font-size-h4;

    // .checkout-select-payment__info__left
    &__left {
      display: flex;

      // .checkout-select-payment__info__left__title-container
      &__title-container {
        display: flex;
        flex-direction: column;
      }

      // .checkout-select-payment__info__left__title
      &__title {
        color: $c-nt-900;
        font-weight: 700;
        text-align: left;
      }

      // .checkout-select-payment__info__left__sub-title
      &__sub-title {
        color: $c-nt-600;
        font-weight: 400;
        text-align: left;
      }
    }

    // .checkout-select-payment__info__right__title
    &__right__title {
      display: flex;
      align-items: center;
      color: $c-nt-900;
      font-weight: 400;
      text-align: right;
    }
  }

  // .checkout-select-payment__image
  &__image {
    min-width: $u-600;
    min-height: $u-600;
    overflow: hidden;
    border-radius: $u-200;
    margin-right: $u-250;
    display: flex;
    align-items: center;
    justify-content: center;

    // .checkout-select-payment__image__icon
    &__icon {
      fill: $color-white;
      width: $u-600;
      height: $u-600;
    }
  }

  // .checkout-select-payment__content
  &__content {
    margin: 0 $u-250 $u-250;

    // .checkout-select-payment__content__description
    &__description {
      margin-top: $u-400;
      color: $c-nt-600;

      &:first-child {
        margin-top: 0;
      }

      @include mq($screen-xs, max) {
        margin-top: $u-550;
      }
    }

    // .checkout-select-payment__content__info-box
    &__info-box {
      margin: $u-400;
      color: $c-nt-1000;

      // .checkout-select-payment__content__info-box-button
      &-button {
        color: $c-pm-700;
        text-decoration: underline;
        cursor: pointer;
        align-self: flex-start;
      }
    }

    @include mq($screen-sm, min) {
      margin: 0 $u-850 $u-550 $u-850;
    }
  }

  .c-accordion-item {
    position: relative;
  }
}

.select-payment {
  // .select-payment__radio
  &__radio {
    font-weight: bold;

    // .select-payment__radio > label
    & > label {
      display: flex;
      justify-content: space-between;
    }
  }

  // .select-payment__description
  &__description {
    padding: $u-200 $u-200 $u-200 1.5rem;
    font-size: x-small;
    border: 1px solid rgb(230, 0, 50);
    border-radius: $u-150;
    margin-bottom: $u-150;
  }
}
