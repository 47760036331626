@mixin c-factbox($factbox-theme) {
  $root: &;

  @include margin(bottom, 5);

  @include padding(top, map-deep-get($factbox-theme, padding, default, top));
  @include padding(
    bottom,
    map-deep-get($factbox-theme, padding, default, bottom)
  );
  @include padding(left, map-deep-get($factbox-theme, padding, default, left));
  @include padding(
    right,
    map-deep-get($factbox-theme, padding, default, right)
  );

  border-radius: map-get($factbox-theme, border-radius);
  width: 100%;
  clear: both;

  @include mq($screen-md, min) {
    @include padding(top, map-deep-get($factbox-theme, padding, desktop, top));
    @include padding(
      bottom,
      map-deep-get($factbox-theme, padding, desktop, bottom)
    );
    @include padding(
      left,
      map-deep-get($factbox-theme, padding, desktop, left)
    );
    @include padding(
      right,
      map-deep-get($factbox-theme, padding, desktop, right)
    );
  }

  &__heading {
    @include margin(
      bottom,
      map-deep-get($factbox-theme, heading, spacing, default)
    );
    @include typography(
      map-deep-get($factbox-theme, heading, typography, default),
      null,
      map-deep-get($factbox-theme, heading, font-family)
    );

    text-transform: uppercase;

    @include mq($screen-md, min) {
      @if (
        map-deep-get($factbox-theme, heading, typography, desktop) == 'custom'
      ) {
        font-size: 18px;
        line-height: 24px;
      } @else {
        @include typography(
          map-deep-get($factbox-theme, heading, typography, desktop),
          null,
          map-deep-get($factbox-theme, heading, font-family)
        );
      }

      @include margin(
        bottom,
        map-deep-get($factbox-theme, heading, spacing, desktop)
      );
    }
  }

  &__body {
    @extend .c-article__body;

    @include typography(
      map-deep-get($factbox-theme, body, typography, default)
    );

    @include mq($screen-md, min) {
      @include typography(
        map-deep-get($factbox-theme, body, typography, desktop)
      );
    }

    ul,
    ol {
      overflow: visible !important;
    }

    p {
      @include margin(bottom, 2);
    }
  }

  &--left,
  &--right {
    @include mq($screen-md, min) {
      clear: none;
      width: 50%;
    }
    @if (map-deep-get($factbox-theme, heading, typography, float)) {
      @include mq($screen-md, min) {
        #{$root}__heading {
          @include typography(
            map-deep-get($factbox-theme, heading, typography, float),
            null,
            map-deep-get($factbox-theme, heading, font-family)
          );
        }
      }
    }
  }

  &--left {
    @include mq($screen-md, min) {
      float: left;
      margin-left: map-deep-get($c-article, negative-positioning, desktop);
      @include margin(right, 3);

      @include mq(map-get($c-article, screen), max) {
        margin-left: map-deep-get($c-article, negative-positioning, default);
      }
    }
  }

  &--right {
    @include mq($screen-md, min) {
      float: right;
      margin-right: map-deep-get($c-article, negative-positioning, desktop);
      @include margin(left, 4);

      @include mq(map-get($c-article, screen), max) {
        margin-right: map-deep-get($c-article, negative-positioning, default);
      }
    }
  }

  @each $theme, $colors in map-get($factbox-theme, themes) {
    &--#{$theme} {
      background: map-get($colors, background);
      color: map-get($colors, color);

      .c-list--style-checkmark li:before {
        content: url('data:image/svg+xml;charset=UTF-8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M21.832 12.555l-6 9a.998.998 0 01-1.539.152l-4-4a1 1 0 011.414-1.414l3.137 3.137 5.324-7.985a1 1 0 011.664 1.11z" fill="#{map-get($colors, color)}" fill-rule="evenodd"/></svg>');
      }
    }
  }
}

.c-factbox {
  @include c-factbox($c-factbox);
}
