
































































































































































































































































































































































@import 'olympus/styles/media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

$infoWidthMax: 396px;
$mapHeightSmall: 230px;
$iconSize: $u-400;

.c-map {
  position: relative;
  align-items: center;

  &__map {
    width: 100%;

    &--small {
      height: $mapHeightSmall;
    }

    &--large {
      display: none;
      height: 100%;
    }
  }

  @include from-tablet {
    display: flex;
    height: calc(var(--map-height) + #{$u-600 * 2});

    &__map--small {
      display: none;
    }

    &__map--large {
      display: block;
    }
  }

  &__title {
    margin-bottom: $u-500;

    @include from-tablet {
      margin-bottom: $u-600;
    }
  }

  &__store-info {
    position: relative;
    padding: $u-400 $u-300;
    width: 100%;
    background-color: $color-white;
    text-align: left;
    box-shadow: $box-shadow-default;
    border-radius: 4px;

    @include from-tablet {
      position: absolute;
      padding: $u-600;
      left: $u-700;
      max-width: $infoWidthMax;
    }

    > section:not(:last-child) {
      margin-bottom: $u-600;

      > section:not(:last-child) {
        margin-bottom: $u-400;
      }
    }
  }

  .c-typography-h4 {
    font-size: $font-size-h4;
  }

  .c-typography-h6 {
    font-size: $font-size-h5;
  }

  &__address {
    font-style: normal;
    font-size: $global-font-size-md;
    line-height: $u-350;
  }

  &__contact-info {
    font-style: normal;
    font-size: $global-font-size-md;
    line-height: $u-350;
  }

  &__contact-info-item {
    display: flex;
  }

  &__contact-icon {
    width: $iconSize;
    height: $iconSize;
  }

  .gm-style-cc {
    display: none;
  }
}
