





























































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.c-form-radio {
  position: relative;
  display: block;
  padding-left: $u-400;
  min-height: $u-400;
  margin-bottom: $u-250;

  &__info-tooltip {
    font-size: $global-font-size-md;
    display: flex;
    margin-top: $u-150;
    align-items: center;
    color: $c-pm-500;

    &-icon {
      height: $u-350;
      width: $u-350;
      fill: $c-pm-500;
      padding-right: $u-150 + $u-100;
    }
  }

  &__description-text {
    margin-top: $u-200;
  }

  &__description-text,
  &__additional-text {
    font-size: $global-font-size-md;
    line-height: $u-350;
    color: $c-nt-700;
  }

  // .c-form-radio > label
  & > label {
    position: relative;
    margin-bottom: 0;
    line-height: $global-line-height-sm;
    display: block;
    padding-left: $u-250;
    padding-top: $u-100;

    // .c-form-radio > label:before,
    // .c-form-radio > label:after
    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: -$u-400;
      display: block;
      width: $u-400;
      height: $u-400;
      content: '';
    }

    // .c-form-radio > label:before
    &:before {
      transition: all 0.15s ease-in-out;
      pointer-events: none;
      background-color: $color-white;

      border-radius: 50%;
      border: $u-100 solid $c-nt-500;
    }

    &:hover:before {
      border: $u-125 solid $c-nt-900;
    }

    // .c-form-radio > label:after
    &:after {
      background: no-repeat 50%/50% 50%;
    }
  }

  // .c-form-radio > input[type='radio']
  & > input[type='radio'] {
    position: absolute;
    z-index: -1;
    opacity: 0;

    // .c-form-radio > input[type='radio']:checked
    &:checked {
      & + label:after {
        background-color: $color-white;
        border-radius: 50%;
        content: '';
        display: block;

        height: $u-300;
        width: $u-300;
        left: -$u-250;
        position: absolute;
        top: $u-250;

        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    &:disabled {
      & + label {
        pointer-events: none;
        cursor: default;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
