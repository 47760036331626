
































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$c-card-padding-horizontal: $u-900 + $u-400; // 102px

.c-card {
  position: relative;

  &__body {
    background: $color-white;
    box-shadow: $box-shadow-default;
    border-radius: 3px;
    border: 2px solid transparent;
    padding: $u-400;
    transition: box-shadow, border-color 0.2s ease;
    will-change: box-shadow;
    z-index: 1;

    @include mq($screen-md, max) {
      padding: $u-300;
    }

    &--sections {
      padding: 0px;
      border: none;

      & .c-card-section {
        border: 2px solid transparent;

        &:first-of-type {
          border-radius: 3px 3px 0px 0px;
          border-bottom: none;
        }

        &:last-of-type {
          border-radius: 0px 0px 3px 3px;
          border-top: none;
        }
      }
    }
  }

  &__tongue {
    background: $color-white;
    box-shadow: $box-shadow-default;
    border-radius: 0 0 3px 3px;
    margin: 0 $u-200;
    padding: $u-250 $u-300;
    transition: box-shadow 0.2s ease;
    will-change: box-shadow;
    z-index: 0;
  }

  &--interactive {
    cursor: pointer;

    &:hover {
      > .c-card__body,
      > .c-card__tongue {
        position: relative;
        box-shadow: $box-shadow-hover;
      }
    }

    &.c-card--selected {
      & .c-card__body,
      & .c-card-section {
        border-color: $c-pm-500;
      }
    }
  }

  &--extra-padding {
    > .c-card__body {
      padding: $u-600 $c-card-padding-horizontal;

      @include mq($screen-md, max) {
        padding: $u-600 $u-300 $u-500;
      }
    }
  }

  &--no-padding {
    > .c-card__body {
      padding: unset;
    }
  }

  &--no-shadow {
    > .c-card__body {
      box-shadow: unset;
    }
  }

  &--no-rounding {
    > .c-card__body {
      border-radius: unset;
    }
  }

  &--no-border {
    > .c-card__body {
      border: unset;
    }
  }
}
