
































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

$ease-in-out: cubic-bezier(0.645, 0, 0.355, 1);
$spinner-width: 80px;

.spinner-chase {
  height: $spinner-width;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  width: $spinner-width;

  animation: rotate-all 1s linear infinite;

  &__section {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 50%;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &__bar {
      border: $u-125 solid $c-pm-500;
      border-radius: 200px 0 0 200px;
      height: 100%;
      position: relative;
      width: 100%;

      &:after {
        background: $c-pm-500;
        border-radius: $u-125;
        content: '';
        display: block;
        height: $u-125;
        position: absolute;
        width: $u-125;
      }

      &--right {
        border-left: none;
        border-radius: 0 200px 200px 0;
        transform: rotate(-10deg);
        transform-origin: left center;

        &:after {
          bottom: -$u-125;
          left: -$u-125;
        }

        animation: rotate-right 0.75s linear infinite alternate;
      }

      &--left {
        border-right: none;
        transform: rotate(10deg);
        transform-origin: right center;

        animation: rotate-left 0.75s linear infinite alternate;

        &:after {
          bottom: -$u-125;
          right: -$u-125;
        }
      }
    }
  }
}

@keyframes rotate-left {
  from {
    transform: rotate(175deg);
  }

  to {
    transform: rotate(30deg);
  }
}

@keyframes rotate-right {
  from {
    transform: rotate(-175deg);
  }
  to {
    transform: rotate(-30deg);
  }
}

@keyframes rotate-all {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}
