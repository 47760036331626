













































































































































































@import 'theme/sass/settings/_settings.colors.scss';

.error-message {
  color: $c-sp-red-500;
}
