

























@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.c-layout-section {
  position: relative;
  margin-top: $u-600;
  padding: $u-500 $u-700;
  overflow: unset;

  @include mq($screen-sm, min) {
    padding-left: $u-350;
    padding-right: $u-350;
  }

  // .c-layout-section--white
  &--white {
    background-color: $color-white;
  }
}
