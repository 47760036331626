﻿@import 'olympus/styles/sr.scss';

@for $i from 1 through 10 {
  .c-margin {
    &--bottom-#{$i} {
      @include margin(bottom, $i);
    }

    &--bottom-desktop-#{$i} {
      @include mq($screen-md, min) {
        @include margin(bottom, $i, true);
      }
    }

    &--top-#{$i} {
      @include margin(top, $i);
    }
    &--top-desktop-#{$i} {
      @include mq($screen-md, min) {
        @include margin(top, $i);
      }
    }

    &--top-negative-#{$i} {
      @include margin(top, -$i);
    }
    &--top-negative-desktop-#{$i} {
      @include mq($screen-md, min) {
        @include margin(top, -$i);
      }
    }
  }
}

.c-clear-float {
  height: 0;
  border: 0;
  clear: both;
}
