

























































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
.c-card-section {
  padding: $u-400;
  background: $color-white;

  &__badge {
    position: absolute;
    left: 0;
    top: 0;
    white-space: normal;
  }

  &--primary {
    background: $c-pm-900;
    color: $color-white;
  }
}
