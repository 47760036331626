/**
 * oneblock-distance.css
 * @version 1.1.20
 * @summary 27-02-2020
 * @author Mads Stoumann
 * @description OneBlock: Distance
 */

/* Distance
===============================
Prefix:
-C Image Caption
-E Content Box
-I Image
-S Splash

Key:
-dl, -dt

Value:
0 (Default)-9
===============================
*/
.-C-dl0 .c-ob__bg-caption,
.-E-dl0 .c-ob__content,
.-I-dl0 .c-ob__img,
.-S-dl0 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 0);
}
.-C-dl1 .c-ob__bg-caption,
.-E-dl1 .c-ob__content,
.-I-dl1 .c-ob__img,
.-S-dl1 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 1);
}
.-C-dl2 .c-ob__bg-caption,
.-E-dl2 .c-ob__content,
.-I-dl2 .c-ob__img,
.-S-dl2 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 2);
}
.-C-dl3 .c-ob__bg-caption,
.-E-dl3 .c-ob__content,
.-I-dl3 .c-ob__img,
.-S-dl3 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 3);
}
.-C-dl4 .c-ob__bg-caption,
.-E-dl4 .c-ob__content,
.-I-dl4 .c-ob__img,
.-S-dl4 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 4);
}
.-C-dl5 .c-ob__bg-caption,
.-E-dl5 .c-ob__content,
.-I-dl5 .c-ob__img,
.-S-dl5 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 5);
}
.-C-dl6 .c-ob__bg-caption,
.-E-dl6 .c-ob__content,
.-I-dl6 .c-ob__img,
.-S-dl6 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 6);
}
.-C-dl7 .c-ob__bg-caption,
.-E-dl7 .c-ob__content,
.-I-dl7 .c-ob__img,
.-S-dl7 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 7);
}
.-C-dl8 .c-ob__bg-caption,
.-E-dl8 .c-ob__content,
.-I-dl8 .c-ob__img,
.-S-dl8 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 8);
}
.-C-dl9 .c-ob__bg-caption,
.-E-dl9 .c-ob__content,
.-I-dl9 .c-ob__img,
.-S-dl9 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 9);
}
/* 10-15 already used for negative distances, cannot change that without ruining production, hence 50-55.
This method will be replaced with custom props, as soon as IE is ditched. */
.-C-dl50 .c-ob__bg-caption,
.-E-dl50 .c-ob__content,
.-I-dl50 .c-ob__img,
.-S-dl50 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 10);
}
.-C-dl51 .c-ob__bg-caption,
.-E-dl51 .c-ob__content,
.-I-dl51 .c-ob__img,
.-S-dl51 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 11);
}
.-C-dl52 .c-ob__bg-caption,
.-E-dl52 .c-ob__content,
.-I-dl52 .c-ob__img,
.-S-dl52 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 12);
}
.-C-dl53 .c-ob__bg-caption,
.-E-dl53 .c-ob__content,
.-I-dl53 .c-ob__img,
.-S-dl53 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 13);
}
.-C-dl54 .c-ob__bg-caption,
.-E-dl54 .c-ob__content,
.-I-dl54 .c-ob__img,
.-S-dl54 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 14);
}
.-C-dl55 .c-ob__bg-caption,
.-E-dl55 .c-ob__content,
.-I-dl55 .c-ob__img,
.-S-dl55 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 15);
}

/* Negative Distance: Left/Right */
.-C-dl10 .c-ob__bg-caption,
.-E-dl10 .c-ob__content,
.-I-dl10 .c-ob__img,
.-S-dl10 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -1);
}
.-C-dl11 .c-ob__bg-caption,
.-E-dl11 .c-ob__content,
.-I-dl11 .c-ob__img,
.-S-dl11 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -2);
}
.-C-dl12 .c-ob__bg-caption,
.-E-dl12 .c-ob__content,
.-I-dl12 .c-ob__img,
.-S-dl12 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -3);
}
.-C-dl13 .c-ob__bg-caption,
.-E-dl13 .c-ob__content,
.-I-dl13 .c-ob__img,
.-S-dl13 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -4);
}
.-C-dl14 .c-ob__bg-caption,
.-E-dl14 .c-ob__content,
.-I-dl14 .c-ob__img,
.-S-dl14 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -5);
}
.-C-dl15 .c-ob__bg-caption,
.-E-dl15 .c-ob__content,
.-I-dl15 .c-ob__img,
.-S-dl15 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -6);
}
.-C-dl16 .c-ob__bg-caption,
.-E-dl16 .c-ob__content,
.-I-dl16 .c-ob__img,
.-S-dl16 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -7);
}
.-C-dl17 .c-ob__bg-caption,
.-E-dl17 .c-ob__content,
.-I-dl17 .c-ob__img,
.-S-dl17 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -8);
}
.-C-dl18 .c-ob__bg-caption,
.-E-dl18 .c-ob__content,
.-I-dl18 .c-ob__img,
.-S-dl18 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -9);
}
.-C-dl19 .c-ob__bg-caption,
.-E-dl19 .c-ob__content,
.-I-dl19 .c-ob__img,
.-S-dl19 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -10);
}
.-C-dl20 .c-ob__bg-caption,
.-E-dl20 .c-ob__content,
.-I-dl20 .c-ob__img,
.-S-dl20 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -11);
}
.-C-dl21 .c-ob__bg-caption,
.-E-dl21 .c-ob__content,
.-I-dl21 .c-ob__img,
.-S-dl21 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -12);
}
.-C-dl22 .c-ob__bg-caption,
.-E-dl22 .c-ob__content,
.-I-dl22 .c-ob__img,
.-S-dl22 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -13);
}
.-C-dl23 .c-ob__bg-caption,
.-E-dl23 .c-ob__content,
.-I-dl23 .c-ob__img,
.-S-dl23 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -14);
}
.-C-dl24 .c-ob__bg-caption,
.-E-dl24 .c-ob__content,
.-I-dl24 .c-ob__img,
.-S-dl24 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * -15);
}

/* Top/Bottom */
.-C-dt0 .c-ob__bg-caption,
.-E-dt0 .c-ob__content,
.-I-dt0 .c-ob__img,
.-S-dt0 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 0);
}
.-C-dt1 .c-ob__bg-caption,
.-E-dt1 .c-ob__content,
.-I-dt1 .c-ob__img,
.-S-dt1 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 1);
}
.-C-dt2 .c-ob__bg-caption,
.-E-dt2 .c-ob__content,
.-I-dt2 .c-ob__img,
.-S-dt2 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 2);
}
.-C-dt3 .c-ob__bg-caption,
.-E-dt3 .c-ob__content,
.-I-dt3 .c-ob__img,
.-S-dt3 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 3);
}
.-C-dt4 .c-ob__bg-caption,
.-E-dt4 .c-ob__content,
.-I-dt4 .c-ob__img,
.-S-dt4 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 4);
}
.-C-dt5 .c-ob__bg-caption,
.-E-dt5 .c-ob__content,
.-I-dt5 .c-ob__img,
.-S-dt5 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 5);
}
.-C-dt6 .c-ob__bg-caption,
.-E-dt65 .c-ob__content,
.-I-dt6 .c-ob__img,
.-S-dt6 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 6);
}
.-C-dt7 .c-ob__bg-caption,
.-E-dt7 .c-ob__content,
.-I-dt7 .c-ob__img,
.-S-dt7 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 7);
}
.-C-dt8 .c-ob__bg-caption,
.-E-dt8 .c-ob__content,
.-I-dt8 .c-ob__img,
.-S-dt8 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 8);
}
.-C-dt9 .c-ob__bg-caption,
.-E-dt9 .c-ob__content,
.-I-dt9 .c-ob__img,
.-S-dt9 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 9);
}

/* Negative Top/Bottom */
.-C-dt10 .c-ob__bg-caption,
.-E-dt10 .c-ob__content,
.-I-dt10 .c-ob__img,
.-S-dt10 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -1);
}
.-C-dt11 .c-ob__bg-caption,
.-E-dt11 .c-ob__content,
.-I-dt11 .c-ob__img,
.-S-dt11 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -2);
}
.-C-dt12 .c-ob__bg-caption,
.-E-dt12 .c-ob__content,
.-I-dt12 .c-ob__img,
.-S-dt12 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -3);
}
.-C-dt13 .c-ob__bg-caption,
.-E-dt13 .c-ob__content,
.-I-dt13 .c-ob__img,
.-S-dt13 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -4);
}
.-C-dt14 .c-ob__bg-caption,
.-E-dt14 .c-ob__content,
.-I-dt14 .c-ob__img,
.-S-dt14 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -5);
}
.-C-dt15 .c-ob__bg-caption,
.-E-dt15 .c-ob__content,
.-I-dt15 .c-ob__img,
.-S-dt15 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -6);
}
.-C-dt16 .c-ob__bg-caption,
.-E-dt16 .c-ob__content,
.-I-dt16 .c-ob__img,
.-S-dt16 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -7);
}
.-C-dt17 .c-ob__bg-caption,
.-E-dt17 .c-ob__content,
.-I-dt17 .c-ob__img,
.-S-dt17 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -8);
}
.-C-dt18 .c-ob__bg-caption,
.-E-dt18 .c-ob__content,
.-I-dt18 .c-ob__img,
.-S-dt18 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -9);
}
.-C-dt19 .c-ob__bg-caption,
.-E-dt19 .c-ob__content,
.-I-dt19 .c-ob__img,
.-S-dt19 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * -10);
}

/* Micro-units, for Call me */
.-C-dl05 .c-ob__bg-caption,
.-E-dl05 .c-ob__content,
.-I-dl05 .c-ob__img,
.-S-dl05 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 0.5);
}

.-C-dt05 .c-ob__bg-caption,
.-E-dt05 .c-ob__content,
.-I-dt05 .c-ob__img,
.-S-dt05 .c-ob__splash {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 0.5);
}
.-C-lr05 .c-ob__bg-caption,
.-E-lr05 .c-ob__content,
.-I-lr05 .c-ob__img,
.-S-lr05 .c-ob__splash {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 0.5);
}

/* Hacks for Call me ribbon */
.-C-dl50 .c-ob__bg-caption {
  --ob-distance-lr: calc(var(--ob-distance-unit) * 0);
}
.-C-dt50 .c-ob__bg-caption {
  --ob-distance-tb: calc(var(--ob-distance-unit) * 0);
}
