




































































































// TODO DKT-1457: remove relative path and use alias instead
$icomoon-font-path: '../../styles/fonts/icons' !default;

// TODO DKT-1457: theme this (and dont use callme explicitly for namings)
@font-face {
  font-family: 'callme';
  src: url('#{$icomoon-font-path}/callme.ttf?g8olqu') format('truetype'),
    url('#{$icomoon-font-path}/callme.woff?g8olqu') format('woff'),
    url('#{$icomoon-font-path}/callme.svg?g8olqu#callme') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icons: (
  calendar: '\e903',
  angle-right: '\e902',
  octagon: '\e901',
  gallery: '\e900',
  lyd: '\e93d',
  update: '\e91d',
  adgangskode: '\e91e',
  arrow-left: '\e91f',
  arrow-right: '\e920',
  bluetooth2: '\e921',
  cart2: '\e922',
  computer2: '\e923',
  dokument: '\e924',
  drift2: '\e925',
  facebook2: '\e926',
  favorit2: '\e927',
  forbrug: '\e928',
  forsikring: '\e929',
  forsikring_2: '\e92a',
  gps: '\e92b',
  help2: '\e92c',
  hjerte: '\e92d',
  indstilliner_2: '\e92e',
  indstillinger: '\e92f',
  info: 'i',
  kamera: '\e930',
  kontakt: '\e931',
  network2: '\e933',
  network_2: '\e934',
  profil: '\e935',
  sim-kort: '\e936',
  smartphone: '\e937',
  smartphone_2: '\e938',
  sms2: '\e939',
  søg: '\e93a',
  udland2: '\e93b',
  wi-fi: '\e93c'
);

.c-icon {
  font-family: 'callme' !important;
  speak: none;
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // .c-icon--rounded
  &--rounded {
    border: 1px solid;
    border-radius: 50%;
  }

  // .c-icon--{name}
  @each $name, $value in $icons {
    &--#{$name}:before {
      content: $value;
    }
  }
}
