/**
 * oneblock-layout.css
 * @version 1.1.10
 * @summary 24-02-2010
 * @author Mads Stoumann
 * @description OneBlock: Layouts
 */

/* Layout
===============================
Prefix:
-O OneBlock

Key:
-ly

Value:
0 Default, Media Top, Content Below
1 Media Bottom, Content Above
2 Media Left, Content Right
3 Media Right, Content Left
4 Content on top of Media
5 Image Only
6 Text Only
7 Card Flip
8 Grow Down --> Up
9 Small Image Left 1/3 - 2/3 Text Right
===============================
*/
 /* Add padding to content-box when default layout */
/* .c-ob:not([class*=-O-ly]) .c-ob__content {
	padding-top: calc(var(--ob-padding-unit) * 2);
} */
.c-ob.-O-ly1 .c-ob__bg-wrapper,
.c-ob.-O-ly3 .c-ob__bg-wrapper {
	order: 2;
}
.c-ob.-O-ly1 .c-ob__content {
	padding-bottom: var(--ob-margin-unit);
}
.c-ob.-O-ly2, 
.c-ob.-O-ly3,
.c-ob.-O-ly9 {
	flex-flow: row nowrap;
}
.c-ob.-O-ly3 .c-ob__content {
	padding-right: calc(var(--ob-margin-unit) * 2);
}
.c-ob.-O-ly2 .c-ob__content,
.c-ob.-O-ly3 .c-ob__content,
.c-ob.-O-ly2 .c-ob__bg-wrapper,
.c-ob.-O-ly3 .c-ob__bg-wrapper {
	flex: 1 0 50%;
	max-width: 50%;
}
.c-ob.-O-ly4 .c-ob__content {
	position: absolute;
}

.c-ob.-O-ly5 .c-ob__content,
.c-ob.-O-ly6 .c-ob__bg-wrapper {
	display: none;
}
.c-ob.-O-ly7 {
	perspective: 1000px;
	position: relative;
	transform-style: preserve-3d;
	transition: transform 0.6s;
}
.c-ob.-O-ly7::after {
	content: '';
	display: block;
	padding-bottom: 56.25%;
}
.c-ob.-O-ly7:hover .c-ob__content {
	transform: rotateY(0);
}
.c-ob.-O-ly7:hover .c-ob__bg-wrapper {
	transform: rotateY(180deg);
}
.c-ob.-O-ly7 .c-ob__content,
.c-ob.-O-ly7 .c-ob__bg-wrapper  {
	backface-visibility: hidden;
	height: 100%;
	position: absolute;
	transform-style: preserve-3d;
	transition: ease-in-out 600ms;
	width: 100%;
}
.c-ob.-O-ly7 .c-ob__content {
	transform: rotateY(-180deg);
}
.c-ob.-O-ly8 {
	overflow: hidden;
	position: relative;
}
.c-ob.-O-ly8 .c-ob__content {
	bottom: 0;
	position: absolute;
	top: 50%;
	transition: top .1s ease-out;
}
.c-ob.-O-ly8 .c-ob__content:hover {
	height: 100%;
	top: 0;
	transition: top .3s, overflow .3s .1s ease-in;
}
.c-ob.-O-ly8 .c-ob__content:hover .c-ob__text {
	-webkit-line-clamp: initial;
	overflow: initial;
}
.c-ob.-O-ly8 .c-ob__content {
	-webkit-box-orient: vertical;
	display: -webkit-box;
}
.c-ob.-O-ly9 .c-ob__bg-wrapper {
	align-self: flex-start;
	flex: 1 0 35%;
	max-width: 35%;
}
.c-ob.-O-ly9 .c-ob__content {
	align-self: flex-start;
	flex: 1 0 65%;
	max-width: 65%;
}

/* Content Box Layouts
===============================
Prefix:
-E Content Box

Key:
-ly

Value:
0 Default: One Column
1 Row: Tagline [Headline, Text]
===============================
*/
.-E-ly1 .c-ob__content {
	display: grid;
	gap: var(--ob-grid-gap);
	grid-row-gap: 0;
	grid-template-areas: 
		"tagline tagline"
		"headline offer"
		"additional_headline temp"
		"text link";
	grid-template-columns: auto auto;
}
.-E-ly1 .c-ob__link-wrapper {
	align-items: flex-end;
	flex-direction: column;
	justify-self: end;
}
.-E-ly1 .c-ob__offer {
	justify-self: end;
	text-align: right;
}
.-E-ly1 .c-ob__link-text-sec {
	margin-inline-start: 0px;
	margin-top: var(--ob-margin-unit);
}
.-E-ly1.-E-ta1 .c-ob__content,
.-E-ly1.-E-ta1 .c-ob__link-wrapper {
	justify-content: initial;
}
.-E-ly2 .c-ob__content,
.-E-ly2 .c-ob__bg-wrapper {
	display: contents;
}
.-E-ly2 .c-ob__bg-caption,
.-E-ly2 .c-ob__offer,
.-E-ly2 .c-ob__link-wrapper {
 display: none;
}
.c-ob.-E-ly2 {
	display: grid;
	grid-template-areas: 
		"bg tagline splash"
		"bg headline image"
		"bg text image";
	grid-template-columns: 50% 30% 20%;
}