// ==========================================================================
// #RESET
// ==========================================================================

// A very simple reset that sits on top of Normalize.css.

// sass-lint:disable single-line-per-selector
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
li,
figure,
hr,
fieldset,
legend {
	margin: 0;
	padding: 0;
}
// sass-lint:enable single-line-per-selector

// Remove list style
ul {
	list-style: none;
}

// Remove default table spacing.

table {
	border-spacing: 0;
	border-collapse: collapse;
}


//  1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
//     on fieldsets.

fieldset {
	min-width: 0; // 1.
	border: 0;
}

button {
	color: #000;
}
