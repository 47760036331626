












































































































































































.shopping-cart-item {
  transform: scale(0);
  transition: transform 0.3s ease;
  will-change: transform;

  &.show {
    transform: scale(1);
  }
}
