/// Search Panel
/// - Version 1.0.0
/// - Date last edited: 2020-04-30
/// @name c-search-panel
/// @author Bjarke Rasmussen, Valtech

@mixin c-search-panel($theme) {
  width: 100%;
  max-width: map-deep-get($theme, container, max-width);
  padding: 10px 0 20px;
  margin: 0 auto;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    background: white;
    z-index: -1;
  }

  .c-search-panel__input {
    margin-bottom: 0;

    @include mq($screen-md, min) {
      margin-bottom: 20px;
      margin-top: 30px;
    }
  }

  &__input {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 56px;

    @include mq($screen-md, min) {
      margin-top: 0;
    }
  }

  &__quick-links-headline {
    padding: 20px 0 0 12px;
    font-size: $u-200;
    font-weight: normal;
    color: map-deep-get($theme, quick-links, headline-color);

    @include mq($screen-md, min) {
      padding: 0 0 0 12px;
    }
  }

  &__quick-links {
    ul {
      display: block;
      padding: 0;
      margin: 0;

      li {
        margin: 10px 0;
        padding: 0 0 0 12px;
      }
    }
  }

  &__results {
    ul {
      display: block;
      padding: 0;
      margin: 0 0 35px;

      li {
        display: block;
        padding: 14px 0;
        margin: 0;

        list-style-type: none;

        border-bottom: 1px solid map-deep-get($theme, results, border-color);

        @include mq($screen-md, min) {
          padding: 20px 12px;
        }

        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__result-headline {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }

  &__result-excerpt {
    display: block;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 18px;
  }

  &__view-all {
    display: block;
    text-align: center;
    a {
      z-index: 0;
      @include c-btn('sm');
    }
  }
}

.c-search-panel {
  @include c-search-panel($c-search-panel);
}
