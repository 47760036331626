.c-dl {
  position: relative;

  &--tabled {
    display: flex;
    flex-wrap: wrap;

    & dt, 
    & dd {
      flex: 1 1 50%;
      width: 0;
    }

    & dd {
      text-align: right;
    }
  }
}