﻿@mixin c-menu($menu-theme) {
  $root: &;

  background-color: map-deep-get($menu-theme, container, background-color);
  bottom: map-deep-get($menu-theme, container, bottom);
  top: map-deep-get($menu-theme, container, top);
  box-sizing: border-box;
  display: flex;
  height: map-deep-get($menu-theme, container, height, default);
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: map-get($menu-theme, z-index);
  box-shadow: map-get($menu-theme, box-shadow);

  summary:before {
    content: none;
  }

  @include mq($screen-md, min) {
    &__panel-background {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0;
      z-index: 0;
      background-color: map-deep-get($menu-theme, panel, background-color);
      box-shadow: #{map-deep-get($menu-theme, panel, top-shadow)},
        #{map-deep-get($menu-theme, panel, box-shadow)};
    }

    &__group-item {
      opacity: 0;
    }
  }

  @at-root #app {
    padding-bottom: map-deep-get($menu-theme, container, height, default);
    @include mq($screen-md, min) {
      padding-bottom: 0;
    }
  }
  /*
      TODO DKT-1410: To be removed when actual icons are implemented
  */
  i {
    --icon-background-color: #{map-deep-get(
        $menu-theme,
        close-button,
        background-color
      )};
  }

  &__close {
    @include padding(all, map-deep-get($menu-theme, close-button, spacing));
    background-color: transparent;
    display: flex;
    height: map-deep-get($menu-theme, close-button, height, default);
    justify-content: flex-end;
    position: fixed;
    right: 0;
    left: 0;
    z-index: map-deep-get($menu-theme, close-button, z-index);
    max-width: map-deep-get($menu-theme, close-button, max-width);
    margin: 0 auto;
    width: 100%;

    svg {
      width: $u-400;
      height: $u-400;
    }
  }

  &__heading {
    @include typography(h4);
    @include padding(bottom, 3);
    @include padding(top, 8);
    @include margin(horizontal, map-get($menu-theme, gutter));
    border-bottom: map-deep-get($menu-theme, headline, border-bottom);
    color: map-deep-get($menu-theme, headline, color);
    text-align: center;

    @include mq($screen-md, min) {
      display: none;
    }
  }

  &__logo svg {
    width: map-deep-get($menu-theme, logo, width, default);
    fill: map-deep-get($menu-theme, logo, color);

    @include mq($screen-md, min) {
      width: map-deep-get($menu-theme, logo, width, desktop);
    }
  }

  &__logo {
    @include mq($screen-md, max) {
      @include padding(right, map-deep-get($menu-theme, divider, offset));
      position: relative;
      &:after {
        content: '';
        height: map-deep-get($menu-theme, divider, height);
        top: 50%;
        transform: translateY(-50%);
        width: map-deep-get($menu-theme, divider, width);
        background: map-deep-get($menu-theme, divider, background);
        right: 0;
        position: absolute;
        display: block;
      }
    }
  }

  &__group {
    flex: 0 0 100%;
  }

  &__inner {
    align-items: center;
    background-color: map-deep-get($menu-theme, inner, background-color);
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    height: map-deep-get($menu-theme, container, height, default);
    justify-content: space-between;
    @include padding(vertical, 0);
    @include padding(horizontal, map-get($menu-theme, gutter), true);

    @include mq($screen-md, min) {
      height: map-deep-get($menu-theme, container, height, desktop);
    }
  }

  &__panel-inner {
    @include mq($screen-md, max) {
      padding: 0;
      .c-wrapper {
        padding: 0;
      }
      .c-menu__side-button & {
        @include padding(
          top,
          map-deep-get($menu-theme, panel, spacing, top, default)
        );
        @include padding(
          bottom,
          map-deep-get($menu-theme, panel, spacing, bottom, default)
        );
        @include padding(
          horizontal,
          map-deep-get($menu-theme, panel, spacing, horizontal, default)
        );
      }
    }
  }

  &__panel-container {
    width: 100%;
    position: relative;
  }

  a.c-menu__item:not(.c-menu__item--icon),
  &__item-details {
    border-bottom: 1px solid map-deep-get($menu-theme, item, border-color);
    @include typography(map-deep-get($menu-theme, item, typography, default));
    @include margin(horizontal, map-get($menu-theme, gutter));
    @include margin(vertical, 0);

    @include mq($screen-md, min) {
      @include typography(map-deep-get($menu-theme, item, typography, desktop));
    }
  }

  &__item > .mobile-only {
    @include margin(right, map-deep-get($menu-theme, item, icon, spacing));
  }

  &__item,
  &__link {
    cursor: pointer;
    outline: none;
  }

  &__link {
    @include typography(body);
    font-size: map-deep-get($menu-theme, link, font-size);
    padding: map-deep-get($menu-theme, link, padding);
    text-decoration: none !important;
  }

  /* Common for Group Headers, Menu Items and Links (on mobile) */
  a.c-menu__item:not(.c-menu__item--icon),
  &__item-details > .c-menu__item,
  &__group-header,
  &__link {
    align-items: center;
    color: map-deep-get($menu-theme, item, color);
    display: flex;
    line-height: rem-calc(48);
    text-decoration: none;
    user-select: none;

    &:hover,
    &:focus,
    &.c-menu__curent-item {
      color: map-get($menu-theme, active-color);

      & > svg {
        fill: map-get($menu-theme, active-color);
      }
    }
  }

  a.c-menu__link {
    &.c-menu__group-item {
      display: block;
    }
  }

  &__group-header {
    font-size: map-deep-get($menu-theme, group, header, font-size);
    font-weight: map-deep-get($menu-theme, group, header, font-weight);
    padding: map-deep-get($menu-theme, group, header, padding);

    @include mq($screen-md, min) {
      cursor: default;

      &--empty {
        &:before {
          content: '\200b' !important;
          order: 2;
        }
      }
    }

    @include mq($screen-md, max) {
      &--empty {
        display: none;
      }
    }
  }

  &__item-details > &__item,
  &__group-header {
    justify-content: space-between;
    position: relative;
  }

  a.c-menu__item:not(.c-menu__item--icon) {
    justify-content: left;

    & > svg {
      width: 22px;
      height: 22px;
      fill: map-deep-get($menu-theme, icons, color);
    }

    @include mq($screen-md, min) {
      & > svg.mobile-only {
        display: none;
      }
    }
  }
  /* Icons AFTER collapsable items */
  &__item-details > .c-menu__item:after,
  &__group-header:after {
    background-color: map-deep-get(
      $menu-theme,
      close-button,
      background-color
    ) !important;
    content: '';
    margin-left: auto;
    mask-image: var(--icon-plus);
    height: 1rem;
    width: 1rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__item--icon {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    @include typography(body-sm);

    & > svg {
      width: 22px;
      height: 22px;
      fill: map-deep-get($menu-theme, icons, color);
    }
  }

  &__main {
    overflow-y: auto;
  }

  &__panel {
    @include mq($screen-md, max) {
      overflow-y: auto;
    }
  }

  &__main,
  &__panel {
    background-color: map-deep-get($menu-theme, panel, background-color);
    bottom: map-deep-get($menu-theme, container, height, default);
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;

    @include mq($screen-md, min) {
      bottom: map-deep-get($menu-theme, container, height, desktop);
    }
  }

  &__side-button {
    &--switch-mobile {
      > label {
        @include mq($screen-md, max) {
          display: none;
        }
      }
      > a {
        @include mq($screen-md, min) {
          display: none;
        }
      }
    }
  }

  &__overlay,
  &__overlay-main {
    background-color: map-deep-get($menu-theme, overlay, background-color);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  @if (map-deep-get($menu-theme, group, same-as-desktop) == true) {
    &__group-header {
      color: map-deep-get($menu-theme, group, header, color);
      font-weight: map-deep-get($menu-theme, group, header, font-weight);
      font-size: rem-calc(map-deep-get($menu-theme, group, header, font-size));
      pointer-events: none;
      text-transform: map-deep-get($menu-theme, group, header, text-transform);
      font-size: rem-calc(10);
      line-height: 2rem;

      &:after {
        content: none;
      }
    }

    &__link {
      @include padding(left, 3);
    }
  }

  @include mq($screen-md, min) {
    & {
      height: map-deep-get($menu-theme, container, height, desktop);
      position: relative;
    }

    &__close {
      display: none;
      position: static;
    }

    &__group {
      flex: 1;
    }

    &__inner,
    &__group-header {
      padding: 0;
    }

    &__group-header {
      color: map-deep-get($menu-theme, group, header, color);
    }
    &__group-header,
    &__link {
      line-height: rem-calc(40);
    }

    &__item {
      display: inline-flex;
    }

    &__item-details,
    &__item--icon,
    a.c-menu__item:not(.c-menu__item--icon) {
      border: 0;
      @include margin(all, 0);
      @include margin(left, 2.25);
    }

    &__item-details &__item {
      display: inline-flex;
    }

    &__item-details > &__item:after,
    &__group-header:after {
      display: none;
    }

    &__item-details:first-of-type {
      padding-top: initial;
    }

    &__item-details[open] &__item {
      color: map-get($menu-theme, active-color);
    }

    &__item:not(.c-menu__item--icon) .c-icon {
      display: none;
    }

    &__group-header {
      color: map-deep-get($menu-theme, group, header, color);
      font-weight: map-deep-get($menu-theme, group, header, font-weight);
      font-size: rem-calc(map-deep-get($menu-theme, group, header, font-size));
      pointer-events: none;
      text-transform: map-deep-get($menu-theme, group, header, text-transform);
    }

    &__link {
      @include padding(all, 0);
    }

    &__main {
      background-color: transparent;
      flex: 1;
      position: static;
    }

    &__side-button {
      position: inherit;

      &--box {
        position: relative;

        & > .c-menu__panel {
          width: map-deep-get($menu-theme, panel, width);
          top: map-deep-get($menu-theme, panel, offset);
          left: auto;
          bottom: auto;

          width: 100%;
          box-shadow: $box-shadow-hover;
          border-radius: 3px;
          @include mq($screen-md, min) {
            width: 416px;
          }
        }
      }
    }

    &__panel,
    &__panel--desktop {
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 1;
    }

    &__panel-inner {
      @include padding(
        vertical,
        map-deep-get($menu-theme, panel, spacing, default)
      );
      .c-menu__side-button & {
        @include padding(
          top,
          map-deep-get($menu-theme, panel, spacing, top, desktop)
        );
        @include padding(
          bottom,
          map-deep-get($menu-theme, panel, spacing, bottom, desktop)
        );
        @include padding(
          horizontal,
          map-deep-get($menu-theme, panel, spacing, horizontal, desktop)
        );
      }
      display: flex;
      min-height: inherit;
    }
    /* State: Desktop */
    #menu-overlay:not(:checked) + .c-menu__overlay {
      display: block;
      z-index: -1;
    }

    .state--menu-main:checked + .c-segment {
      position: static;
    }

    [for='menu-main'] {
      display: none;
    }

    .c-menu__main,
    .state--menu-main:checked ~ .c-menu > .c-menu__inner > .c-menu__main {
      display: flex;
    }
  }

  &__item-details,
  &__item--icon,
  a.c-menu__item:not(.c-menu__item--icon) {
    @include mq($screen-lg, min) {
      @include margin(left, 4);
    }
  }

  /* State: Mobile */
  @at-root .state--menu:checked + label,
    .state--menu-main:checked ~ .c-menu > .c-menu__inner > [for='menu-main'] {
    color: map-get($menu-theme, active-color);
    i {
      --icon-background-color: #{map-get($menu-theme, active-color)};
    }

    svg {
      fill: map-get($menu-theme, active-color);
    }
  }

  @at-root .state--menu:checked + label + .c-menu__panel,
    .state--menu-main:checked ~ .c-menu > .c-menu__inner > .c-menu__main {
    display: block;
  }

  @at-root .state--menu:checked + label + .c-menu__panel {
    @if (map-deep-get($menu-theme, panel, hide-close) == false) {
      .c-menu__close {
        display: flex;
        position: absolute;

        svg {
          right: $u-300;
          top: $u-300;
        }
      }
    }
  }

  @include mq($screen-lg, max) {
    @at-root #menu-overlay:not(:checked) ~ #app > main,
      #menu-overlay:not(:checked) ~ #app > footer {
      display: none;
    }
  }

  @at-root .state--menu-main:checked + .c-segment {
    display: block;
    position: fixed;
    top: 55px;
    width: 100%;
    z-index: 1001;
  }

  @at-root .state--menu-main:checked + .c-segment .c-segment__inner {
    padding-left: 0;
    padding-right: 0;
  }

  @at-root .c-menu__item-details[open] .c-menu__item::after,
    .c-menu__group[open] .c-menu__group-header::after {
    background-color: map-get($menu-theme, active-color);
    mask-image: var(--icon-minus);
    mask-image: var(--icon-minus);
  }

  summary::-webkit-details-marker {
    display: none;
  }

  @include mq($screen-md, max) {
    * {
      outline: none;
    }
    &__group[open] .c-menu__group-header {
      color: #222;
      font-weight: bold;
    }
    &__inner {
      border-top: 1px solid map-deep-get($menu-theme, inner, border-color);
    }
    /* Target 1st level only */
    &__item-details--1 > summary.c-menu__item,
    &__main > .c-menu__item {
      padding: map-deep-get($menu-theme, item, padding);
    }
  }
}

.c-menu {
  @include c-menu($c-menu);
}

@include mq($screen-md, min) {
  @at-root #menu-overlay:not(:checked) ~ #app > main,
    #menu-overlay:not(:checked) ~ #app > footer {
    display: block;
  }
}
@include mq($screen-md, max) {
  @at-root #menu-overlay:not(:checked) ~ #app > main,
    #menu-overlay:not(:checked) ~ #app > footer {
    display: none;
  }
}
