/// Campaign Block
/// - Version 1.0.1
/// - Date last edited: 05-08-2020
/// @name c-campaign
/// @author Mads Stoumann
/// @param {String} $theme - The theme-map to use

@mixin c-campaign($theme) {
  $root: &;
  background-color: map-get($theme, background-color);
  color: map-get($theme, color);
  text-align: center;

  &__cta {
    @include c-btn('sm');
    white-space: nowrap;
    &-wrapper {
      flex: 0 0 20%;
      text-align: right;
    }
  }
  &__headline {
    font-family: map-deep-get($theme, headline, font-family);
    font-size: map-deep-get($theme, headline, font-size, default);
    text-transform: map-deep-get($theme, headline, text-transform);
  }
  &__image {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;

    &-wrapper {
      flex: 0 0 25%;
      margin: 0;
      padding: 0;
      position: relative;
      &::after {
        content: '';
        display: block;
        padding-bottom: 33.333333%; /* Aspect-ratio 3:1 */
      }
    }
  }
  &__inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: map-get($theme, max-width);
    position: relative;

    &:hover {
      text-decoration: none;
    }
  }
  &__splash {
    clip-path: map-deep-get($theme, splash, clip-path);
    display: none;
    font-size: 100%;
    margin: 0;
    position: absolute;
    right: map-deep-get($theme, splash, offset);
    top: map-deep-get($theme, splash, offset);
    width: map-deep-get($theme, splash, width);
    &::after {
      background-color: map-deep-get($theme, splash, background-color);
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    &-line1,
    &-line2,
    &-line3 {
      color: map-deep-get($theme, splash, color);
      left: 50%;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);
      width: 100%;
    }
    &-line1 {
      font-family: map-deep-get($theme, splash, line1, font-family);
      font-size: map-deep-get($theme, splash, line1, font-size);
      top: map-deep-get($theme, splash, line1, top);
    }
    &-line2 {
      font-family: map-deep-get($theme, splash, line2, font-family);
      font-size: map-deep-get($theme, splash, line2, font-size);
      top: map-deep-get($theme, splash, line2, top);
    }
    &-line3 {
      font-family: map-deep-get($theme, splash, line3, font-family);
      font-size: map-deep-get($theme, splash, line3, font-size);
      top: map-deep-get($theme, splash, line3, top);
    }
  }
  &__text {
    font-size: map-deep-get($theme, text, font-size, default);
    line-height: 1.4;
  }
  .c-cnt {
    display: flex;
    flex-wrap: wrap;
    font-family: map-deep-get($theme, counter, font-family);
    justify-content: center;
    line-height: 1;
    text-transform: map-deep-get($theme, counter, text-transform);

    &__headline {
      flex: 0 0 100%;
      font-size: map-deep-get($theme, counter, font-size, default);
      margin: map-deep-get($theme, counter, margin, default);
    }
    &__item,
    &__divider {
      font-family: map-deep-get($theme, counter-item, font-family);
      font-size: map-deep-get($theme, counter-item, font-size, default);
      line-height: 1;
    }
    &__item {
      display: inline-flex;
      flex-direction: column;
      margin: 0 map-deep-get($theme, counter-item, margin, default);
      &#{$root}__item--daysonly {
        width: auto;
      }
      &:last-of-type {
        text-align: left;
        width: 2ch;
      }
    }
    &__label {
      font-family: map-deep-get($theme, counter-label, font-family);
      font-size: map-deep-get($theme, counter-label, font-size, default);
      text-transform: capitalize;
    }
  }
  /* Modifier */
  &--bg {
    & #{$root}__countdown,
    & #{$root}__header {
      padding: map-deep-get($theme, space-unit, default) 0;
      position: relative;
      z-index: 1;
    }
    & #{$root}__image-wrapper {
      display: contents;
      position: relative;
      &::after {
        display: none;
      }
    }
  }
  &--large {
    & #{$root}__image-wrapper {
      flex: 0 0 33%;
      &::after {
        padding-bottom: 56.25%; /* Aspect-ratio 16:9 */
      }
    }
  }
  &--noimage {
    padding: 0.75em 0;
  }
  /* RWD */
  @include mq($screen-md, max) {
    &--full,
    &--large {
      .c-cnt {
        &__headline {
          font-size: map-deep-get(
            $theme,
            --modifier,
            counter,
            font-size,
            default
          );
          margin: map-deep-get($theme, counter, margin, desktop);
        }
        &__item,
        &__divider {
          font-size: map-deep-get(
            $theme,
            --modifier,
            counter-item,
            font-size,
            default
          );
          margin: 0 map-deep-get($theme, counter-item, margin, default);
        }
        &__item--daysonly {
          font-size: map-deep-get(
            $theme,
            --modifier,
            counter-item,
            font-size,
            default
          );
        }
        &__label {
          font-size: map-deep-get(
            $theme,
            --modifier,
            counter-label,
            font-size,
            default
          );
        }
      }
      & #{$root}__cta-wrapper {
        text-align: center;
      }
      & #{$root}__headline {
        font-size: map-deep-get(
          $theme,
          --modifier,
          headline,
          font-size,
          default
        );
      }
      & #{$root}__text {
        font-size: map-deep-get($theme, --modifier, text, font-size, default);
      }
      & #{$root}__inner {
        display: block;
        padding: 0 0 map-deep-get($theme, space-unit, default);
        text-align: center;
        & > * {
          margin-bottom: map-deep-get($theme, space-unit, default);
        }
      }
    }
    &--small {
      & #{$root}__image-wrapper {
        flex: 0 0 50%;
      }
      & #{$root}__cta-wrapper {
        display: none;
      }
      & #{$root}__headline {
        font-size: map-deep-get(
          $theme,
          --modifier,
          headline,
          font-size,
          default
        );
      }
      & #{$root}__text {
        font-size: map-deep-get($theme, --modifier, text, font-size, default);
      }
    }
    &--noimage {
      padding: 0.5em 0;
    }
  }
  @include mq($screen-md, min) {
    .c-cnt__headline {
      margin: map-deep-get($theme, counter, margin, desktop);
    }
    &--bg {
      & #{$root}__countdown,
      & #{$root}__header {
        padding: map-deep-get($theme, space-unit, desktop) 0;
      }
    }
    &--full {
      & #{$root}__cta-wrapper {
        text-align: center;
      }
      & #{$root}__inner {
        display: block;
        padding: 0 map-deep-get($theme, space-unit, default)
          map-deep-get($theme, space-unit, desktop);
        & > * {
          margin-bottom: map-deep-get($theme, space-unit, desktop);
        }
        & #{$root}__splash {
          display: block;
        }
      }
      &#{$root}--bg {
        #{$root}__inner > * {
          margin-bottom: 0;
        }
      }
    }
    &--full,
    &--large {
      & #{$root}__cta {
        @include c-btn();
      }
      & #{$root}__headline {
        font-size: map-deep-get(
          $theme,
          --modifier,
          headline,
          font-size,
          desktop
        );
      }
      & #{$root}__text {
        font-size: map-deep-get($theme, --modifier, text, font-size, desktop);
      }
      .c-cnt {
        &__headline {
          font-size: map-deep-get(
            $theme,
            --modifier,
            counter,
            font-size,
            desktop
          );
        }
        &__item,
        &__divider {
          font-size: map-deep-get(
            $theme,
            --modifier,
            counter-item,
            font-size,
            desktop
          );
        }
        &__item--daysonly {
          font-size: map-deep-get(
            $theme,
            --modifier,
            counter-item,
            font-size,
            desktop
          );
        }
        &__label {
          font-size: map-deep-get(
            $theme,
            --modifier,
            counter-label,
            font-size,
            desktop
          );
        }
      }
    }
    &--small {
      .c-cnt {
        &__headline {
          font-size: map-deep-get($theme, counter, font-size, desktop);
        }
        &__item,
        &__item--daysonly,
        &__divider {
          font-size: map-deep-get($theme, counter-item, font-size, desktop);
        }
        &__label {
          font-size: map-deep-get($theme, counter-label, font-size, desktop);
        }
      }
    }
    &--splash-left {
      #{$root}__splash {
        left: map-deep-get($theme, splash, offset);
        right: auto;
      }
    }
  }
}

.c-campaign {
  @include c-campaign($c-campaign);
}
