/**
 * oneblock-widgets.css
 * @version 1.0.00
 * @summary 30-09-2019
 * @author Mads Stoumann
 * @description OneBlock: Custom Widgets.
 * NOTE: This file should be changed/updated for every client
 */

/* Countdown Clock */
.c-clock {
  background-color: #494F5B;
  border-radius: .25rem;
  color: #FFF;
  display: flex;
  justify-content: space-between;
  padding: .75rem .5rem;
}
.c-clock__item {
  background-color: #292C32;
  border-radius: .25rem;
  color: #FFF;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0 .25rem;
  text-align: center;
}
.c-clock__item span {
  font-size: small;
  color: #999;
}