






















































@import 'theme/sass/settings/_settings.vars.scss';

.activity-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    max-width: 152px;
    text-align: center;
    margin-top: 26px;
  }

  // .activity-indicator__animation
  &__animation {
    width: 64px;
    height: 64px;
  }

  // .activity-indicator--fullscreen
  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    z-index: map-get($zindex, 'activity-indicator');

    &:before {
      content: '';
      position: fixed;
      z-index: -1;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background: white;
      opacity: 0.9;
    }
  }
}
