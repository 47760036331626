@import url('./oneblock-widgets.css');
/**
 * oneblock-config.css
 * @version 1.1.41
 * @summary 23-09-2020
 * @author Mads Stoumann
 * @description OneBlock: Default Configuration.
 * NOTE: This file should be changed/updated for every client
 */

/*
Naming: `--ob` + ELEMENT-PREFIX + EMMET-abbr + (Optional) NUMERIC VALUE
Default values use NUMERIC VALUE 0 (Zero)

ELEMENT PREFIX
-A Additional headline
-B Background Image/Video
-C Image Caption
-E Content Box
-H Headline
-I Image
-K Secondary Link
-L Primary Link
-M Meta
-N Tagline
-O OneBlock
-P Offer/Price
-R Ribbon
-S Splash
-Dh Display Headline
-T Text
-W Media Wrapper
-Cnt Countdown
*/

/* CSS Custom Properties */
:root {
  /* GENERIC */
  --ob-alpha: 1;
  --ob-bdrs: 0;
  --ob-color-transparent: linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(135deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(135deg, transparent 75%, #ccc 75%);
  --ob-distance-lr: 16px;
  --ob-distance-tb: 16px;
  --ob-distance-unit: 16px;
  --ob-font-1: 'TeliaSans', Arial, sans-serif;
  /* Body Text Font */
  --ob-font-2: 'Telia Sans Heading', sans-serif;
  /* Headline Font */
  --ob-grid-gap: 16px;
  --ob-margin: 16px;
  --ob-margin-unit: 16px;
  --ob-translate-x: 0px;
  --ob-translate-y: 0px;
  --ob-zindex: 3;
  /*
	========
	ONEBLOCK
	========
	*/
  --ob-O-bg0: transparent;
  --ob-O-bg1: #ffffff;
  --ob-O-bg2: #29003E;
  --ob-O-bg3: #FBF0FF;
  --ob-O-bg4: #5C524E;
  --ob-O-bg5: #F8EEE8;

  --ob-O-bg6: #F4F4F9;
  --ob-O-bg7: #E8E8ED;
  --ob-O-bg8: #29003E;
  --ob-O-bg9: #29003E;
  --ob-O-br1: 2px;
  --ob-O-br2: 3px;
  --ob-O-br3: 5px;
  --ob-O-br4: 1em;
  --ob-O-bx1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --ob-O-bx2: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
  --ob-O-bx3: 0 4px 16px rgba(0, 0, 0, 0.08);
  --ob-O-bx4: 0 4px 16px rgba(0, 0, 0, 0.16);
  --ob-O-bx5: 0 12px 32px rgba(0, 0, 0, 0.16);
  --ob-O-fz0: 1rem;
  --ob-O-bx-hv2: var(--ob-O-bx5);
  /*
	===========
	CONTENT BOX
	===========
	Background Color in raw RGB. Alpha (--ob-alpha) is added/combined in oneblock-color.css
	*/
  --ob-E-bg1: 255, 255, 255;
  --ob-E-bg2: 41, 0, 62;
  --ob-E-bg3: 251, 240, 255;
  --ob-E-bg4: 92, 82, 78;
  --ob-E-bg5: 248, 238, 232;
  --ob-E-bg6: 244, 244, 249;
  --ob-E-bg7: 232, 232, 237;
  --ob-E-bg8: 41, 0, 62;
  --ob-E-bg9: 41, 0, 62;

  --ob-E-c0: #121214;
  --ob-E-c1: #fff;
  --ob-E-c2: #29003E;
  --ob-E-c3: #FBF0FF;
  --ob-E-c4: #5C524E;
  --ob-E-c5: #F8EEE8;
  /* Padding Units */
  --ob-E-pt: 0.5rem;
  --ob-E-pr: 0.5rem;
  --ob-E-pb: 0.5rem;
  --ob-E-pl: 0.5rem;
  /* Defaults */
  --ob-E-pt0: 1.5rem;
  --ob-E-pr0: 1rem;
  --ob-E-pb0: 1.5rem;
  --ob-E-pl0: 1rem;
  /*
	========
	HEADLINE
	========
	*/
  --ob-H-c0: #121214;
  --ob-H-c1: #fff;
  --ob-H-c2: #29003E;
  --ob-H-c3: #FBF0FF;
  --ob-H-c4: #5C524E;
  --ob-H-c5: #F8EEE8;

  --ob-H-fz1: 16px;
  --ob-H-lh1: 20px;
  --ob-H-fz2: 18px;
  --ob-H-lh2: 24px;
  --ob-H-fz3: 20px;
  --ob-H-lh3: 24px;
  --ob-H-fz4: 24px;
  --ob-H-lh4: 28px;
  --ob-H-fz5: 28px;
  --ob-H-lh5: 32px;
  --ob-H-fz6: 32px;
  --ob-H-lh6: 36px;
  --ob-H-fz7: 36px;
  --ob-H-lh7: 36px;
  --ob-H-fz8: 40px;
  --ob-H-lh8: 48px;
  --ob-H-fz9: 48px;
  --ob-H-lh9: 48px;
  --ob-H-fz10: 56px;
  --ob-H-lh10: 56px;

  --ob-H-fw1: 700;
  --ob-H-fw2: var(--ob-H-fw1);
  --ob-H-fw3: var(--ob-H-fw1);
  --ob-H-fw4: var(--ob-H-fw1);
  --ob-H-fw5: var(--ob-H-fw1);

  /* Defaults */
  --ob-H-ff0: var(--ob-font-1);
  --ob-H-fz0: var(--ob-H-fz4);
  --ob-H-lh0: var(--ob-H-lh4);
  --ob-H-mb0: var(--ob-margin-unit);
  --ob-H-fw0: 700;
  --ob-H-tt0: normal;
/*
	========
	ADDITIONAL HEADLINE
	========
	*/
  --ob-A-c0: #121214;
  --ob-A-c1: #fff;
  --ob-A-c2: #29003E;
  --ob-A-c3: #FBF0FF;
  --ob-A-c4: #5C524E;
  --ob-A-c5: #F8EEE8;

  --ob-A-fz1: 10px;
  --ob-A-lh1: 12px;
  --ob-A-fz2: 12px;
  --ob-A-lh2: 16px;
  --ob-A-fz3: 14px;
  --ob-A-lh3: 20px;
  --ob-A-fz4: 16px;
  --ob-A-lh4: 24px;
  --ob-A-fz5: 20px;
  --ob-A-lh5: 28px;
  --ob-A-fz6: 24px;
  --ob-A-lh6: 32px;

  --ob-A-fw2: 400;
  --ob-A-fw3: 500;
  --ob-A-fw4: 700;

  /* Defaults */
  --ob-A-ff0: var(--ob-font-1);
  --ob-A-fz0: var(--ob-A-fz4);
  --ob-A-lh0: var(--ob-A-lh4);
  --ob-A-mb0: var(--ob-margin-unit);
  --ob-A-fw0: var(--ob-A-fw2);
  --ob-A-tt0: none;

  /*
	========
	DISPLAY HEADLINE
	========
	*/
  --ob-Dh-cl0: #121214;
  --ob-Dh-cl1: #fff;
  --ob-Dh-cl2: #29003E;
  --ob-Dh-cl3: #FBF0FF;
  --ob-Dh-cl4: #5C524E;
  --ob-Dh-cl5: #F8EEE8;

  --ob-Dh-fz1: 40px;
  --ob-Dh-lh1: 40px;
  --ob-Dh-fz2: 48px;
  --ob-Dh-lh2: 48px;
  --ob-Dh-fz3: 64px;
  --ob-Dh-lh3: 64px;
  --ob-Dh-fz4: 80px;
  --ob-Dh-lh4: 80px;
  
  /* Defaults */
  --ob-Dh-ff0: var(--ob-font-2);
  --ob-Dh-fz0: var(--ob-Dh-fz1);
  --ob-Dh-lh0: var(--ob-Dh-lh1);
  --ob-Dh-mb0: var(--ob-margin-unit);
  --ob-Dh-fw0: 400;
  /*
	=============
	IMAGE CAPTION
	=============
	*/
  --ob-C-bg0: #29003E;
  --ob-C-bg1: #E4175C;
  --ob-C-bg2: #f2f2f2;
  --ob-C-bg3: #000000;
  --ob-C-bg4: #008080;
  --ob-C-bg5: #ffffff;
  --ob-C-c0: #ffffff;
  --ob-C-c1: #29003E;
  --ob-C-fz0: 14px;
  --ob-C-lh0: 20px;
  /* Padding Units */
  --ob-C-pl: 0.125em;
  --ob-C-pt: 0.125em;
  /* Defaults */
  --ob-C-ff0: var(--ob-font-1);
  --ob-C-lh0: var(--ob-H-lh1);
  --ob-C-fw0: 500;
  --ob-C-pl0: 1em;
  --ob-C-pt0: 0.125em;
  /*
  ======
  RIBBON
  ======
  */
  --ob-R-bg0: #29003E;
  --ob-R-bg1: #E4175C;
  --ob-R-bg2: #008080;
  --ob-R-bg3: #ffffff;
  --ob-R-bg4: #000000;
  --ob-R-c0: #ffffff;
  --ob-R-c1: #29003E;
  --ob-R-fz0: 14px;
  --ob-R-fw0: 500;
  --ob-R-m0: 0 0 1rem 0;
  --ob-R-p0: 2px 12px 4px 12px;
  --ob-R-fs0: normal;
  --ob-R-br0: 0;
  --ob-R-br1: 2px 0 2px 0;
  --ob-R-br2: 3px 0 3px 0;
  /*
	=====
	LINKS
	=====
	*/
  --ob-L-bdrs: 2em;
  --ob-L-bdw: 1px;
  --ob-L-bgc1: #29003E;
  --ob-L-bgc2: #ffffff;
  --ob-L-bgc3: #990AE3;
  --ob-L-bgc4: #29003E;
  --ob-L-c1: #ffffff;
  --ob-L-c2: #29003E;
  --ob-L-c3: #ffffff;
  --ob-L-c4: #fff;
  --ob-L-bgh1: #5E0092;
  --ob-L-bga1: #7800B4;
  --ob-L-bgf1: var(--ob-L-bgc1);
  --ob-L-bgh2: #EDC8FF;
  --ob-L-bga2: #DE99FF;
  --ob-L-bgf2: #ffffff;
  --ob-L-bgh3: #7800B4;
  --ob-L-bga3: #5E0092;
  --ob-L-bgf3: #990AE3;
  --ob-L-fc: #DE99FF;
  --ob-L-ff0: 'TeliaSans';
  --ob-L-fw0: 400;
  --ob-L-fz0: 16px;
  --ob-L-lh0: inherit;
  --ob-L-p: 12px 24px;
  /*
	====
	META
	====
	*/
  --ob-M-c1: #000;
  --ob-M-c2: #333;
  --ob-M-c3: #666;
  --ob-M-c4: #999;
  --ob-M-sz: 1rem;
  /* Defaults */
  --ob-M-ff0: var(--ob-font-1);
  --ob-M-fz0: var(--ob-T-fz1);
  --ob-M-lh0: var(--ob-T-lh1);
  --ob-M-fw0: 400;
  --ob-M-mb0: var(--ob-margin-unit);
  --ob-M-icon-mie: 0.15em;
  --ob-M-item-mie: 0.65em;
  --ob-M-tt0: normal;
  /*
	=============
	PRICE / OFFER
	=============
	*/
  --ob-P-bf1: 12px;
  --ob-P-bf2: 14px;
  --ob-P-bf3: 16px;
  --ob-P-bf4: 24px;

  --ob-P-pf1: 16px;
  --ob-P-pf2: 24px;
  --ob-P-pf3: 32px;
  --ob-P-pf4: 40px;
  --ob-P-pf5: 48px;

  --ob-P-cf1: 12px;
  --ob-P-cf2: 14px;
  --ob-P-cf3: 16px;
  --ob-P-cf4: 24px;

  --ob-P-tf1: 12px;
  --ob-P-tf2: 14px;

  --ob-P-lf1: 12px;
  --ob-P-lf2: 14px;
  /* Defaults */
  --ob-P-base-c0: inherit;
  --ob-P-base-ff0: var(--ob-font-1);
  --ob-P-base-fz0: 14px;
  --ob-P-base-fw0: 400;
  --ob-P-base-td0: line-through;
  --ob-P-curr-fz0: 14px;
  --ob-P-curr-fw0: 700;
  --ob-P-price-c0: inherit;
  --ob-P-price-ff0: var(--ob-font-1);
  --ob-P-price-fz0: 32px;
  --ob-P-price-fw0: 700;
  --ob-P-text-c0: #6e6e6e;
  --ob-P-text-ff0: var(--ob-font-1);
  --ob-P-text-fz0: 14px;
  --ob-P-text-fw0: inherit;
  --ob-P-lh0: 1;
  --ob-P-m0: 0 0 var(--ob-margin-unit) 0;
  --ob-P-label-ff0: var(--ob-font-1);
  --ob-P-label-fz0: 14px;
  --ob-P-label-fw0: inherit;
  /*
	======
	SPLASH
	======
	*/
  --ob-S-bg0: #29003E;
  --ob-S-bg1: #E4175C;
  --ob-S-bg2: #ffffff;
  --ob-S-bg3: #990ae3;
  --ob-S-bg4: #000000;
  --ob-S-bg5: #00827C;
  --ob-S-bg6: #f2f2f2;
  --ob-S-bg7: #ff9b00;
  --ob-S-c0: #ffffff;
  --ob-S-c1: #ffffff;
  --ob-S-c2: #29003E;
  --ob-S-c3: #ffffff;
  --ob-S-c4: #ffffff;
  --ob-S-c5: #ffffff;
  --ob-S-c6: #29003E;
  --ob-S-c7: #ffffff;
  --ob-S-fz1: 12px;
  --ob-S-fz2: 14px;
  --ob-S-fz3: 16px;
  --ob-S-fz4: 20px;
  --ob-S-fz5: 24px;
  --ob-S-fz6: 32px;
  --ob-S-fz7: 48px;
  --ob-S-sz1: 64px;
  --ob-S-sz2: 80px;
  --ob-S-sz3: 96px;
  --ob-S-sz4: 128px;
  --ob-S-sz5: 176px;
  /* Defaults */
  --ob-S-c0: #fff;
  --ob-S-sz0: var(--ob-S-sz2);
  --ob-S1-ff0: var(--ob-font-1);
  --ob-S2-ff0: var(--ob-font-1);
  --ob-S3-ff0: var(--ob-font-1);
  --ob-S1-fw0: 700;
  --ob-S2-fw0: 700;
  --ob-S3-fw0: 700;
  --ob-S1-fz0: var(--ob-S-fz1);
  --ob-S2-fz0: var(--ob-S-fz1);
  --ob-S3-fz0: var(--ob-S-fz1);
  /*
	=======
	TAGLINE
	=======
	*/
  --ob-N-c0: #121214;
  --ob-N-c1: #fff;
  --ob-N-c2: #29003E;
  --ob-N-c3: #FBF0FF;
  --ob-N-c4: #5C524E;
  --ob-N-c5: #F8EEE8;

  --ob-N-fz1: 10px;
  --ob-N-fz2: 12px;
  --ob-N-lh1: 16px;
  
  /* Defaults */
  --ob-N-ff0: var(--ob-font-1);
  --ob-N-fz0: var(--ob-N-fz2);
  --ob-N-lh0: var(--ob-N-lh1);
  --ob-N-lts0: 1px;
  --ob-N-mb0: var(--ob-margin-unit);
  --ob-N-fw0: 400;
  --ob-N-tt0: uppercase;
  /*
	====
	TEXT
	====
	*/
  --ob-T-fz1: 10px;
  --ob-T-lh1: 12px;
  --ob-T-fz2: 12px;
  --ob-T-lh2: 14px;
  --ob-T-fz3: 14px;
  --ob-T-lh3: 20px;
  --ob-T-fz4: 16px;
  --ob-T-lh4: 24px;
  --ob-T-fz5: 20px;
  --ob-T-lh5: 28px;
  --ob-T-fz6: 24px;
  --ob-T-lh6: 32px;
  --ob-T-fz10: calc(16px + (24 - 16) * ((100vw - 300px) / (3200 - 300)));
  --ob-T-lh10: 1.6;

  /*
	====
	COUNTDOWN
	====
	*/
  --ob-Cnt-digit-fz1: 58px;
  --ob-Cnt-digit-fz2: 48px;
  --ob-Cnt-digit-fz3: 36px;
  --ob-Cnt-digit-fz4: 22px;
  --ob-Cnt-digit-fz5: 14px;

  --ob-Cnt-digit-width-sz1: 2ch;
  --ob-Cnt-digit-width-sz2: 2ch;
  --ob-Cnt-digit-width-sz3: 2ch;
  --ob-Cnt-digit-width-sz4: 2ch;
  --ob-Cnt-digit-width-sz5: 2ch;

  --ob-Cnt-label-fz1: 14px;
  --ob-Cnt-label-fz2: 14px;
  --ob-Cnt-label-fz3: 12px;
  --ob-Cnt-label-fz4: 12px;
  --ob-Cnt-label-fz5: 12px;

  --ob-Cnt-headline-fz1: 16px;
  --ob-Cnt-headline-fz2: 16px;
  --ob-Cnt-headline-fz3: 16px;
  --ob-Cnt-headline-fz4: 14px;
  --ob-Cnt-headline-fz5: 12px;

  /* Defaults */
  --ob-T-ff0: var(--ob-font-1);
  --ob-T-fz0: var(--ob-T-fz3);
  --ob-T-lh0: var(--ob-T-lh3);
  --ob-T-mb0: var(--ob-margin-unit);
  --ob-T-fw0: 400;
}

/*
====================
CUSTOM CLIENT STYLES
====================
*/

/* Image Caption: Text-Mask */
.-C-tm1 .c-ob__bg-caption {
  bottom: 0;
  height: 100%;
  max-height: calc(100% - calc(var(--ob-distance-tb) * 2));
  max-width: calc(100% - calc(var(--ob-distance-lr) * 2));
  mix-blend-mode: screen;
  overflow: hidden;
  width: 100%;
}

/* Headline: Qoute Styles */
.-H-qs1 .c-ob__headline {
  border-left: 0.25em solid hsl(200, 40%, 80%);
  padding-left: 1.75em;
}

.-H-qs1 .c-ob__headline::before {
  color: hsl(200, 40%, 80%);
  content: '\201C';
  font-family: inherit;
  font-size: 4em;
  left: 0.1em;
  position: absolute;
  top: 0.1em;
}

/* Link: Text Colors */
.-L-bg1 .c-ob__link-text,
.-K-bg1 .c-ob__link-text-sec {
  color: var(--ob-L-bgc1);
}

.-L-bg2 .c-ob__link-text,
.-K-bg2 .c-ob__link-text-sec {
  color: var(--ob-L-bgc2);
}

.-L-bg3 .c-ob__link-text,
.-K-bg3 .c-ob__link-text-sec {
  color: var(--ob-L-bgc3);
}

.-L-bg4 .c-ob__link-text,
.-K-bg4 .c-ob__link-text-sec {
  color: var(--ob-L-bgc4);
}

/* Link: Style: BTN */
.-L-st1 .c-ob__link-text,
.-L-st2 .c-ob__link-text,
.-K-st1 .c-ob__link-text-sec,
.-K-st2 .c-ob__link-text-sec {
  border-color: transparent;
  border-radius: var(--ob-L-bdrs);
  border-style: solid;
  border-width: var(--ob-L-bdw);
  box-sizing: border-box;
  padding: var(--ob-L-p);
  text-decoration: none;
  white-space: nowrap;
  font-weight: 500;
}

/* Link: Style: BTN-fill */
.-L-st1.-L-bg1 .c-ob__link-text,
.-K-st1.-K-bg1 .c-ob__link-text-sec {
  background-color: var(--ob-L-bgc1);
  color: var(--ob-L-c1);
}

.-L-st1.-L-bg2 .c-ob__link-text,
.-K-st1.-K-bg2 .c-ob__link-text-sec {
  background-color: var(--ob-L-bgc2);
  color: var(--ob-L-c2);
}

.-L-st1.-L-bg3 .c-ob__link-text,
.-K-st1.-K-bg3 .c-ob__link-text-sec {
  background-color: var(--ob-L-bgc3);
  color: var(--ob-L-c3);
}

.-L-st1.-L-bg4 .c-ob__link-text,
.-K-st1.-K-bg4 .c-ob__link-text-sec {
  background-color: var(--ob-L-bgc4);
  color: var(--ob-L-c4);
}

/* Hover */
.-L-st1.-L-bg1 .c-ob__link-text:hover,
.-K-st1.-K-bg1 .c-ob__link-text-sec:hover {
  background-color: var(--ob-L-bgh1);
}

.-L-st1.-L-bg2 .c-ob__link-text:hover,
.-K-st1.-K-bg2 .c-ob__link-text-sec:hover,
.-L-st2.-L-bg1 .c-ob__link-text:hover,
.-K-st2.-K-bg1 .c-ob__link-text-sec:hover {
  background-color: var(--ob-L-bgh2);
}

.-L-st2.-L-bg2 .c-ob__link-text:hover,
.-K-st2.-K-bg2 .c-ob__link-text-sec:hover {
  background-color: #fff;
  color: var(--ob-L-bgc1);
}

.-L-st2.-L-bg1 .c-ob__link-text:hover,
.-K-st2.-K-bg1 .c-ob__link-text-sec:hover {
  background-color: var(--ob-L-bgh1);
  color: #fff;
  border-color: transparent;
}

.-L-st2.-L-bg2 .c-ob__link-text:hover,
.-K-st2.-K-bg2 .c-ob__link-text-sec:hover {
  border-color: transparent;
}

.-L-st1.-L-bg3 .c-ob__link-text:hover,
.-K-st1.-K-bg3 .c-ob__link-text-sec:hover {
  background-color: var(--ob-L-bgh3);
}

.-L-st2.-L-bg3 .c-ob__link-text:hover,
.-K-st2.-K-bg3 .c-ob__link-text-sec:hover {
  background-color: #FBF0FF;
}

/* Link: Style: BTN Outline */
.-L-st2.-L-bg1 .c-ob__link-text,
.-K-st2.-K-bg1 .c-ob__link-text-sec {
  background-color: transparent;
  border-color: var(--ob-L-bgc1);
}

.-L-st2.-L-bg2 .c-ob__link-text,
.-K-st2.-K-bg2 .c-ob__link-text-sec {
  border-color: var(--ob-L-bgc2);
}

.-L-st2.-L-bg4 .c-ob__link-text,
.-K-st2.-K-bg4 .c-ob__link-text-sec {
  border-color: var(--ob-L-bgc4);
}

/* Active */
.-L-st1.-L-bg1 .c-ob__link-text:active,
.-K-st1.-K-bg1 .c-ob__link-text-sec:active {
  background-color: var(--ob-L-bga1);
}

.-L-st1.-L-bg2 .c-ob__link-text:active,
.-K-st1.-K-bg2 .c-ob__link-text-sec:active {
  background-color: var(--ob-L-bga2);
}

.-L-st2.-L-bg1 .c-ob__link-text:active,
.-K-st2.-K-bg1 .c-ob__link-text-sec:active {
  background-color: var(--ob-L-bga1);
  color: #fff;
  border-color: transparent;
}

.-L-st2.-L-bg1 .c-ob__link-text:active,
.-K-st2.-K-bg1 .c-ob__link-text-sec:active {
  border-color: transparent;
}

.-L-st2.-L-bg2 .c-ob__link-text:active,
.-K-st2.-K-bg2 .c-ob__link-text-sec:active {
  background-color: #FBF0FF;
  color: var(--ob-L-bgc1);
}

.-L-st2.-L-bg1 .c-ob__link-text:active,
.-K-st2.-K-bg1 .c-ob__link-text-sec:active {
  background-color: var(--ob-L-bga1);
  color: #fff;
}

.-L-st2.-L-bg2 .c-ob__link-text:active,
.-K-st2.-K-bg2 .c-ob__link-text-sec:active {
  background: #FBF0FF;
}

.-L-st1.-L-bg3 .c-ob__link-text:active,
.-K-st1.-K-bg3 .c-ob__link-text-sec:active {
  background-color: var(--ob-L-bga3);
}

.-L-st2.-L-bg3 .c-ob__link-text:active,
.-K-st2.-K-bg3 .c-ob__link-text-sec:active {
  background-color: #EDC8FF;
}

/* Focus */

.-L-st1.-L-bg1 .c-ob__link-text:focus-visible,
.-K-st1.-K-bg1 .c-ob__link-text-sec:focus-visible {
  outline-style: solid;
  outline-color: var(--ob-L-fc);
  outline-width: 1px;
  outline-offset: 2px;
  background-color: var(--ob-L-bgf1);
}

.-L-st1.-L-bg2 .c-ob__link-text:focus-visible,
.-K-st1.-K-bg2 .c-ob__link-text-sec:focus-visible {
  outline-style: solid;
  outline-color: var(--ob-L-fc);
  outline-width: 1px;
  outline-offset: 2px;
  background-color: var(--ob-L-bgf2);
}

.-L-st1.-L-bg3 .c-ob__link-text:focus-visible,
.-K-st1.-K-bg3 .c-ob__link-text-sec:focus-visible {
  outline-style: solid;
  outline-color: var(--ob-L-fc);
  outline-width: 1px;
  outline-offset: 2px;
  background-color: var(--ob-L-bgf3);
}

.-L-st1.-L-bg2 .c-ob__link-text:focus-visible,
.-K-st1.-K-bg2 .c-ob__link-text-sec:focus-visible,
.-L-st2.-L-bg1 .c-ob__link-text:focus-visible,
.-K-st2.-K-bg1 .c-ob__link-text-sec:focus-visible,
.-L-st2.-L-bg2 .c-ob__link-text:focus-visible,
.-K-st2.-K-bg2 .c-ob__link-text-sec:focus-visible,
.-L-st2.-L-bg3 .c-ob__link-text:focus-visible,
.-K-st2.-K-bg3 .c-ob__link-text-sec:focus-visible {
  outline-style: solid;
  outline-color: var(--ob-L-fc);
  outline-width: 1px;
  outline-offset: 2px;
}

/* Link BTN Sizes */
.-L-st1.-L-sz1 .c-ob__link-text,
.-L-st2.-L-sz1 .c-ob__link-text,
.-K-st1.-K-sz1 .c-ob__link-text-sec,
.-K-st2.-K-sz1 .c-ob__link-text-sec {
  border-radius: 20px;
  padding: 8px 20px;
}

.-L-st1.-L-sz2 .c-ob__link-text,
.-L-st2.-L-sz2 .c-ob__link-text,
.-K-st1.-K-sz2 .c-ob__link-text-sec,
.-K-st2.-K-sz2 .c-ob__link-text-sec {
  border-radius: 28px ;
  padding: 16px 28px;
}

@media only screen and (max-width: 600px) {
  .c-ob__link-text,
  .c-ob__link-text-sec {
    /*
        HACK: FORCE DEFAULT BUTTONS TO SMALL, WHEN ON MOBILE
        !important is used in order to target all scenarios.
        */
    font-size: 14px !important;
    padding: 0.5625em 1.3em !important;
  }
}

/* Image Caption Styles */
.c-ob__bg-caption {
  border-radius: var(--ob-bdrs) 0 var(--ob-bdrs) 0;
}

.-C-ps3 .c-ob__bg-caption {
  border-radius: 0 var(--ob-bdrs) 0 var(--ob-bdrs);
}

/* Tagline: Styles */
.-N-st1 .c-ob__tagline {
  color: var(--ob-N-c1);
}

.-N-st2 .c-ob__tagline {
  color: var(--ob-N-c2);
}

.-N-st3 .c-ob__tagline {
  color: var(--ob-N-c3);
}

.-N-st4 .c-ob__tagline {
  color: var(--ob-N-c4);
}

/* Video Poster Play Overlay */
.c-ob__bg-play svg {
  cursor: pointer;
  display: inline-block;
  fill: #9b009b;
  height: 30%;
  left: 50%;
  max-height: 7rem;
  max-width: 7rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

.c-ob__bg-play:hover svg {
  fill: #fff;
}

/* Custom Shapes */
.-B-sh10 .c-ob__bg {
  clip-path: url('#svg-curve-telia');
}

.-B-sh10 .c-ob__bg-wrapper__bg {
  clip-path: url('#svg-curve-telia');
}

/* TODO: DKT-10212 Safari can't use clip-path on outer wrapper */
@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .-B-sh10 .c-ob__bg-wrapper__bg .c-ob__bg,
    .-B-sh10 .c-ob__bg__no-clip {
      clip-path: none;
    }
  }
}

/* TODO: DKT-10212 Safari can't use clip-path on outer wrapper*/
.c-ob__bg__no-clip,
.-B-sh10 .c-ob__bg__no-clip {
  clip-path: none;
}

.-B-sh11 .c-ob__bg {
  clip-path: url('#svg-pebble-telia');
}

/* Counter in OneBlock */
.c-ob__countdown .c-cnt {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 1;
  margin-bottom: var(--ob-margin-unit);
}

.c-ob__countdown .c-cnt__headline {
  display: block;
  flex: 0 0 100%;
  font-family: var(--ob-font-1);
  font-size: var(--ob-Cnt-headline-fz3);
  line-height: 1.6;
}

.c-ob__countdown .c-cnt__item {
  font-family: var(--ob-font-2);
  font-size: var(--ob-Cnt-digit-fz3);
  line-height: 1.2;
  text-align: center;
}
.c-ob__countdown .c-cnt__item {
  display: inline-flex;
  flex-direction: column;
}

.c-ob__countdown .c-cnt__item:last-of-type {
  text-align: left;
  width: 2ch;
}

.c-ob__countdown .c-cnt__divider {
  margin: 0 4px;
  font-size: var(--ob-Cnt-digit-fz3);
  font-family: var(--ob-font-1);
  font-weight: 400;
}

.c-ob__countdown .c-cnt__label {
  font-family: var(--ob-font-1);
  font-weight: 400;
  font-size: var(--ob-Cnt-label-fz3);
  text-align: center;
  text-transform: capitalize;
}

@media all and (-ms-high-contrast: none) {
  .c-ob__bg-wrapper {
    height: 100%;
  }
  .-K-st1.-K-sz1 .c-ob__link-text-sec,
  .-K-st2.-K-sz1 .c-ob__link-text-sec,
  .-L-st1.-L-sz1 .c-ob__link-text,
  .-L-st2.-L-sz1 .c-ob__link-text {
    margin: 16px 0;
  }
}

.c-ob__display-headline {
  font-style: normal;
}

div[style*="z-index: 3000"] {
  display: none;
}
