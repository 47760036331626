











































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';

.product-login-prompt {
  order: map-deep-get($product-order, 'prompt', 'xs');
  padding-top: $u-600;
  margin-bottom: 0;

  .c-btn {
    border-radius: 0.5rem;
    height: unset;
    padding: 0 1rem;
    text-align: left;
    width: 100%;
    justify-content: space-between;

    svg {
      fill: currentColor;
      flex: 0 0 auto;
      height: 20px;
      width: 20px;
    }

    &__icon {
      margin-right: $u-300;
    }

    &__arrow {
      margin-left: $u-350;
    }

    &__text {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      padding: $u-250 0;
      flex: 1;
      align-items: center;
    }

    &__primary {
      margin-right: $u-250;
    }

    &__secondary {
      font-size: $font-size-h5;
      font-weight: 400;
    }
  }

  &--attention {
    .c-btn {
      animation: attention 1s ease-out;
    }
  }
}

@keyframes attention {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
