















































































































































































































































































































.c-gallery {
  position: relative;

  &__selected {
    position: relative;
    text-align: center;
    flex-shrink: 0;
  }

  &__thumbnails {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    & > li {
      width: 80px;
      height: 80px;
      border: 1px solid #e2e2e2;
      cursor: pointer;
      text-align: center;
      position: relative;
      margin-bottom: 5px;

      &:not(:last-child) {
        margin-right: 5px;
      }

      &.selected {
        border-color: black;
      }
    }

    &-image {
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;

      &--dim {
        opacity: 0.2;
      }
    }

    &-expand {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &-collapse {
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        border-bottom: 3px solid black;
        border-right: 3px solid black;
        content: '';
        display: block;
        height: 18px;
        width: 18px;
        transform: rotate(135deg);
      }

      & > span {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }
  }

  &--left-thumbnails {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .c-gallery__thumbnails {
      flex-direction: column;
      justify-content: flex-end;

      & > li {
        margin-bottom: 0;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      &-collapse {
        &:before {
          transform: rotate(45deg);
        }
      }
    }
  }
}
