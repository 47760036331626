/**
 * oneblock-animations.css
 * @version 1.0.11
 * @summary 08-10-2019
 * @author Mads Stoumann
 * @description OneBlock: Animations
 * Modified from Animista: w: http://animista.net, t: @cssanimista
 */

@keyframes bounce-in-top{0%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) - 500px),0);animation-timing-function:ease-in;opacity:0}38%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out;opacity:1}55%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) - 65px),0);animation-timing-function:ease-in}72%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}81%{transform:translate3d(var(--ob-translate-x), calc(var(--ob-translate-y) - 28px),0);animation-timing-function:ease-in}90%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}95%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) - 8px),0);animation-timing-function:ease-in}100%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}}
@keyframes bounce-in-right{0%{transform:translate3d(calc(var(--ob-translate-x) + 600px),var(--ob-translate-y),0);animation-timing-function:ease-in;opacity:0}38%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out;opacity:1}55%{transform:translate3d(calc(var(--ob-translate-x) + 68px),var(--ob-translate-y),0);animation-timing-function:ease-in}72%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}81%{transform:translate3d(calc(var(--ob-translate-x) + 32px),var(--ob-translate-y),0);animation-timing-function:ease-in}90%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}95%{transform:translate3d(calc(var(--ob-translate-x) + 8px),var(--ob-translate-y),0);animation-timing-function:ease-in}100%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}}
@keyframes bounce-in-bottom{0%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) + 500px),0);animation-timing-function:ease-in;opacity:0}38%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out;opacity:1}55%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) + 65px),0);animation-timing-function:ease-in}72%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}81%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) + 28px),0);animation-timing-function:ease-in}90%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}95%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) + 8px),0);animation-timing-function:ease-in}100%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}}
@keyframes bounce-in-left{0%{transform:translate3d(calc(var(--ob-translate-x) - 600px),var(--ob-translate-y),0);animation-timing-function:ease-in;opacity:0}38%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out;opacity:1}55%{transform:translate3d(calc(var(--ob-translate-x) - 68px),var(--ob-translate-y),0);animation-timing-function:ease-in}72%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}81%{transform:translate3d(calc(var(--ob-translate-x) - 28px),var(--ob-translate-y),0);animation-timing-function:ease-in}90%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}95%{transform:translate3d(calc(var(--ob-translate-x) - 8px),var(--ob-translate-y),0);animation-timing-function:ease-in}100%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}}
@keyframes bounce-top{0%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) - 45px),0);animation-timing-function:ease-in;opacity:1}12%{opacity:1}20%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) - 24px),0);animation-timing-function:ease-in}32.5%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) - 12px),0);animation-timing-function:ease-in}41%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) - 6px),0);animation-timing-function:ease-in}46.5%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) - 4px),0);animation-timing-function:ease-in}12.5%,27.5%,37.5%,43.5%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out}50%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);animation-timing-function:ease-out;opacity:1}}
@keyframes fade-in-top{0%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) - 50px),0);opacity:0}100%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);opacity:1}}
@keyframes fade-in-right{0%{transform:translate3d(calc(var(--ob-translate-x) + 50px),var(--ob-translate-y),0);opacity:0}100%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);opacity:1}}
@keyframes fade-in-bottom{0%{transform:translate3d(var(--ob-translate-x),calc(var(--ob-translate-y) + 50px),0);opacity:0}100%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);opacity:1}}
@keyframes fade-in-bl{0%{transform:translate3d(calc(var(--ob-translate-x) - 50px), calc(var(--ob-translate-y) + 50px),0);opacity:0}100%{transform:translate3d(var(--ob-translate-x), var(--ob-translate-y),0);opacity:1}}
@keyframes fade-in-br{0%{transform:translate3d(calc(var(--ob-translate-x) + 50px), calc(var(--ob-translate-y) + 50px),0);opacity:0}100%{transform:translate3d(var(--ob-translate-x), var(--ob-translate-y),0);opacity:1}}
@keyframes fade-in-left{0%{transform:translate3d(calc(var(--ob-translate-x) - 50px),var(--ob-translate-y),0);opacity:0}100%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);opacity:1}}
@keyframes focus-in-expand{0%{letter-spacing:-.5em;filter:blur(12px);opacity:0}100%{filter:blur(0);opacity:1}}
@keyframes kenburns-bottom-left{0%{transform:scale(1) translate3d(0,0,0);transform-origin:16% 84%}100%{transform:scale(1.25) translate3d(-20px,15px,0);transform-origin:left bottom}}
@keyframes kenburns-top{0%{transform:scale(1) translateY(0);transform-origin:50% 16%}100%{transform:scale(1.25) translateY(-15px);transform-origin:top}}
@keyframes scale-up-center{0%{transform:scale(.5) translate3d(var(--ob-translate-x), var(--ob-translate-y),0)}100%{transform:scale(1) translate3d(var(--ob-translate-x), var(--ob-translate-y),0)}}
@keyframes text-focus-in{0%{filter:blur(12px);opacity:0}100%{filter:blur(0);opacity:1}}
@keyframes tracking-in-expand-fwd-bottom{0%{letter-spacing:-.5em;transform:translateZ(-700px) translateY(500px);opacity:0}40%{opacity:.6}100%{transform:translateZ(0) translateY(0);opacity:1}}
@keyframes tracking-in-expand-fwd-top{0%{letter-spacing:-.5em;transform:translateZ(-700px) translateY(-500px);opacity:0}40%{opacity:.6}100%{transform:translateZ(0) translateY(0);opacity:1}}
@keyframes vibrate{0%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0)}20%,40%{transform:translate3d(calc(var(--ob-translate-x) - 2px),calc(var(--ob-translate-y) - 2px),0)}60%{transform:translate3d(calc(var(--ob-translate-x) + 2px),calc(var(--ob-translate-y) + 2px),0)}80%{transform:translate3d(calc(var(--ob-translate-x) + 2px),calc(var(--ob-translate-y) - 2px),0)}100%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0)}}
@keyframes wobble-hor-bottom{0%,50%{transform:translate3d(var(--ob-translate-x),var(--ob-translate-y),0);transform-origin:50% 50%}7.5%{transform:translate3d(calc(var(--ob-translate-x) - 30px),var(--ob-translate-y),0) rotate(-6deg)}15%{transform:translate3d(calc(var(--ob-translate-x) + 15px),var(--ob-translate-y),0) rotate(6deg)}22.5%{transform:translate3d(calc(var(--ob-translate-x) - 15px),var(--ob-translate-y),0) rotate(-3.6deg)}30%{transform:translate3d(calc(var(--ob-translate-x) + 9px),var(--ob-translate-y),0) rotate(2.4deg)}37.5%{transform:translate3d(calc(var(--ob-translate-x) - 6px),var(--ob-translate-y),0) rotate(-1.2deg)}}