

















































































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.product-wizard {
  // .product-wizard__container
  &__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;

    & > *,
    .product-details > * {
      width: 100%;
    }

    @include mq($screen-md, min) {
      align-items: flex-end;

      &.product-wizard__container__top {
        &:before {
          content: '';
          display: inline-block;
          margin-top: -$product-gallery-height;
          order: 1;
        }
      }

      & > *,
      .product-details > * {
        width: 50%;
      }
    }
  }

  // .product-wizard__color-selector
  &__color-selector {
    order: map-deep-get($product-order, 'color', 'xs');

    h3 {
      display: none;

      @include mq($screen-md, min) {
        display: block;
      }
    }

    ul {
      justify-content: center;

      @include mq($screen-md, min) {
        justify-content: flex-start;
      }
    }

    @include mq($screen-md, min) {
      order: map-deep-get($product-order, 'color', 'md');
    }
  }

  // .product-wizard__capacity-selector
  &__capacity-selector {
    order: map-deep-get($product-order, 'storage', 'xs');
  }

  // .product-wizard__payment-selector,
  &__payment-selector {
    // .product-wizard__payment-selector__description,
    &__description {
      font-size: $font-size-h5;
      margin: -$u-200 0 $u-300;

      a {
        color: $c-pm-500;
      }
    }
  }

  // .product-wizard__payment-selector,
  // .product-wizard__binding-selector
  &__payment-selector,
  &__binding-selector {
    order: map-deep-get($product-order, 'payment', 'xs');
  }

  // .product-wizard__insurance-selector
  &__insurance-selector {
    order: map-deep-get($product-order, 'insurance', 'xs');
    padding-top: $u-500;

    .cm-c-total__row--bottom-separator {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  // .product-wizard__price-plan-selector
  &__price-plan-selector {
    order: map-deep-get($product-order, 'subscription', 'xs');
  }

  // .product-wizard__configuration-footer
  &__configuration-footer {
    order: map-deep-get($product-order, 'configuration-footer', 'xs');

    &:not(:empty) {
      margin-top: $u-500;
    }
  }

  // .product-wizard__existing-subscriptions-button
  &__existing-subscriptions-button {
    text-align: right;
    margin-bottom: $u-300;
  }
}
