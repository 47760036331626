@mixin c-breadcrumb($theme) {
  display: block;
  background-color: map-deep-get($theme, default, background-color);

  a {
    color: map-deep-get($theme, default, link-color);
    font-size: 12px;
    text-decoration: underline;

    svg {
      display: inline-block;
      width: 14px;
      height: 14px;
      fill: map-deep-get($theme, default, link-color);
      position: relative;
      top: 3px;
    }
  }

  &__list {
    display: block;
    padding: 11px 0;
    margin: 0;
  }

  &__list-item {
    display: inline-block;
    padding: 0 35px 0 0;
    margin: 0;
    list-style-type: none;
    position: relative;
    color: map-deep-get($theme, default, link-color);
    font-size: 12px;

    &:last-child:after {
      display: none;
    }

    &:first-child {
      &:after {
        top: 8px;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-right: 1px solid map-deep-get($theme, default, link-color);
      border-bottom: 1px solid map-deep-get($theme, default, link-color);
      position: absolute;
      transform: rotate(-45deg);
      top: 4px;
      right: 12px;
    }
  }
}

.c-breadcrumb {
  @include c-breadcrumb($c-breadcrumb);
}
