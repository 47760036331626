




































































































































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.carousel {
  width: 100%;
  display: flex;
  flex-flow: column;

  &__header {
    text-align: center;
    margin-bottom: $u-500;
  }

  &__page-step {
    position: relative;

    &__skeleton-loader {
      width: 100%;
      height: 210px;
      margin-bottom: $u-800;
    }
  }

  &__mobile {
    display: flex;
    flex-flow: column;
    gap: $u-500;
    &__card {
      margin-bottom: $u-800;
    }
  }
}
#carousel .VueCarousel-navigation-next {
  transform: translateY(-55%) translateX(110%);
}
