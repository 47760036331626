// Checkout
// .cm-
#{$prefix} {
    // .cm-c-checkout
    &c-checkout {
        // .cm-c-checkout-summary
        &-summary {
            font-size: $global-font-size;
            margin-bottom: $u-400;

            &__section {
                font-size: $font-size-h5;

                &__delivery-group {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    @include mq($screen-sm, max) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    &__title {
                        align-self: flex-start;
                    }

                    &__address {
                        text-align: right;

                        line-height: $u-400;

                        li {
                            list-style-type: none;
                        }

                        @include mq($screen-sm, max) {
                            text-align: left;
                        }
                    }
                }

                &:not(:last-child) {
                    margin-bottom: $u-300;
                }
            }

            @include mq($screen-sm, max) {
                padding-top: 0;
                margin-bottom: 0;
            }
        }
        // .cm-c-checkout__error
        &__error {
          font-size: $font-size-h4;
          font-weight: 700;
          color: $c-sp-red-500;
        }
    }
}