


























































































































@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.c-tabs {
  &-nav > ul {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 0;
    list-style: none;
    flex-wrap: nowrap; // On mobile, make it seem like the content flows edge to edge while horizontal scrolling.
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }

    @include mq($screen-md, max) {
      overflow: auto;
      white-space: nowrap;
    }

    li {
      margin-right: 10px;

      @include mq($screen-md, min) {
        flex: 0 1 auto;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        a {
          color: black;
          border-bottom-color: black;
        }
      }
    }

    a {
      font-weight: 700;
      line-height: 2;
      flex: 0 1 50%;
      padding-bottom: 5px;
      border-bottom: 4px solid transparent;
      transition: color 0.2s ease, border-color 0.2s ease;
      will-change: color, border-color;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: gray;
      }
    }
  }

  &--vertical {
    display: flex;
    flex-wrap: wrap;

    & .c-tabs-nav > ul {
      flex-direction: column;
    }
  }
}
