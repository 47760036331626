













































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$inputHeight: $u-700 + $u-200;
$inputBorderWidth: 1px;

.address-search {
  position: relative;

  $root: &;

  // .address-search__card
  &__card {
    // .address-search__card--is-block
    &--is-block {
      background: $c-pm-800;
      border-radius: 3px;
      box-shadow: $box-shadow-default;
      color: $color-white;
      padding: $u-400 24px $u-500;
      text-align: center;

      @include mq($screen-md, min) {
        padding-left: 100px;
        padding-right: 100px;
      }

      // .address-search__card--is-block .address-search__description
      #{$root}__description {
        display: block;
        margin-bottom: $u-300;
      }
    }
  }

  // .address-search__description
  &__description {
    display: none;
  }

  // .address-search__group
  &__group {
    color: $c-nt-900;
    position: relative;
  }

  $vasImageSize: 24;
  $vasImageMargin: 2;
  $vasMinCount: 1;
  $vasMaxCount: 6;

  // .address-search__tongue
  &__tongue {
    background: $color-white;
    border-radius: 0 0 3px 3px;
    box-shadow: $box-shadow-default;
    margin: 0 8px;
    padding: $u-250 $u-300 $u-250;

    // .address-search__tongue-trigger,
    // .address-search__tongue-footer
    &-trigger,
    &-footer {
      position: relative;
      display: flex;
      align-items: center;
    }

    // .address-search__tongue-images
    &-images {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      min-width: #{($vasImageSize + $vasImageMargin) * $vasMinCount}px;
      max-width: #{($vasImageSize + $vasImageMargin) * $vasMaxCount}px;
      max-height: #{$vasImageSize}px;

      // .address-search__tongue-images > img
      & > img {
        height: #{$vasImageSize}px;
        width: #{$vasImageSize}px;
        max-width: 100%;
        height: auto;
        vertical-align: middle;

        &:not(:last-child) {
          margin-right: #{$vasImageMargin}px;
        }
      }
    }

    // .address-search__tongue-title
    &-title {
      flex: 1 0 0;
      margin: 0 1rem;
      white-space: nowrap;
    }

    // .address-search__tongue-icon
    &-icon {
      fill: $color-black;
      height: 6px;
      position: absolute;
      right: 0;
      top: 8px;
      transform: rotate(0);
      transition: transform 0.2s ease;
      width: 10px;
      will-change: transform;

      // .address-search__tongue-icon--expanded
      &--expanded {
        transform: rotate(180deg);
      }
    }

    // .address-search__tongue-content
    &-content {
      padding-top: 16px;
    }
  }
}
