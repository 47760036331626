















































































.c-form-group {
  position: relative;
  margin-bottom: 10px;

  // .c-form-group > label
  & > label {
    display: inline-block;
    margin-bottom: .5rem;
  }

  // .c-form-group__success
  &__success {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28a745;
  }

  // .c-form-group__error
  &__error {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
  }

  // .c-form-group--sr-only
  &--sr-only {
    & > label {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0,0,0,0);
      white-space: nowrap;
      border: 0;
    }
  }

  &--inline {
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex: 1 1 50%;
      width: 0;
    }

    & > label {
      line-height: 1.5;
      padding-top: calc(.375rem + 1px);
      padding-bottom: calc(.375rem + 1px);
      margin-bottom: 0;
      font-size: inherit;
    }
  }
}
