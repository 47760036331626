/**
 * oneblock-size.css
 * @version 1.0.11
 * @summary 16-02-2020
 * @author Mads Stoumann
 * @description OneBlock: Size (image, splash)
 */
 
/* Size
===============================
Prefix:
-A Additional headline
-E Content Box
-H Header
-I Image
-S Splash

Key:
-sz (Height)
-wd (Width)

Value:
1-9 (Image), 1-5 (Splash)
===============================
*/

/* Header && Additional headline */
.-A-wd1 .c-ob__additional-headline,
.-Dh-wd1 .c-ob__display-headline,
.-H-wd1 .c-ob__headline {
	width: 10%;
}
.-A-wd2 .c-ob__additional-headline,
.-Dh-wd2 .c-ob__display-headline,
.-H-wd2 .c-ob__headline {
	width: 20%;
}
.-A-wd3 .c-ob__additional-headline,
.-Dh-wd3 .c-ob__display-headline,
.-H-wd3 .c-ob__headline {
	width: 30%;
}
.-A-wd4 .c-ob__additional-headline,
.-Dh-wd4 .c-ob__display-headline,
.-H-wd4 .c-ob__headline {
	width: 40%;
}
.-A-wd5 .c-ob__additional-headline,
.-Dh-wd5 .c-ob__display-headline,
.-H-wd5 .c-ob__headline {
	width: 50%;
}
.-A-wd6 .c-ob__additional-headline,
.-Dh-wd6 .c-ob__display-headline,
.-H-wd6 .c-ob__headline {
	width: 60%;
}
.-A-wd7 .c-ob__additional-headline,
.-Dh-wd7 .c-ob__display-headline,
.-H-wd7 .c-ob__headline {
	width: 70%;
}
.-A-wd8 .c-ob__additional-headline,
.-Dh-wd8 .c-ob__display-headline,
.-H-wd8 .c-ob__headline {
	width: 80%;
}
.-A-wd9 .c-ob__additional-headline,
.-Dh-wd9 .c-ob__display-headline,
.-H-wd9 .c-ob__headline {
	width: 90%;
}

/* Text */
.-T-wd1 .c-ob__text {
  width: 10%;
}

.-T-wd2 .c-ob__text {
  width: 20%;
}

.-T-wd3 .c-ob__text{
  width: 30%;
}

.-T-wd4 .c-ob__text {
  width: 40%;
}

.-T-wd5 .c-ob__text {
  width: 50%;
}

.-T-wd6 .c-ob__text {
  width: 60%;
}

.-T-wd7 .c-ob__text {
  width: 70%;
}

.-T-wd8 .c-ob__text {
  width: 80%;
}

.-T-wd9 .c-ob__text {
  width: 90%;
}

/* Image Size (Height), default 100% */
.-I-sz0 .c-ob__img {
	max-height: none;
}
.-I-sz1 .c-ob__img {
	max-height: 10%;
}
.-I-sz2 .c-ob__img {
	max-height: 20%;
}
.-I-sz3 .c-ob__img {
	max-height: 30%;
}
.-I-sz4 .c-ob__img {
	max-height: 40%;
}
.-I-sz5 .c-ob__img {
	max-height: 50%;
}
.-I-sz6 .c-ob__img {
	max-height: 60%;
}
.-I-sz7 .c-ob__img {
	max-height: 70%;
}
.-I-sz8 .c-ob__img {
	max-height: 80%;
}
.-I-sz9 .c-ob__img {
	max-height: 90%;
}

/*  Image Size (Width / Max-width), default 100% */
.-I-wd1 .c-ob__img {
	max-width: 10%;
}
.-I-wd2 .c-ob__img {
	max-width: 20%;
}
.-I-wd3 .c-ob__img {
	max-width: 30%;
}
.-I-wd4 .c-ob__img {
	max-width: 40%;
}
.-I-wd5 .c-ob__img {
	max-width: 50%;
}
.-I-wd6 .c-ob__img {
	max-width: 60%;
}
.-I-wd7 .c-ob__img {
	max-width: 70%;
}
.-I-wd8 .c-ob__img {
	max-width: 80%;
}
.-I-wd9 .c-ob__img {
	max-width: 90%;
}

/* Content Box Width */
.-E-wd1 .c-ob__content {
	width: calc(10% - var(--ob-distance-lr));
}
.-E-wd2 .c-ob__content {
	width: calc(20% - var(--ob-distance-lr));
}
.-E-wd3 .c-ob__content {
	width: calc(30% - var(--ob-distance-lr));
}
.-E-wd4 .c-ob__content {
	width: calc(40% - var(--ob-distance-lr));
}
.-E-wd5 .c-ob__content {
	width: calc(50% - var(--ob-distance-lr));
}
.-E-wd6 .c-ob__content {
	width: calc(60% - var(--ob-distance-lr));
}
.-E-wd7 .c-ob__content {
	width: calc(70% - var(--ob-distance-lr));
}
.-E-wd8 .c-ob__content {
	width: calc(80% - var(--ob-distance-lr));
}
.-E-wd9 .c-ob__content {
	width: calc(90% - var(--ob-distance-lr));
}
.-E-wd10 .c-ob__content {
	width: calc(100% - var(--ob-distance-lr));
}
/* Content Box Height */
.-E-ht1 .c-ob__content {
	height: calc(10% - var(--ob-distance-tb));
}
.-E-ht2 .c-ob__content {
	height: calc(20% - var(--ob-distance-tb));
}
.-E-ht3 .c-ob__content {
	height: calc(30% - var(--ob-distance-tb));
}
.-E-ht4 .c-ob__content {
	height: calc(40% - var(--ob-distance-tb));
}
.-E-ht5 .c-ob__content {
	height: calc(50% - var(--ob-distance-tb));
}
.-E-ht6 .c-ob__content {
	height: calc(60% - var(--ob-distance-tb));
}
.-E-ht7 .c-ob__content {
	height: calc(70% - var(--ob-distance-tb));
}
.-E-ht8 .c-ob__content {
	height: calc(80% - var(--ob-distance-tb));
}
.-E-ht9 .c-ob__content {
	height: calc(90% - var(--ob-distance-tb));
}
.-E-ht10 .c-ob__content {
	height: calc(100% - var(--ob-distance-tb));
}

/* Content Box Width: Layout 2 + 3 (Image Left/Right) */
.c-ob.-O-ly2.-E-wd1 .c-ob__content,
.c-ob.-O-ly3.-E-wd1 .c-ob__content {
  flex: 1 0 10%;
	max-width: 10%;
}
.c-ob.-O-ly2.-E-wd1 .c-ob__bg-wrapper,
.c-ob.-O-ly3.-E-wd1 .c-ob__bg-wrapper {
  flex: 1 0 90%;
	max-width: 90%;
}

.c-ob.-O-ly2.-E-wd2 .c-ob__content,
.c-ob.-O-ly3.-E-wd2 .c-ob__content {
  flex: 1 0 20%;
	max-width: 20%;
}
.c-ob.-O-ly2.-E-wd2 .c-ob__bg-wrapper,
.c-ob.-O-ly3.-E-wd2 .c-ob__bg-wrapper {
  flex: 1 0 80%;
	max-width: 80%;
}

.c-ob.-O-ly2.-E-wd3 .c-ob__content,
.c-ob.-O-ly3.-E-wd3 .c-ob__content {
  flex: 1 0 30%;
	max-width: 30%;
}
.c-ob.-O-ly2.-E-wd3 .c-ob__bg-wrapper,
.c-ob.-O-ly3.-E-wd3 .c-ob__bg-wrapper {
  flex: 1 0 70%;
	max-width: 70%;
}

.c-ob.-O-ly2.-E-wd4 .c-ob__content,
.c-ob.-O-ly3.-E-wd4 .c-ob__content {
  flex: 1 0 40%;
	max-width: 40%;
}
.c-ob.-O-ly2.-E-wd4 .c-ob__bg-wrapper,
.c-ob.-O-ly3.-E-wd4 .c-ob__bg-wrapper {
  flex: 1 0 60%;
	max-width: 60%;
}

.c-ob.-O-ly2.-E-wd6 .c-ob__content,
.c-ob.-O-ly3.-E-wd6 .c-ob__content {
  flex: 1 0 60%;
	max-width: 60%;
}
.c-ob.-O-ly2.-E-wd6 .c-ob__bg-wrapper,
.c-ob.-O-ly3.-E-wd6 .c-ob__bg-wrapper {
  flex: 1 0 40%;
	max-width: 40%;
}

.c-ob.-O-ly2.-E-wd7 .c-ob__content,
.c-ob.-O-ly3.-E-wd7 .c-ob__content {
  flex: 1 0 70%;
	max-width: 70%;
}
.c-ob.-O-ly2.-E-wd7 .c-ob__bg-wrapper,
.c-ob.-O-ly3.-E-wd7 .c-ob__bg-wrapper {
  flex: 1 0 30%;
	max-width: 30%;
}

.c-ob.-O-ly2.-E-wd8 .c-ob__content,
.c-ob.-O-ly3.-E-wd8 .c-ob__content {
  flex: 1 0 80%;
	max-width: 80%;
}
.c-ob.-O-ly2.-E-wd8 .c-ob__bg-wrapper,
.c-ob.-O-ly3.-E-wd8 .c-ob__bg-wrapper {
  flex: 1 0 20%;
	max-width: 20%;
}

.c-ob.-O-ly2.-E-wd9 .c-ob__content,
.c-ob.-O-ly3.-E-wd9 .c-ob__content {
  flex: 1 0 90%;
	max-width: 90%;
}
.c-ob.-O-ly2.-E-wd9 .c-ob__bg-wrapper,
.c-ob.-O-ly3.-E-wd9 .c-ob__bg-wrapper {
  flex: 1 0 10%;
	max-width: 10%;
}

/* Splash Size */
.-S-sz1 .c-ob__splash {
	width: var(--ob-S-sz1);
}
.-S-sz2 .c-ob__splash {
	width: var(--ob-S-sz2);
}
.-S-sz3 .c-ob__splash {
	width: var(--ob-S-sz3);
}
.-S-sz4 .c-ob__splash {
	width: var(--ob-S-sz4);
}
.-S-sz5 .c-ob__splash {
	width: var(--ob-S-sz5);
}

/* Auto-Expand */
.-T-ax1 .c-ob__text {
	flex: unset;
}