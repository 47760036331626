// sass-lint:disable-all
$build: false !default;

$fonts-path: '/src/apps/webshop/telia/fonts';
$icomoon-font-path: '/src/apps/webshop/telia/fonts/icons' !default;
$images-path: '/src/apps/webshop/telia/images';
$svg-path-shared: '/src/shared/svg';

@if $build == true {
  $fonts-path: '/dist/fonts';
}

/* Regular */
@font-face {
  font-family: 'TeliaSans';
  src: local('Telia Sans Regular'), local('TeliaSans-Regular'),
    url('#{$fonts-path}/TeliaSans-Regular.woff2') format('woff2'),
    url('#{$fonts-path}/TeliaSans-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

/* Regular - Italic */
@font-face {
  font-family: 'TeliaSans';
  src: local('Telia Sans RegularItalic'), local('TeliaSans-RegularItalic'),
    url('#{$fonts-path}/TeliaSans-RegularItalic.woff2') format('woff2'),
    url('#{$fonts-path}/TeliaSans-RegularItalic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

/* Medium / Semibold */
@font-face {
  font-family: 'TeliaSans';
  src: local('Telia Sans Medium'), local('TeliaSans-Medium'),
    url('#{$fonts-path}/TeliaSans-Medium.woff2') format('woff2'),
    url('#{$fonts-path}/TeliaSans-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

/* Medium - Italic */
@font-face {
  font-family: 'TeliaSans';
  src: local('Telia Sans MediumItalic'), local('TeliaSans-MediumItalic'),
    url('#{$fonts-path}/TeliaSans-MediumItalic.woff2') format('woff2'),
    url('#{$fonts-path}/TeliaSans-MediumItalic.woff') format('woff');
  font-style: italic;
  font-weight: 500;
}

/* Bold */
@font-face {
  font-family: 'TeliaSans';
  src: local('Telia Sans Bold'), local('TeliaSans-Bold'),
    url('#{$fonts-path}/TeliaSans-Bold.woff2') format('woff2'),
    url('#{$fonts-path}/TeliaSans-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

/* Bold - Italic */
@font-face {
  font-family: 'TeliaSans';
  src: local('Telia Sans BoldItalic'), local('TeliaSans-BoldItalic'),
    url('#{$fonts-path}/TeliaSans-BoldItalic.woff2') format('woff2'),
    url('#{$fonts-path}/TeliaSans-BoldItalic.woff') format('woff');
  font-style: italic;
  font-weight: 700;
}

// Telia Sans Heading
@font-face {
  font-family: 'Telia Sans Heading';
  src: local('Telia Sans Heading'), local('TeliaSansHeading-Heading'),
    url('#{$fonts-path}/TeliaSansHeading-Heading.woff2') format('woff2'),
    url('#{$fonts-path}/TeliaSansHeading-Heading.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Title
$font-size-display-100: 64px;
$font-size-h1: 36px !default;
// TODO DKT-COMING_SOON: These font-sizes should be changed to the new sizes described in figma. But it's too risky right before the release.
$font-size-h2: 24px !default;
$font-size-h3: 20px !default;
$font-size-h4: 16px !default;
$font-size-h5: 14px !default;
$font-size-h6: 12px !default;

// Other Font-Sizes
$font-size-xxs: 9px !default;
$font-size-xs: 10px;
$font-size-s: 28px;
$font-size-m: 30px;
$font-size-l: 48px;
$font-size-xl: 60px;
$font-size-xxl: 72px;

// Global settings
$global-font-family-xl: 'Telia Sans Heading', Arial, sans-serif !default;
$global-font-family: 'TeliaSans', Arial, sans-serif !default;
$global-font-family-2: 'TeliaSans', Arial, sans-serif !default;
$global-font-family-3: 'TeliaSans', Arial, sans-serif !default;

$global-font-size: 16px !default;
$global-font-size-md: 14px !default;
$global-font-size-sm: 12px !default;
$global-font-size-xs: 10px !default;

$global-line-height-xs: 20px !default;
$global-line-height: 1.375 !default;
$global-line-height-sm: 1.5 !default;
$title-line-height: 1.375 !default;
$global-text-color: #444 !default;

// Project prefix
$prefix: '.cm-';
// sass-lint:enable-all
