@import './breakpoints.scss';

// Media query
// Usage: @include mq($screen-sm, max) {}
@mixin mq($point, $query1: min, $query2: width) {
	@media screen and (#{$query1}-#{$query2}: $point) {
		@content;
	}
}

// Till Phablet
// Usage: @include till-phablet() {}
@mixin till-phablet() {
  @include mq($breakpoint-phablet, max) {
    @content;
  }
}

// From Phablet
// Usage: @include from-phablet() {}
@mixin from-phablet() {
  @include mq($breakpoint-phablet, min) {
    @content;
  }
}

// From Tablet
// Usage: @include from-tablet() {}
@mixin from-tablet() {
  @include mq($breakpoint-tablet, min) {
    @content;
  }
}

// todo: add more on the way.