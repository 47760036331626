



















































// Use this to prevent deprecation warning
@function safe-unquote($param) {
  @return if(type-of($param) == string, unquote($param), $param);
}

$fontSize: 100;
$increment: 15; // Font size should increment by 15% for each letter

.shouting-font {
  font-style: italic;
  font-weight: 900;

  &__letter {
    // It is unlikely that we will need more than 20 characters
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        font-size: #{$fontSize}#{'%'};
      }
      $fontSize: safe-unquote($fontSize) + safe-unquote($increment);
    }
  }
}
