/**
 * oneblock-borderradius.css
 * @version 1.0.00
 * @summary 15-10-2019
 * @author Mads Stoumann
 * @description OneBlock: Hover Effect
*/
/*
===============================
Prefix:
-O OneBlock

Key:
-br

Value:
1-?
===============================
*/

.-O-br1 {
  --ob-bdrs: var(--ob-O-br1);
}
.-O-br2 {
  --ob-bdrs: var(--ob-O-br2);
}
.-O-br3 {
  --ob-bdrs: var(--ob-O-br3);
}
.-O-br4 {
  --ob-bdrs: var(--ob-O-br4);
}

.-O-br1 .c-ob__ribbon {
  --ob-R-bdrs: var(--ob-R-br1);
}
.-O-br2 .c-ob__ribbon {
  --ob-R-bdrs: var(--ob-R-br2);
}

[class*='-O-br'],
[class*='-O-br'] .c-ob__bg-wrapper,
[class*='-O-br'] .c-ob__content {
  border-radius: var(--ob-bdrs);
}
.c-ob:not([class*='-O-ly'])[class*='-O-br'] .c-ob__bg-wrapper,
.-O-ly1 .c-ob__content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.c-ob:not([class*='-O-ly'])[class*='-O-br'] .c-ob__content,
.-O-ly1 .c-ob__bg-wrapper,
.-O-ly8 .c-ob__content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.-O-ly2 .c-ob__bg-wrapper,
.-O-ly3 .c-ob__content {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.-O-ly2 .c-ob__content,
.-O-ly3 .c-ob__bg-wrapper {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.-O-ly4 .c-ob__content {
  border-radius: 0;
}
