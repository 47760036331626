





















































































@import 'olympus/styles/tools/_tools.media-query.scss';
@import 'olympus/styles/breakpoints.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.checkout-receipt {
  // .checkout-receipt__shipping
  &__shipping {
    // .checkout-receipt__shipping--methods
    &--methods {
      position: relative;

      // .checkout-receipt__shipping--methods label
      & label {
        display: block;
      }
    }

    // .checkout-receipt__shipping--description
    &--description {
      font-size: $global-font-size-sm;
    }
  }
}
