



























































































































@import 'theme/sass/settings/_settings.vars.scss';

.c-wrapper {
  margin: $u-400 auto;

  > div {
    flex-grow: 1;
  }
}
