

















































































































@import 'theme/sass/settings/_settings.vars.scss';

$contentMaxWidth: 384px; // TODO DKT-1415: this should be aligned with the design team and ev. made a global design token instead of a magic number.

.c-vas-multiple-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;

  // .c-vas-multiple-overlay__content
  &__content {
    margin-top: $u-500;
    max-width: $contentMaxWidth;
  }
}
