


























































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$duration: 0.3s;
$timing: cubic-bezier(0.23, 1, 0.32, 1);
$cell-border-radius: 999px;

@import '~vue2-datepicker/scss/index.scss';

@mixin typographyDatepicker() {
  font-family: $global-font-family;
  font-size: $global-font-size;
}

.mx-datepicker {
  max-width: 100%;
  width: 100%;

  .mx-icon-calendar {
    fill: $c-pm-500;
    right: $u-250;
  }

  .icon-calendar {
    fill: $c-pm-500;
    height: $u-400;
    width: $u-400;
  }
  .icon-clear {
    fill: $c-nt-600;
    height: $u-400;
    width: $u-400;
  }

  @include mq($screen-sm, max) {
    .mx-input-wrapper:hover {
      .mx-icon-clear {
        display: none;
        + .mx-icon-calendar {
          display: block;
        }
      }
    }
  }
}

.mx-datepicker-popup {
  box-sizing: border-box;

  &-left-bottom {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
}

.mx-datepicker-main {
  border-radius: 3px;
  box-shadow: $box-shadow-hover;
  max-width: 100%;
  width: 384px;
  padding: $u-400;
  color: $color-black;

  .mx-calendar {
    width: 100%;
    padding: 0;
    + .mx-calendar {
      display: none !important;
    }
  }

  .mx-calendar-content {
    height: auto;
  }

  .mx-calendar-panel-month,
  .mx-calendar-panel-year {
    .cell {
      background: none;
      text-transform: capitalize;

      @include typographyDatepicker();

      > div {
        align-items: center;
        border-radius: $cell-border-radius;
        display: flex;
        justify-content: center;
        min-height: $u-500;
        padding: 0 $u-300;
        &:hover {
          background-color: $c-pm-200;
        }
      }

      &.active > div {
        background-color: $c-pm-500;
      }
    }
  }

  .mx-calendar-panel-date {
    flex-direction: row;
    justify-content: center;

    .cell {
      background: transparent;

      height: 48px;
      @include mq($screen-tiny, max) {
        height: 38px;
      }

      position: relative;

      > div {
        position: relative;
        z-index: 3;
      }

      &.disabled {
        background-color: $c-nt-100;
        &.not-current-month {
          background-color: $c-nt-100;
        }
      }

      &.today:not(.active) {
        position: relative;
        &::before,
        &::after {
          content: '';
          height: 100%;
          position: absolute;
          top: 0;
        }
        &::after {
          background-color: $c-pm-200;
          border-radius: $cell-border-radius;
          left: 0;
          width: 100%;
          z-index: 2;
        }
        > div {
          position: relative;
          z-index: 3;
        }
      }

      &:not(.disabled) {
        &.today:not(.active) {
          color: $color-black;
        }
        &:not(.active):hover {
          color: $color-black;
          position: relative;
          &::after {
            background-color: $c-pm-200;
            border-radius: $cell-border-radius;
            left: 0;
            width: 100%;
          }
        }
      }

      &::before,
      &::after {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
      }

      &::before {
        z-index: 1;
      }

      &::after {
        z-index: 2;
      }

      &.in-range,
      &.hover-in-range {
        background: $c-pm-100;
        border-radius: 0;
        + .active::before {
          left: 0;
          right: auto;
          width: 50%;
        }
      }

      &:hover + .hover-in-range {
        box-shadow: -16px 0 0 $c-pm-100;
      }

      &.hover-in-range + :hover {
        background: transparent;
        position: relative;
        &::before {
          background-color: $c-pm-100;
          left: 0;
          width: 50%;
        }
        &::after {
          background-color: $c-pm-200;
          border-radius: $cell-border-radius;
          left: 0;
          width: 100%;
        }
      }

      &.active {
        &::before {
          background-color: $c-pm-100;
          right: 0;
        }
        &::after {
          background-color: $c-pm-500;
          border-radius: $cell-border-radius;
          left: 0;
          width: 100%;
        }

        + .in-range,
        + .hover-in-range {
          box-shadow: -20px 0 0 $c-pm-100;
        }
      }
    }
  }

  .mx-btn {
    color: $c-pm-500;
  }

  .mx-btn-current-month,
  .mx-btn-current-year {
    text-transform: capitalize;
  }

  .mx-calendar-header-label .mx-btn {
    @include typographyDatepicker();
    font-weight: bold;
  }

  .mx-table-date th {
    @include typographyDatepicker();

    text-transform: capitalize;
  }

  .mx-table-date thead th {
    @include typographyDatepicker();
    font-weight: bold;
  }

  .mx-table-date td {
    @include typographyDatepicker();

    height: $u-600;
  }

  &.mx--disable-month-and-years {
    .mx-btn-current-month,
    .mx-btn-current-year {
      color: $color-black;
      pointer-events: none;
    }
  }
}

.mx-zoom-in-down-enter-active,
.mx-zoom-in-down-leave-active {
  opacity: 1;
  transform: translateY(0);
  transform-origin: center top;
  transition: transform $duration $timing, opacity $duration $timing;
}

.mx-zoom-in-down-enter,
.mx-zoom-in-down-leave-to {
  opacity: 0;
  transform: translateY(-$u-300);
}
