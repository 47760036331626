@import url('./oneblock-animations.css');
@import url('./oneblock-aspect.css');
@import url('./oneblock-borderradius.css');
@import url('./oneblock-boxshadow.css');
@import url('./oneblock-color.css');
@import url('./oneblock-distance.css');
@import url('./oneblock-hide.css');
@import url('./oneblock-hover.css');
@import url('./oneblock-layout.css');
@import url('./oneblock-margin.css');
@import url('./oneblock-opacity.css');
@import url('./oneblock-padding.css');
@import url('./oneblock-position.css');
@import url('./oneblock-shapes.css');
@import url('./oneblock-size.css');
@import url('./oneblock-transition.css');
@import url('./oneblock-typography.css');

/**
 * oneblock.css
 * @version 1.2.40
 * @summary 23-09-2020
 * @author Mads Stoumann
 * @description OneBlock default styles / Main CSS file
 */

/* Prefixes for modifier classes
=================================
-A Additional headline
-B Background Image/Video
-C Image Caption
-E Content Wrapper
-H Headline
-I Image
-K Secondary Link
-L Primary Link
-M Meta
-N Tagline
-O OneBlock
-R Ribbon
-S Splash
-T Text
-W Background Image/Video Wrapper
-X Section / Wrapper
*/

.c-ob {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  font-size: var(--ob-O-fz0);
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;
}

.c-article__body > .c-ob,
.c-article__body > .c-ob > .c-ob__bg-wrapper {
  height: auto;
}

.c-ob__bg {
  border-radius: inherit;
  grid-area: bg;
}

.c-ob__bg,
.c-ob__bg-play,
.c-ob__bg-wrapper__bg {
  background-color: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  mix-blend-mode: normal;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.c-ob__bg-wrapper__bg {
  border-radius: inherit;
}

.c-ob__bg-play {
  cursor: pointer;
}

.c-ob__bg-play svg {
  transition: transform 0.3s ease-in-out;
}

.c-ob__bg-play * {
  pointer-events: none;
}

.c-ob__bg-play svg:hover {
  transform-origin: 50% 50%;
  transform: scale(1.05) translate(-50%, -50%);
}

.c-ob__bg-caption {
  background: var(--ob-C-bg0);
  color: var(--ob-C-c0);
  font-family: var(--ob-C-ff0);
  font-size: var(--ob-C-fz0);
  font-weight: var(--ob-C-fw0);
  grid-area: caption;
  padding: var(--ob-C-pt0) var(--ob-C-pl0);
  position: absolute;
  top: var(--ob-distance-tb);
}

.c-ob__bg-caption div {
  display: inline;
}

.c-ob:not([class*='-C-ps']) .c-ob__bg-caption {
  left: var(--ob-distance-lr);
}

[dir='rtl'] .c-ob:not([class*='-C-ps']) .c-ob__bg-caption {
  left: auto;
  right: var(--ob-distance-lr);
}

/* Video Play Overlay Transitions */
.c-ob__bg-caption,
.c-ob__bg-play,
.c-ob__bg-poster,
.c-ob__img,
.c-ob__splash {
  opacity: 1;
  transition: opacity 0.66s ease-out;
}

.c-ob__bg-video .c-ob__bg-caption,
.c-ob__bg-video .c-ob__bg-play,
.c-ob__bg-video .c-ob__bg-poster,
.c-ob__bg-video .c-ob__bg[style],
.c-ob__bg-video .c-ob__img,
.c-ob__bg-video .c-ob__splash {
  animation: none;
  opacity: 0 !important;
  pointer-events: none;
}

.c-ob__bg-wrapper {
  display: flex;
  grid-area: media;
  margin: 0;
  /* overflow: hidden; */
  padding: 0;
  position: relative;
}

/* Supporting safari, fixing stretched images */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  figure.c-ob__bg-wrapper {
    display: inline;
  }
}

.c-ob__bg-wrapper::after {
  content: '';
  display: block;
  padding-bottom: 56.25%;
  /* Default Aspect-Ratio 16:9 */
}

.c-ob__content {
  align-items: flex-start;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  grid-area: content;
  padding: var(--ob-E-pt0) var(--ob-E-pr0) var(--ob-E-pb0) var(--ob-E-pl0);
  position: relative;
  width: 100%;
}

.c-ob__headline {
  font-family: var(--ob-H-ff0);
  font-size: var(--ob-H-fz0);
  font-weight: var(--ob-H-fw0);
  grid-area: headline;
  line-height: var(--ob-H-lh0);
  margin: 0 0 var(--ob-H-mb0) 0;
  max-width: 100%;
  text-transform: var(--ob-H-tt0);
  color: var(--ob-H-c0);
}

.c-ob__additional-headline {
  font-family: var(--ob-A-ff0);
  font-size: var(--ob-A-fz0);
  font-weight: var(--ob-A-fw0);
  grid-area: additional_headline;
  line-height: var(--ob-A-lh0);
  margin: 0 0 var(--ob-A-mb0) 0;
  max-width: 100%;
  text-transform: var(--ob-A-tt0);
  color: var(--ob-A-c0);
}

.c-ob__display-headline {
  font-family: var(--ob-Dh-ff0);
  font-size: var(--ob-Dh-fz0);
  font-weight: var(--ob-Dh-fw0);
  grid-area: headline;
  line-height: var(--ob-Dh-lh0);
  margin: 0 0 var(--ob-Dh-mb0) 0;
  max-width: 100%;
  text-transform: var(--ob-Dh-tt0);
  color: var(--ob-Dh-cl0);
}

.c-ob__img {
  grid-area: image;
  left: var(--ob-distance-lr);
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: var(--ob-distance-tb);
}

.c-ob__link {
  /* Hide clickable link, show for screen-readers */
  bottom: 0;
  display: block;
  grid-area: link-pri;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-indent: 200%;
  top: 0;
  visibility: visible;
  white-space: nowrap;
}

.c-ob__link-text,
.c-ob__link-text-sec {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: var(--ob-L-ff0);
  font-size: var(--ob-L-fz0);
  font-weight: var(--ob-L-fw0);
  line-height: var(--ob-L-lh0);
  text-align: center;
  text-decoration: underline;
  margin: calc(var(--ob-margin-unit) / 2);
}

.c-ob__link-text-sec {
  grid-area: link-sec;
}

.c-ob__link-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--ob-margin-unit) / -2);
  grid-area: link;
}

.c-ob__meta {
  align-items: center;
  display: flex;
  font-family: var(--ob-M-ff0);
  font-size: var(--ob-M-fz0);
  font-weight: var(--ob-M-fw0);
  grid-area: meta;
  line-height: var(--ob-M-lh0);
  margin-bottom: var(--ob-M-mb0);
  position: relative;
  text-transform: var(--ob-M-tt0);
}

.c-ob__meta-icon {
  fill: currentColor;
  height: var(--ob-M-sz);
  margin-inline-end: var(--ob-M-icon-mie);
  pointer-events: none;
  width: var(--ob-M-sz);
}

.c-ob__meta-item {
  align-items: center;
  color: inherit;
  display: inline-flex;
  margin-inline-end: var(--ob-M-item-mie);
  text-decoration: none;
}

.c-ob__meta-item:hover {
  text-decoration: underline;
}

.c-ob__offer {
  grid-area: offer;
  margin: var(--ob-P-m0);
}

.c-ob__offer-base {
  font-family: var(--ob-P-base-ff0);
  font-size: var(--ob-P-base-fz0);
  font-weight: var(--ob-P-base-fw0);
  text-decoration: var(--ob-P-base-td0);
}

.c-ob__offer-currency {
  font-size: var(--ob-P-curr-fz0);
  font-weight: var(--ob-P-curr-fw0);
}

.c-ob__offer-price {
  color: inherit;
  font-family: var(--ob-P-price-ff0);
  font-size: var(--ob-P-price-fz0);
  font-weight: var(--ob-P-price-fw0);
  line-height: var(--ob-P-lh0);
}

.c-ob__offer-text {
  color: inherit;
  font-family: var(--ob-P-text-ff0);
  font-size: var(--ob-P-text-fz0);
  font-weight: var(--ob-P-text-fw0);
  line-height: 1.3;
}

.c-ob__offer-price-label {
  font-family: var(--ob-P-label-ff0);
  font-size: var(--ob-P-label-fz0);
  font-weight: var(--ob-P-label-fw0);
  line-height: var(--ob-P-lh0);
}

.c-ob__ribbon {
  background-color: var(--ob-R-bg0);
  border-radius: var(--ob-R-bdrs);
  color: var(--ob-R-c0);
  font-family: var(--ob-C-ff0);
  font-size: var(--ob-R-fz0);
  font-weight: var(--ob-R-fw0);
  margin: var(--ob-R-m0);
  padding: var(--ob-R-p0);
  font-variant-numeric: tabular-nums;
  position: absolute;
  top: 0;
  left: 0;
  font-style: var(--ob-R-fs0);
}

.c-ob__ribbon div {
  display: inline;
}

.c-ob__splash {
  color: var(--ob-S-c0);
  fill: var(--ob-S-bg0);
  grid-area: splash;
  overflow: hidden;
  position: absolute;
  width: var(--ob-S-sz0);
  z-index: var(--ob-zindex);
}

.c-ob__splash::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.c-ob__splash svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.c-ob__splash text {
  fill: currentColor;
  stroke: none;
}

.c-ob__splash-line-1 {
  font-family: var(--ob-S1-ff0);
  font-size: var(--ob-S1-fz0);
  font-weight: var(--ob-S1-fw0);
}

.c-ob__splash-line-2 {
  font-family: var(--ob-S2-ff0);
  font-size: var(--ob-S2-fz0);
  font-weight: var(--ob-S2-fw0);
}

.c-ob__splash-line-3 {
  font-family: var(--ob-S3-ff0);
  font-size: var(--ob-S3-fz0);
  font-weight: var(--ob-S3-fw0);
}

.c-ob__tagline {
  display: block;
  font-family: var(--ob-N-ff0);
  font-size: var(--ob-N-fz0);
  font-weight: var(--ob-N-fw0);
  grid-area: tagline;
  letter-spacing: var(--ob-N-lts0);
  line-height: var(--ob-N-lh0);
  margin: 0 0 var(--ob-N-mb0) 0;
  text-transform: var(--ob-N-tt0);
}

.c-ob__text {
  flex: 1 0 auto;
  font-family: var(--ob-T-ff0);
  font-size: var(--ob-T-fz0);
  grid-area: text;
  line-height: var(--ob-T-lh0);
  max-width: 100%;
  word-wrap: break-word;
}

.c-ob__text p {
  font-size: inherit;
}

.c-ob__text a {
  text-decoration: underline;
}

.c-ob__bg-caption-countdown .c-cnt__headline,
.c-ob__ribbon-countdown .c-cnt__headline {
  display: none;
}

/* HACK for iframes in Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .c-ob__bg-poster {
      top: -44px;
    }
  }
}
