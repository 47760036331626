/**
 * oneblock-transition.css
 * @version 1.0.00
 * @summary 30-09-2019
 * @author Mads Stoumann
 * @description OneBlock: Transitions for animations
 */

/* Transitions / Animations
===============================
Prefix:
-C Image Caption
-H Headline
-O OneBlock
-I Image
-L Link Text
-S Splash
-W Background Media Wrapper

Key:
-ts

Value:
1-9
===============================
*/
/* Hide overflow while animating */
.c-ob[class*='-S-ts'],
.c-ob[class*='-W-ts'] {
	overflow: hidden;
}

/* Entrances */
.-O-ts1,
.-I-ts1 .c-ob__img,
.-S-ts1 .c-ob__splash {
	animation: fade-in-top .6s cubic-bezier(.39,.575,.565,1.000) both;
}
.-O-ts2,
.-I-ts2 .c-ob__img,
.-S-ts2 .c-ob__splash {
	animation: fade-in-right .6s cubic-bezier(.39,.575,.565,1.000) both;
}
.-O-ts3,
.-I-ts3 .c-ob__img,
.-S-ts3 .c-ob__splash {
	animation: fade-in-bottom .6s cubic-bezier(.39,.575,.565,1.000) both;
}
.-O-ts4,
.-I-ts4 .c-ob__img,
.-S-ts4 .c-ob__splash {
	animation: fade-in-bl .6s cubic-bezier(.39,.575,.565,1.000) both;
}
.-O-ts5,
.-I-ts5 .c-ob__img,
.-S-ts5 .c-ob__splash {
	animation: fade-in-br .6s cubic-bezier(.39,.575,.565,1.000) both;
}
.-O-ts6,
.-I-ts6 .c-ob__img,
.-S-ts6 .c-ob__splash {
	animation: fade-in-left .6s cubic-bezier(.39,.575,.565,1.000) both;
}
.-O-ts7,
.-I-ts7 .c-ob__img,
.-S-ts7 .c-ob__splash {
	animation: bounce-in-top 1.1s both;
}
.-O-ts8,
.-I-ts8 .c-ob__img,
.-S-ts8 .c-ob__splash {
	animation: bounce-in-right 1.1s both;
}
.-O-ts9,
.-I-ts9 .c-ob__img,
.-S-ts9 .c-ob__splash {
	animation: bounce-in-bottom 1.1s both;
}
.-O-ts10,
.-I-ts10 .c-ob__img,
.-S-ts10 .c-ob__splash {
	animation: bounce-in-left 1.1s both;
}
.-O-ts11,
.-I-ts11 .c-ob__img,
.-S-ts11 .c-ob__splash {
	animation: scale-up-center .4s cubic-bezier(.39,.575,.565,1.000) both;
}

/* Text Effects */
.-C-ts12 .c-ob__bg-caption,
.-A-ts12 .c-ob__additional-headline,
.-H-ts12 .c-ob__headline {
	animation: text-focus-in 1s cubic-bezier(.55,.085,.68,.53) both;
}
.-C-ts13 .c-ob__bg-caption,
.-A-ts13 .c-ob__additional-headline,
.-H-ts13 .c-ob__headline {
	animation: focus-in-expand .8s cubic-bezier(.25,.46,.45,.94) both;
}
.-C-ts14 .c-ob__bg-caption,
.-A-ts14 .c-ob__additional-headline,
.-H-ts14 .c-ob__headline {
	animation: tracking-in-expand-fwd-top .8s cubic-bezier(.215,.61,.355,1.000) both;
}
.-C-ts15 .c-ob__bg-caption,
.-A-ts15 .c-ob__additional-headline,
.-H-ts15 .c-ob__headline {
	animation: tracking-in-expand-fwd-bottom .8s cubic-bezier(.215,.61,.355,1.000) both;
}

/* Attention */
.-I-ts16 .c-ob__img,
.-L-ts16 .c-ob__link-text,
.-S-ts16 .c-ob__splash {
	animation: bounce-top 2.7s both infinite;
}
.-I-ts17 .c-ob__img,
.-L-ts17 .c-ob__link-text,
.-S-ts17 .c-ob__splash {
	animation: wobble-hor-bottom 2.4s both infinite;
}
.-I-ts18 .c-ob__img,
.-L-ts18 .c-ob__link-text,
.-S-ts18 .c-ob__splash {
	animation: vibrate .3s linear infinite both;
}

/* Background */
.-W-ts19 .c-ob__bg {
	animation: kenburns-bottom-left 10s ease-out infinite alternate;
}

.-W-ts20 .c-ob__bg {
	animation: kenburns-top 5s ease-out both;
}

/* Flip Background Media */
.-W-fl1 .c-ob__bg {
	transform: scaleX(-1);
}

.-W-fl2 .c-ob__bg {
	transform: scaleY(-1);
}