// Form section
// Primarly used in product-page for separating different sections in form
// e.g. Capacity options, Payments, Baskets

// .cm-
#{$prefix} {
  // .cm-o-form-section
  &o-form-section {
    margin-bottom: 40px;
    // .cm-o-form-section--disabled
    &--disabled {
      opacity: 0.4;
    }
    // .cm-o-form-section__title
    &__title {
      margin-bottom: 20px;
      font-size: $font-size-h3;
    }
    // .cm-o-form-section__afternote
    &__afternote {
      font-size: $font-size-h6;
    }
    // .cm-o-form-section--selected
    &--selected {
      margin-bottom: 0;
      padding: 15px 0;
      border: 1px solid #ccc;
      border-width: 2px 0;

      .cm-o-form-section__options {
        display: none;
      }

      .cm-o-form-section__result {
        display: flex;
        justify-content: space-between;
      }

      & + & {
        border-width: 0 0 2px;
      }

      & + .cm-o-form-section:not(&),
      & + :not(.cm-o-form-section) {
        margin-top: 40px;
      }
    }
    // .cm-o-form-section__result
    &__result {
      display: none;
    }
    // .cm-o-form-section__result-name
    &__result-name {
      font-size: $font-size-h3;

      @include mq($screen-sm, max) {
        padding-right: 20px;
      }
    }
    // .cm-o-form-section__result-price
    &__result-price {
      @include mq($screen-xs, max) {
        display: block;
      }
    }
    // .cm-o-form-section__result-value
    &__result-value {
      align-self: center;

      a {
        display: block;
        color: $c-pm-500;
        white-space: nowrap;
      }
    }
  }
}

.cm-o-form-section {
}
