


































































































































































































































































































































































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors';

.product-configuration {
  position: relative;

  &:first-child {
    margin-top: $u-600;
  }

  &__group {
    margin-bottom: $u-200;
  }

  &__remove {
    display: flex;
    align-items: center;
    $btn-size: $u-500;
    background: none;
    padding: 0;
    height: $btn-size / 2;
    position: absolute;
    right: $u-300;
    top: $u-300;
    width: $btn-size / 2;
    z-index: 1;

    svg {
      height: 100%;
      width: 100%;
      fill: $c-pm-500;
    }
  }

  &__modal__message {
    text-align: center;
    margin: $u-250;
  }
}
