








































.c-gallery-overlay {
  position: relative;
}
