
















































































































































































































































































































































































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.internet-config {
  &__section {
    margin-top: $u-600;

    &__row {
      margin-top: $u-400;
      margin-bottom: $u-350;

      @include mq($screen-md, max) {
        &__col-chip:last-of-type {
          margin-top: $u-200;
        }
      }
    }
  }

  &__info {
    &__text {
      a {
        color: $c-pm-500;
      }
    }
  }

  &__start {
    &__legend {
      font-size: $font-size-h2;
      font-weight: 700;
      margin-bottom: $u-300;
    }

    &__selection {
      .c-form-radio-group-wrapper {
        div[role='radiogroup'] {
          gap: $u-300;
        }
      }

      &__label {
        font-weight: 700;
      }
    }
  }

  &__datepicker {
    padding-bottom: $u-250;
  }

  &__disclaimer {
    font-size: 14px;
  }

  &__cancellation-information {
    margin-bottom: $u-600;
    margin-top: Calc($u-400 * -1);
  }

  &__cancellation-manual-information {
    margin-top: $u-500;
  }
}
