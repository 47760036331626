





















































































































@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';

$icon-size: 30px;
$icon-margin: $u-300;

.user-card {
  border: 2px solid $c-nt-300;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $u-250;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $u-350 $u-300 $u-300 $u-350;

    figure {
      display: flex;
      align-items: center;

      svg {
        width: $icon-size;
        height: $icon-size;
        margin-right: $icon-margin;
      }
    }

    button {
      flex-shrink: 0;
    }
  }

  &__description {
    * {
      font-size: $font-size-h5;
    }

    &:not(:empty) {
      padding: 0 $icon-size + $icon-margin * 2;
      margin-bottom: $u-300;
    }

    a {
      color: $c-pm-600;
    }
  }

  &--selected {
    border-color: $c-pm-300;
  }

  &--disabled {
    p {
      color: $c-nt-400;
    }

    svg {
      fill: $c-nt-400;
    }

    .user-card__description {
      color: $color-black;
    }
  }
}
