






























































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.porting-config {
  font-size: $global-font-size-md;

  &__section {
    margin-bottom: $u-400;

    &--no-margin {
      margin-bottom: 0;
    }

    &__row:not(:last-of-type) {
      margin-bottom: $u-250;
    }

    &__row {
      @include mq($screen-md, max) {
        &__chip-wrapper:last-of-type {
          margin-top: $u-200;
        }
      }

      // porting-config__section__row__tooltip
      &__tooltip {
        display: flex;
      }

      &__sim-info-text {
        margin-right: $u-200;
      }
    }
  }

  h3 {
    font-size: $font-size-h3;
    line-height: $title-line-height;
    font-weight: 700;
  }

  &__legend {
    font-size: $global-font-size;
    font-weight: 700;
    margin-bottom: $u-300;
  }

  hr {
    background: $c-nt-300;
    border: none;
    height: $u-100;
    margin: $u-600 0;

    @include mq($screen-md, max) {
      margin: $u-350 0;
    }
  }

  &__info-box {
    margin-top: $u-400;
  }

  &__input {
    margin-top: $u-350;
  }
}
.howToFindSimModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__img {
    margin-top: $u-250;
    flex-grow: 0;
  }
}

// porting__tooltip
.porting__tooltip {
  margin-left: $u-200;
  display: flex;

  // porting__tooltip__icon-wrapper
  &__icon-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    // porting__tooltip__icon-wrapper__icon
    &__icon {
      max-height: $u-350;
      max-width: $u-350;
    }
  }
}
