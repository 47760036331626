











































































































































































































































































































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

// new-number-config
.new-number-config {
  // new-number-config__section
  &__section {
    // new-number-config__section__row:not(:last-of-type)
    &__row:not(:last-of-type) {
      margin-bottom: $u-250;
    }
    // new-number-config__section__row
    &__row {
      // new-number-config__section__row__tooltip
      &__tooltip {
        display: flex;
      }
    }
  }
  // new-number-config__input-wrapper
  &__input-wrapper {
    button:disabled {
      cursor: initial;
    }

    margin-bottom: $u-150;
    // new-number-config__input-wrapper__search
    &__search {
      &--with-error {
        // If there is an error that is displayed below the input field
        // we add its line-height ($u-300) to the margin-bottom.
        margin-bottom: $u-400 + $u-300;
      }
    }

    &__fetch-btn {
      padding-right: $u-250;
    }
  }
  // new-number-config__number
  &__number {
    // new-number-config__number__more-numbers
    &__more-numbers {
      display: flex;
      justify-content: center;
      // new-number-config__number__more-numbers__icon
      &__icon {
        $link-icon-size: 20px;

        fill: currentColor;
        width: $link-icon-size;
        height: $link-icon-size;
        margin-right: $u-150;
      }
    }
    // new-number-config__number__search-tips
    &__search-tips {
      margin-bottom: $u-500;
      margin-left: $u-250;
      display: flex;
      align-items: baseline;
      label {
        font-style: normal;
        color: $c-pm-500;
        font-size: $font-size-h6;
      }
    }
    // new-number-config__number__no-numbers
    &__no-numbers {
      display: flex;
      justify-content: center;
      font-size: $font-size-h5;
      font-style: italic;
    }
    // new-number-config__number-chips
    &-chips {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: $u-250;
      column-gap: $u-250;
      margin-bottom: $u-350;

      ::v-deep .chip {
        flex-basis: 100%;

        @include mq($screen-sm, min) {
          flex-basis: calc(50% - #{$u-200});
        }

        @include mq($screen-md, min) {
          flex-basis: calc(33.3% - #{$u-200});
        }

        &__input + span {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  // new-number-config__separator
  &__separator {
    border: 0;
    height: $u-100;
    background: $c-nt-300;
    margin: $u-400 0 $u-550 0;
  }

  // new-number-config__simcard-selection
  &__simcard-selection {
    margin-bottom: $u-700;
  }

  // new-number-config____hidden-or-unlisted
  &__hidden-or-unlisted {
    // new-number-config____hidden-or-unlisted__legend
    &__legend {
      margin-bottom: $u-300;
      font-size: $font-size-h4;
      font-weight: bolder;
    }
  }
}

$info-icon-size: 20px;
// new-number__tooltip
.new-number-tooltip {
  display: flex;
  // new-number-tooltip__icon-wrapper
  // new-number-tooltip__icon-wrapper
  &__icon-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    // new-number-tooltip__icon-wrapper__icon
    &__icon {
      max-height: $info-icon-size;
      max-width: $info-icon-size;
      margin-left: $u-200;
      &--purple {
        fill: $c-pm-500;
      }
    }
  }
}
