



























































































































































































































































































































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.checkout-select-payment {
  // .checkout-select-payment__activation-box
  &__activation-box {
    border: $u-100 solid $c-nt-300;
    border-radius: $u-200;
    background: $color-white;
    margin-bottom: $u-250;
    display: flex;
    width: 100%;
    padding: $u-300;

    // .checkout-select-payment__activation-box-image
    &-image {
      min-width: $u-600;
      min-height: $u-600;
      overflow: hidden;
      border-radius: $u-200;
      margin-right: $u-250;
      display: flex;
      justify-content: center;

      // .checkout-select-payment__image__icon
      &__icon {
        fill: $color-white;
        width: $u-600;
        height: $u-600;
      }
    }

    // .checkout-select-payment__activation-box-content
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      font-size: $font-size-h4;

      // .checkout-select-payment__activation-box-content-title
      &-title {
        color: $c-nt-900;
        font-weight: 700;
      }

      // .checkout-select-payment__activation-box-content-description
      &-description {
        color: $c-nt-500;
        margin-bottom: $u-200;

        ul {
          list-style: disc;
          padding-left: $u-350;
        }
      }
    }
  }

  // .checkout-select-payment__modal-container
  &__modal-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  // .checkout-select-payment__modal-button-container
  &__modal-button-container {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }

  // .checkout-select-payment__banner-container
  &__banner-container {
    // .checkout-select-payment__banner-container .resurs-banner
    .resurs-banner {
      // .checkout-select-payment__banner-container .resurs-banner__container
      &__container {
        box-shadow: unset;
        border: $u-100 solid $c-nt-300;

        ul {
          list-style: disc;
          padding-left: $u-350;
        }
      }
    }
  }
}
