// Form elements
button,
label {
	cursor: pointer;
}
input,
textarea,
select,
button {
	font-family: $global-font-family;
	font-size: $global-font-size;
	line-height: $global-line-height;
}
