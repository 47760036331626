




















































































































































@import 'theme/sass/settings/_settings.vars.scss';

.sim-card-selection {
  &__legend {
    margin-bottom: $u-250;
    font-weight: 700;
  }
}
