







































@import 'theme/sass/settings/_settings.vars.scss';

// .broadband-landing-page
.broadband-landing-page {
  // .broadband-landing-page__modal
  &__modal {
    // .broadband-landing-page__modal__message
    &__message {
      text-align: center;
      padding-top: $u-250;
    }
  }
}
