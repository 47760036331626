.skeleton-loader {
    z-index: 1;

    &__inner {
        border-radius: 3px;
        height: inherit;
        max-width: 100%;
        min-height: inherit;
        width: 100%;

        animation: skeleton-animation 3s infinite;
        background: linear-gradient(-90deg,  #CECDCD 0%, #F4F4F4 33%, #DEDEDE 66%, #CECDCD 100%);
        background-size: 200%;
        background-position: 200%;
        &--round-border {
            border-radius: inherit;
        }
    }
}

@keyframes skeleton-animation {
    to {
        background-position: 0;
    }
    
}