









































































































































@import 'olympus/styles/media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

.split-navigation {
  display: flex;
  height: 100%;
  padding: $u-300;
  flex-direction: column;
  // .split-navigation__menu-icon
  &__menu-icon {
    width: 100px;
    height: 46px;
    fill: $c-pm-600;

    @include from-tablet {
      width: 124px;
      height: 80px;
    }
  }
  // .split-navigation__container
  &__container {
    display: flex;
    width: 100%;
    margin: auto;
    flex-direction: column;

    @include from-tablet {
      flex-direction: row;
      padding: unset;
    }
  }
  // .split-navigation__symbols
  &__symbols {
    display: flex;
    flex-direction: column;
    max-width: 384px;
    margin: 0 auto $u-150;
    flex-direction: column-reverse;
  }
  // .split-navigation__heading
  &__heading {
    text-align: left;
    font-size: $font-size-h2;
    font-family: $global-font-family-2;
    max-width: 384px;
    margin: 0 auto $u-150;

    @include from-tablet {
      margin: 0 auto $u-200;
    }
  }
  // .split-navigation__description
  &__description {
    text-align: left;
    font-size: $global-font-size-md;
    max-width: 384px;
    margin: 0 auto $u-300;

    @include from-tablet {
      margin: 0 auto $u-400;
    }
  }
  // .split-navigation__badge
  &__badge {
    background: $c-sp-teal-500;
    color: $color-white;
    width: fit-content;
    padding: 2px $u-250;
    border-radius: 3px;
    font-size: $global-font-size-md;
    margin: 0 auto $u-200 0;
    position: absolute;
  }
  // .split-navigation__icon
  &__icon {
    width: $u-900;
    height: $u-900;
    fill: $c-pm-600;
    margin: 0 auto $u-500 auto;
  }
  // .split-navigation__option
  &__option {
    text-align: left;
    max-width: 384px;
    margin: 0 auto $u-150;

    @include from-tablet {
      margin: 0 auto $u-200;
    }
  }
  // .split-navigation__divider
  &__divider {
    border-top: 1.5px solid $c-nt-300;
    padding: 0 0 $u-600;
    margin: $u-600 0 0;

    @include from-tablet {
      border-top: unset;
      height: 180px;
      border-right: 1.5px solid $c-nt-300;
      padding: 0 0 0 $u-300;
      margin: auto $u-300 auto 0;
    }
  }
}
