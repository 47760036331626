










































































































.c-input {
  &__input {
    border-radius: 3px;
  }
}
