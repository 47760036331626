@import 'olympus/styles/mixins/button-reset.scss';

// Buttons
button {
  outline: none;
}

html [type='button'] {
  appearance: none;
}

$wideButtonWidth: 282px;

// .c-btn
/// @param {String} $size ('', 'sm', 'md', 'lg')
/// @param {String} $variant ('', 'primary', 'primary-alternative', white','secondary', 'secondary-white', 'tertiary', 'transactional', 'desctructive', 'expressive')
@mixin c-btn($size: '', $variant: '') {
  height: $u-700;
  font-size: $font-size-h4;
  font-weight: 400;
  line-height: $u-400;
  padding: $u-250 $u-400;
  border: none;
  border-radius: $u-450;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.25s, color 0.25s, border-color 0.25s;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;

  // All buttons share the same outline on focus.
  &:focus-visible {
    outline: $u-100 solid $c-pm-400;
    outline-offset: $u-150;
  }

  // c-btn--primary (default)
  color: $color-white;
  background-color: $c-pm-850;

  &:hover {
    background-color: $c-pm-700;

    // For when we give the c-btn classname to links
    text-decoration: none;
  }

  &:active {
    background-color: $c-pm-600;
  }

  &:disabled,
  &--disabled {
    background-color: $c-nt-100;
    color: $c-nt-500;
    pointer-events: none;
    outline: none;
  }

  // c-btn--secondary
  &--secondary {
    background-color: transparent;
    border: $u-100 solid $c-pm-850;
    color: $c-pm-850;

    &:hover,
    &:active {
      color: $color-white;
    }

    &:hover {
      background-color: $c-pm-700;
      border: $u-100 solid $c-pm-700;
    }

    &:active {
      background-color: $c-pm-600;
      border: $u-100 solid $c-pm-600;
    }
  }

  // c-btn--tertiary
  &--tertiary {
    background-color: transparent;
    color: $c-pm-500;

    &:hover {
      background-color: $c-pm-100;
    }

    &:active {
      background-color: $c-pm-200;
    }
  }

  // c-btn--transactional
  &--transactional {
    background-color: $c-sp-green-500;
    color: $c-nt-900;

    &:hover {
      background-color: $c-sp-green-400;
    }

    &:active {
      background-color: $c-sp-green-600;
    }
  }

  // c-btn--transactional-secondary
  &--transactional-secondary {
    background-color: transparent;
    color: $c-nt-900;
    border: $u-100 solid $c-sp-green-500;

    &:hover {
      background-color: $c-sp-green-400;
      border: $u-100 solid $c-sp-green-400;
    }

    &:active {
      background-color: $c-sp-green-600;
      border: $u-100 solid $c-sp-green-600;
    }
  }

  // c-btn--destructive
  &--destructive {
    background-color: $c-sp-red-500;
    color: $color-white;

    &:hover {
      background-color: $c-sp-red-400;
    }

    &:active {
      background-color: $c-sp-red-600;
    }
  }

  // c-btn--tertiary-destructive
  &--tertiary-destructive {
    background-color: transparent;
    color: $c-sp-red-500;

    &:hover {
      background-color: $c-sp-red-400;
      color: $color-white;
    }

    &:active {
      background-color: $c-sp-red-600;
      color: $color-white;
    }
  }

  // c-btn--primary-alternative
  &--primary-alternative {
    background-color: $color-white;
    color: $c-pm-850;

    &:hover {
      background-color: $c-pm-200;
    }

    &:active {
      background-color: $c-pm-300;
    }
  }

  // c-btn--secondary-alternative
  &--secondary-alternative {
    background-color: transparent;
    border: $u-100 solid $color-white;

    &:hover {
      background-color: $color-white;
      border: $u-100 solid $color-white;
      color: $c-pm-850;
    }

    &:active {
      background-color: $c-pm-200;
      border: $u-100 solid $c-pm-200;
      color: $c-pm-850;
    }
  }

  &--secondary,
  &--transactional-secondary,
  &--secondary-alternative {
    &:disabled {
      background-color: transparent;
      border: $u-100 solid $c-nt-400;
      color: $c-nt-400;
    }
  }

  &--tertiary,
  &--tertiary-destructive {
    &:disabled {
      background-color: transparent;
      color: $c-nt-400;
    }
  }

  // .c-btn--link c-btn--as-link
  &--as-link,
  &--link {
    background: none;
    text-decoration: underline;
    color: $c-pm-500;
    padding: 0;
    height: fit-content;

    &:hover {
      color: $c-pm-400;
      background: none;
    }

    &:active {
      color: $c-pm-600;
      background: none;
    }

    &:focus-visible {
      outline: $u-100 solid $c-pm-300;
      border-radius: 0;
    }

    &:disabled {
      background-color: transparent;
      text-decoration: none;
      color: $c-nt-400;
    }
  }

  // .c-btn--reset
  &--reset {
    border: none;
    border-radius: 0;
    outline: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    appearance: none;

    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }

    &:focus-visible {
      background-color: transparent;
      outline: none;
      border: none;
    }

    &:disabled {
      background-color: transparent;
      text-decoration: none;
      color: $c-nt-300;
    }
  }

  // .c-btn--close
  &--close {
    position: absolute;
    right: $u-300;
    top: $u-300;
    margin-left: auto;
    width: $u-600;
    height: $u-600;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;

    & > span {
      display: none;
    }

    &:hover {
      opacity: 1;
    }

    &:before,
    &:after {
      position: absolute;
      top: $u-200;
      left: calc(50% - #{$u-150});
      content: ' ';
      height: $u-400;
      width: $u-150;
      background-color: #333;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  // .c-btn--back-link
  &--back-link {
    &:before {
      content: '<';
      display: inline-block;
      margin-right: $u-200;
    }
  }

  &--text-left {
    text-align: left;
  }

  &--align-center {
    margin-left: auto;
    margin-right: auto;
  }

  &--icon {
    max-width: $u-350;
    max-height: $u-350;
    margin-right: $u-150;
    fill: currentColor;

    .cm-icon {
      font-size: $font-size-h1;
      font-weight: bold;
      line-height: $u-300;
      vertical-align: text-top;
    }
  }

  // .c-btn--rounded
  &--rounded {
    border-radius: 1000px;
  }

  // TODO DKT-11144: Remove the --light styles. They are not in the design.
  // .c-btn--light
  &--light {
    background-color: $color-white;
    border: $u-100 solid $c-pm-500;
    color: $c-pm-500;

    &:hover,
    &:focus {
      background-color: $c-pm-100;
      border-color: $c-pm-500;
      color: $c-pm-500;
    }

    &.c-btn--secondary {
      border: $u-100 solid $c-sp-green-500;
      color: $c-nt-900;

      &:hover,
      &:focus {
        background-color: $c-sp-green-100;
        border-color: $c-sp-green-500;
      }
    }
  }

  // .c-btn--inline
  &--inline {
    display: inline-block;
  }

  // TODO DKT-11142: Introduce size props instead.
  @if ($size == 'sm') {
    height: $u-600;
    padding: $u-200 $u-350;
  }

  @if ($size == 'md') {
    height: $u-700;
    padding: $u-250 $u-400;
  }

  @if ($size == 'lg') {
    height: 56px;
    padding: $u-300 $u-450;
  }

  @if ($variant == 'primary-alternative') {
    @include primary-alternative();
  }

  @if ($variant == 'transactional') {
    @include transactional();
  }

  // .c-btn--bordered
  &--bordered {
    border: $u-100 solid currentColor;
  }

  // .c-btn--block
  &--block {
    display: block;
    width: 100%;
  }

  // TODO DKT-11141: Refactor --wide & __column
  // .c-btn--wide
  &--wide {
    width: $wideButtonWidth;
    max-width: 100%;
  }

  // TODO DKT-11144: Remove the --empty styles. They are not in the design.
  // .c-btn--empty
  &--empty {
    //background-color: transparent;
    // .c-btn--empty:hover
    &:hover {
      background-color: $c-pm-500;
      border-color: $c-pm-500;
      color: $color-white;
    }
    // .c-btn--empty.is-active
    &.is-active {
      border-color: $c-nt-200;
    }
  }

  // .c-btn--empty-primary
  &--empty-primary {
    background-color: transparent;
    border: 2px solid $c-pm-500;
    color: $c-pm-500;
    // .c-btn--empty-primary:hover
    &:hover {
      background-color: $c-pm-500;
      color: $color-white;
    }
  }

  // .c-btn--empty-secondary
  &--empty-secondary {
    background-color: transparent;
    border: $u-100 solid $c-sp-teal-500;
    color: $c-sp-teal-500;
    // .c-btn--empty-secondary:hover
    &:hover {
      background-color: $c-sp-teal-500;
      color: $color-white;
    }
  }

  // .c-btn--empty-white
  &--empty-white {
    background-color: transparent;
    border: $u-100 solid $color-white;
    color: $color-white;

    &:hover {
      background-color: $color-white;
      color: $global-text-color;
    }
  }

  .cm-c-spinner {
    transform: scale(0);
    transition: transform 0.25s;
  }

  // .c-btn--loading
  &--loading {
    flex-wrap: nowrap;
    padding-right: $u-700;
    position: relative;

    .cm-c-spinner {
      background-color: transparent;
      left: unset;
      transform: scale(1);
      width: $u-700;
    }
  }

  // .c-btn--sm
  &--sm {
    height: $u-600;
    padding: $u-200 $u-350;
  }

  // .c-btn--md
  &--md {
    height: $u-700;
    padding: $u-250 $u-400;
  }

  // .c-btn--lg
  &--lg {
    height: 56px;
    padding: $u-300 $u-450;
  }

  // .c-btn__column
  &__column {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    line-height: 1;
    &:nth-child(2) {
      margin-left: auto;
      padding-left: $u-200;
    }
  }

  &--expressive {
    background-color: $c-pm-500;
    color: $color-white;

    &:hover {
      background-color: $c-pm-600;
    }

    &:active {
      background-color: $c-pm-700;
    }

    &:focus-visible {
      background-color: $c-pm-500;
      outline-style: solid;
      outline-color: $c-pm-300;
      outline-width: $u-100;
      outline-offset: 2px;
    }
  }
}

@mixin primary-alternative() {
  background-color: $color-white;
  color: $c-pm-850;

  &:hover {
    background-color: $c-pm-200;
  }

  &:active {
    background-color: $c-pm-300;
  }
}

@mixin transactional() {
  background-color: $c-sp-green-500;
  color: $c-nt-900;

  &:hover {
    background-color: $c-sp-green-400;
  }

  &:active {
    background-color: $c-sp-green-600;
  }
}

.c-btn {
  @include c-btn();
}
