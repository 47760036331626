





























































































































































































































































































































































@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.product-tabs {
  padding: $u-500 0;

  @include mq($screen-md, max) {
    max-width: 100%;
  }

  // .product-tabs__tabs
  &__tabs {
    [class$='-nav'] {
      & > ul {
        padding: 0 0 $u-700 + $u-350 0;

        @include mq($screen-md, max) {
          padding-bottom: $u-700;
        }

        li {
          margin-right: $u-500;

          @include mq($screen-md, min) {
            margin-right: $u-700 + $u-200;
          }

          &.active {
            a {
              color: $c-pm-500;
              border-bottom-color: $c-pm-500;
            }
          }
        }

        a {
          font-size: $font-size-h4;
          padding-bottom: $u-200;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  // .product-tabs__content
  &__content {
    max-width: 792px;
    margin: 0 auto;

    &--full-width {
      max-width: 100%;
    }

    &__markup {
      h1,
      h2,
      h3,
      h4 {
        font-family: $global-font-family-2;
        font-size: $font-size-h2;
        padding: $u-300 0 $u-150;
        text-transform: uppercase;
      }

      p {
        line-height: 1.3;
      }
    }
  }

  .cm-c-total {
    margin-top: $u-300;

    // .cm-c-total__row
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    // .cm-c-total__value
    &__value {
      flex-shrink: 0;
      margin-left: $u-300;
    }
  }
}
