












































































































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$inputHeight: $u-700 + $u-200;
$inputBorderWidth: 2px;

.c-dropdown {
  & .vs__actions {
    padding: 0px;
  }

  & .vs__dropdown-toggle {
    background-color: $color-white;
    box-shadow: $box-shadow-default;
    border-radius: 3px;
    height: $inputHeight;
    padding-bottom: 0px;
    border: none;
  }

  & .vs__selected-options {
    padding: 0px;
    width: 100%;
    height: $inputHeight;

    & > label {
      bottom: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      text-align: left;
      padding-left: $u-250;
      padding-top: 18px; // $u-300 + 2px
      font-size: $global-font-size;
      color: $c-nt-500;

      transition: all 0.2s ease;
    }

    & > input {
      margin-top: 0px;
      padding-top: $u-300;
      padding-left: $u-250;
      outline-offset: 0px;
      position: absolute;
      width: 100%;
      height: 100%;

      font-size: $global-font-size;
      line-height: $global-font-size;

      height: $inputHeight;
      border-radius: 3px;
      border: $inputBorderWidth solid $c-nt-300;
      transition: border-color 0.2s ease;

      &:hover {
        border-color: $c-nt-500;
      }

      &:disabled {
        border-color: $c-nt-300;

        & + label {
          color: $c-nt-300;
        }
      }

      &:focus,
      &:not(.vs__search--empty) {
        border-color: $c-pm-500;
        & + label {
          color: $c-pm-500;
          font-size: $global-font-size-sm;
          padding-top: $u-200;
        }
      }
    }
  }

  & .vs__dropdown-option--highlight {
    background-color: $c-nt-200;
    color: $c-nt-900;
  }

  & .vs__dropdown-menu {
    top: 100%;
    border-radius: 3px;
    padding-top: 0px;
  }

  & .vs__no-options {
    color: $c-nt-900;
    min-height: $inputHeight;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .vs__dropdown-option {
    line-height: $inputHeight;
  }

  & .vs__selected {
    padding-top: 16px;
    padding-left: 12px;
    margin: 0px;
    position: relative !important;
  }

  & .vs__clear {
    display: none;
  }

  &__icon {
    height: calc(100% - #{2 * $inputBorderWidth});
    position: absolute;
    right: $inputBorderWidth;
    top: $inputBorderWidth;
    width: 60px;
    background-color: white;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      height: $u-400;
      width: $u-400;
      transition: all 0.2s ease;

      .vs--disabled & {
        fill: $c-nt-300;
      }
    }

    &--touchable {
      cursor: pointer;
      z-index: 10;
    }
  }

  &__loader {
    position: absolute;

    &:before {
      left: calc(100% - 3em) !important;
    }
  }

  &:not(.c-dropdown--searchable) {
    .vs__selected-options {
      border-radius: 3px;
      border: $inputBorderWidth solid $c-nt-300;
      transition: border-color 0.2s ease;
    }

    &.vs--open {
      .vs__selected-options {
        border-color: $c-pm-500;
      }
      .c-dropdown__icon > svg {
        transform: rotateZ(180deg);
      }
    }
  }

  &--skinny {
    .vs__dropdown-toggle {
      box-shadow: none;
    }

    .vs__selected-options > input {
      border-width: 1px;
    }
  }
}
