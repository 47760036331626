@mixin c-figure(
  $figure-theme: (
    border-radius: 3px
  )
) {
  $root: &;

  @include margin(bottom, 4);

  width: 100%;
  box-sizing: content-box;

  &--left,
  &--right {
    width: map-deep-get($figure-theme, width, floated, default);

    @include mq($screen-md, min) {
      width: map-deep-get($figure-theme, width, floated, desktop);
    }

    @include margin(bottom, 3);
  }

  &--left {
    float: left;
    @include padding(right, 2);

    @include mq($screen-md, min) {
      @include padding(right, 3);
      margin-left: map-deep-get($c-article, negative-positioning, desktop);

      @include mq(map-get($c-article, screen), max) {
        margin-left: map-deep-get($c-article, negative-positioning, default);
      }
    }
  }

  &--right {
    float: right;
    @include padding(left, 2);

    @include mq($screen-md, min) {
      @include padding(left, 3);
      margin-right: map-deep-get($c-article, negative-positioning, desktop);

      @include mq(map-get($c-article, screen), max) {
        margin-right: map-deep-get($c-article, negative-positioning, default);
      }
    }
  }

  &--center {
    @include mq($screen-md, min) {
      .c-video {
        &:after {
          width: rem-calc(map-get($figure-theme, icon));
          height: rem-calc(map-get($figure-theme, icon));
        }

        svg {
          width: rem-calc(map-get($figure-theme, icon));
        }
      }
    }
  }

  figcaption {
    background: color(primary, dim);

    @include typography(body-sm);
    @include padding(vertical);
    @include padding(horizontal);

    @include mq($screen-md, min) {
      @include padding(horizontal, 2);
    }
  }

  picture,
  .c-video {
    border-radius: map-get($figure-theme, border-radius);
    display: block;
    overflow: hidden;
    width: 100%;
    text-align: center;

    img {
      object-fit: cover;
      overflow: hidden;
    }
  }

  &--right,
  &--left {
    picture img,
    .c-video img {
      height: map-deep-get($figure-theme, height, floated, default);

      @include mq($screen-md, min) {
        height: map-deep-get($figure-theme, height, floated, desktop);
      }
    }
  }
}

.c-figure {
  @include c-figure($c-figure);
}
