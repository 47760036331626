













































































































































































































































































































































































































































































@import 'olympus/styles/media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.product-stock-status__text {
  font-size: $global-font-size-md;
  line-height: $global-line-height-xs;

  // .product-stock-status__text--shop-stock-enabled
  &--shop-stock-enabled {
    color: $c-pm-500;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__skeleton-loader {
    height: $u-300;
    width: $u-900;
  }
}

.store-list {
  &__heading {
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;

    @include mq($screen-md, max) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__search {
    margin: $u-500 0 $u-300;
  }

  &__filters {
    display: flex;
    flex-direction: column;
    gap: $u-300;
    margin-bottom: $u-400;

    @include from-tablet {
      flex-direction: row;
      align-items: center;
      gap: $u-400;
      margin-bottom: $u-500;
    }
  }

  &__stock-status {
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: $u-200;
    line-height: $u-400;

    &-indicator {
      width: $u-250;
      height: $u-250;
      border-radius: 50%;
      background: $c-sp-red-500;

      &.green {
        background: $c-sp-green-500;
      }

      &.yellow {
        background: $c-sp-orange-500;
      }
    }
  }

  &__store {
    padding: $u-300;
    border-top: $u-100 solid $c-nt-300;

    &:hover:not(.expanded) {
      cursor: pointer;
      background-color: $c-pm-100;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $u-200;
      line-height: $u-400;

      &-meta {
        display: flex;
        align-items: center;
        gap: $u-200;
      }

      &-title {
        font-weight: 700;
      }

      &-km-label,
      &-distance {
        font-size: $global-font-size-md;
        line-height: $global-line-height-xs;
        display: flex;
        align-items: center;
        gap: $u-200;
      }

      &-distance {
        &:before {
          display: block;
          width: $u-150;
          height: $u-150;
          background: $c-nt-900;
          border-radius: 50%;
          content: '';
        }

        &__skeleton-loader {
          height: $u-250;
          width: $u-350;
        }
      }

      &-toggle {
        overflow: hidden;
        color: $c-pm-500;
      }
    }

    &__address {
      margin-bottom: $u-200;
      font-style: normal;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: $u-200;
      margin: $u-300 0 $u-250;

      &__action {
        line-height: $u-400;
        color: $c-pm-500;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: $u-200;

        svg {
          fill: $c-pm-500;
          stroke: $c-pm-500;
          width: $u-350;
          height: $u-350;
        }
      }
    }

    &__opening-hours__list {
      display: flex;
      line-height: $u-400;

      &-item {
        flex-basis: 160px;
      }
    }
  }
}
