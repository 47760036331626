








































$icon-size: 20px;
.member-description-list {
  padding: 10px 20px;

  & > li {
    margin-bottom: 20px;

    & span.member {
      // TODO: DKT-39277 replace with norlys when it's time to do cleanup.
      background: url('../../../telia/images/icon-user.svg') no-repeat center
        center;
      background-size: $icon-size $icon-size;
      height: $icon-size;
      width: $icon-size;
      display: inline-block;
      margin-right: 5px;

      &-separator {
        margin-right: 5px;
      }
    }
  }

  &__image {
    line-height: $icon-size;
    vertical-align: middle;
  }
}
