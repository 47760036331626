// Fieldset
fieldset {
	&[disabled] {
		opacity: .4;
		// not clickable link
		a {
			pointer-events: none;
		}
		button {
			cursor: default;
		}
	}
}
