/**
 * oneblock-padding.css
 * @version 1.1.10
 * @summary 26-02-2020
 * @author Mads Stoumann
 * @description OneBlock: Padding
 */

/* Padding
===============================
Prefix:
-C Image Caption
-E Content Box

Key:
-pl, -pt

Value:
0 (Default)-9
===============================
*/
.-E-pt0 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 0); }
.-E-pr0 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 0); }
.-E-pb0 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 0); }
.-E-pl0 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 0); }
.-E-pt1 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 1); }
.-E-pr1 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 1); }
.-E-pb1 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 1); }
.-E-pl1 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 1); }
.-E-pt2 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 2); }
.-E-pr2 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 2); }
.-E-pb2 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 2); }
.-E-pl2 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 2); }
.-E-pt3 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 3); }
.-E-pr3 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 3); }
.-E-pb3 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 3); }
.-E-pl3 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 3); }
.-E-pt4 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 4); }
.-E-pr4 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 4); }
.-E-pb4 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 4); }
.-E-pl4 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 4); }
.-E-pt5 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 5); }
.-E-pr5 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 5); }
.-E-pb5 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 5); }
.-E-pl5 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 5); }
.-E-pt6 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 6); }
.-E-pr6 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 6); }
.-E-pb6 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 6); }
.-E-pl6 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 6); }
.-E-pt7 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 7); }
.-E-pr7 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 7); }
.-E-pb7 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 7); }
.-E-pl7 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 7); }
.-E-pt8 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 8); }
.-E-pr8 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 8); }
.-E-pb8 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 8); }
.-E-pl8 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 8); }
.-E-pt9 .c-ob__content { padding-top: calc(var(--ob-E-pt) * 9); }
.-E-pr9 .c-ob__content { padding-right: calc(var(--ob-E-pr) * 9); }
.-E-pb9 .c-ob__content { padding-bottom: calc(var(--ob-E-pb) * 9); }
.-E-pl9 .c-ob__content { padding-left: calc(var(--ob-E-pl) * 9); }

.-C-pt0 .c-ob__bg-captiont {
  padding-bottom: 0;
  padding-top: 0;
}
.-C-pt1 .c-ob__bg-caption {
  padding-bottom: calc(var(--ob-C-pt) * 1);
  padding-top: calc(var(--ob-C-pt) * 1);
}
.-C-pt2 .c-ob__bg-caption {
  padding-bottom: calc(var(--ob-C-pt) * 2);
  padding-top: calc(var(--ob-C-pt) * 2);
}
.-C-pt3 .c-ob__bg-caption {
  padding-bottom: calc(var(--ob-C-pt) * 3);
  padding-top: calc(var(--ob-C-pt) * 3);
}
.-C-pt4 .c-ob__bg-caption {
  padding-bottom: calc(var(--ob-C-pt) * 4);
  padding-top: calc(var(--ob-C-pt) * 4);
}
.-C-pt5 .c-ob__bg-caption {
  padding-bottom: calc(var(--ob-C-pt) * 5);
  padding-top: calc(var(--ob-C-pt) * 5);
}
.-C-pt6 .c-ob__bg-caption {
  padding-bottom: calc(var(--ob-C-pt) * 6);
  padding-top: calc(var(--ob-C-pt) * 6);
}
.-C-pt7 .c-ob__bg-caption {
  padding-bottom: calc(var(--ob-C-pt) * 7);
  padding-top: calc(var(--ob-C-pt) * 7);
}
.-C-pt8 .c-ob__bg-caption {
  padding-bottom: calc(var(--ob-C-pt) * 8);
  padding-top: calc(var(--ob-C-pt) * 8);
}
.-C-pt9 .c-ob__bg-caption {
  padding-bottom: calc(var(--ob-C-pt) * 9);
  padding-top: calc(var(--ob-C-pt) * 9);
}
.-C-pl0 .c-ob__bg-caption {
  padding-left: 0;
  padding-right: 0;
}
.-C-pl1 .c-ob__bg-caption {
  padding-left: calc(var(--ob-C-pl) * 1);
  padding-right: calc(var(--ob-C-pl) * 1);
}
.-C-pl2 .c-ob__bg-caption {
  padding-left: calc(var(--ob-C-pl) * 2);
  padding-right: calc(var(--ob-C-pl) * 2);
}
.-C-pl3 .c-ob__bg-caption {
  padding-left: calc(var(--ob-C-pl) * 3);
  padding-right: calc(var(--ob-C-pl) * 3);
}
.-C-pl4 .c-ob__bg-caption {
  padding-left: calc(var(--ob-C-pl) * 4);
  padding-right: calc(var(--ob-C-pl) * 4);
}
.-C-pl5 .c-ob__bg-caption {
  padding-left: calc(var(--ob-C-pl) * 5);
  padding-right: calc(var(--ob-C-pl) * 5);
}
.-C-pl6 .c-ob__bg-caption {
  padding-left: calc(var(--ob-C-pl) * 6);
  padding-right: calc(var(--ob-C-pl) * 6);
}
.-C-pl7 .c-ob__bg-caption {
  padding-left: calc(var(--ob-C-pl) * 7);
  padding-right: calc(var(--ob-C-pl) * 7);
}
.-C-pl8 .c-ob__bg-caption {
  padding-left: calc(var(--ob-C-pl) * 8);
  padding-right: calc(var(--ob-C-pl) * 8);
}
.-C-pl9 .c-ob__bg-caption {
  padding-left: calc(var(--ob-C-pl) * 9);
  padding-right: calc(var(--ob-C-pl) * 9);
}