// Form input field
// This is not for EPi forms

/* DOCUMENTATION
---
title: Input field
name: 1input
category: Components - Forms
---
The material design solution is working only if the required attribute is added.
Javascript more advanced solution is needed.

```html_example_table
<!-- INPUT -->
<div class="cm-c-form-control-group">
	<input type="text" class="cm-c-form-control" required>
	<label class="cm-c-form-control__label">Input label</label>
</div>

<!-- SELECT -->
<fieldset class="cm-c-form-control-group">
	<select name="example" class="cm-c-form-control cm-c-form-select cm-js-material-input">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
	</select>
	<label class="cm-c-form-control__label">Select label</label>
	<span class="cm-c-form-select__arrow"></span>
</fieldset>
```
*/

/* COLORS USED TO STATES OF THE FIELD */
$highlight-color: $c-pm-500;
// For Call me only, there is a seperate form file with styles that will overwrite the highlight color
$error-color: $c-sp-red-500;
$default-color: $c-nt-500;
$inactive-color: $c-nt-300;

// Form elements

// TODO-4911: Remove .cm prefix
#{$prefix} {
  // .cm-c-form
  &c-form {
    // .cm-c-form__row
    &__row {
      margin-bottom: $u-300;

      // .cm-c-form__row--extra-spacing
      &--extra-spacing {
        margin-bottom: $u-500;
      }

      // .cm-c-form__row--footer
      &--footer {
        margin-bottom: 0;
        margin-top: 40px;
      }
    }

    &__col:not(:last-of-type) {
      @include mq($screen-md, max) {
        margin-bottom: $u-300;
      }
    }

    // TODO-4911: Replace wrappers with the new ones that should have been introduced in CMS project ( 01-06-2020 )
    // .cm-c-form-wrapper
    &-wrapper {
      width: auto;
      max-width: $container-sm;
      margin: $u-300 auto;

      // Small phone screens
      @include mq($screen-sm, max) {
        margin: $u-150 auto;
        padding: $u-200;
      }

      // Medium screens like tablets
      @include mq($screen-md, max) {
        max-width: $container-xs;
      }
    }

    // .cm-c-form-submit-btn
    &-submit-btn {
      display: block;
      margin: 0 auto;

      // .cm-c-form-submit-btn--wrapper
      &--wrapper {
        margin: 16px 0px;

        // Add specificity, else common btn styles will take precedence
        & > button {
          display: block;
        }
      }
    }

    // .cm-c-form input[type="text"][readonly]
    input[type="text"][readonly],
    input[type="text"]:disabled {
      background: $c-nt-200;
      color: rgb(84, 84, 84);
    }
  }

  // .cm-c-form-control
  &c-form-control {
    width: 100%;
    border: $u-100 solid $inactive-color;
    padding: 24px 12px 12px 12px;
    border-radius: 2px;
    font-size: $global-font-size;
    line-height: 18px;
    color: $c-nt-900;
    margin-bottom: $u-350;

    // .cm-c-form-control--nogap
    &--nogap {
      margin-bottom: 0px;
    }

    @include targetIE() {
      // Allow more space for validation messages for IE, because label is positioned above the field
      margin-bottom: $u-250;
    }

    // .cm-c-form-control:hover
    &:hover {
      border-color: $default-color;
    }

    // .cm-c-form-control:focus
    &:focus {
      outline: none;
      border-color: $highlight-color;
    }

    // .cm-c-form-control__label
    &__label {
      position: absolute;
      top: $u-300;
      color: $default-color;
      font-weight: normal;
      transition: 0.2s ease all;
      pointer-events: none;
      padding: 0px 12px;
      display: block;

      @include targetIE() {
        // Labels should display above fields for IE
        top: -20px !important;
        padding: 0px !important;
      }
    }

    // .cm-c-form-control__icon
    &__icon {
      width: 18px;
      height: 18px;
      position: absolute;
      bottom: 27px;
      right: 16px;
      transform: translateY(50%);

      // .cm-c-form-control__icon--cursor-is-pointer
      &--cursor-is-pointer {
        cursor: pointer;
      }

      // .cm-c-form-control__icon--no-fixed-height
      &--no-fixed-height {
        width: unset;
        height: unset;
      }

      // .cm-c-form-control__icon svg
      & svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &[required]:valid ~ &__label {
      top: 10px;
      font-size: $font-size-h6;

      @include targetIE() {
        font-size: 16px;
      }
    }

    // TODO-4911: NEED TO FIND OUT WHAT THIS IS, AND IF ITS USED ANYWHERE, else remove it - 23/01/2023: This is used in the old number configuration (in new number search field), which is currently still being used in CallMe B2C checkout
    // .cm-c-form-control__button
    &__button {
      position: absolute;
      top: $u-250;
      right: $u-300;
      outline: none;
      background: none;
      border: none;

      &--icon {
        top: $u-150;
        right: 0;
        font-size: $font-size-h1;
      }
    }

    // .cm-c-form-control--filled ~ .cm-c-form-control__label
    &--filled ~ &__label,
    // .cm-c-form-control:focus ~ .cm-c-form-control__label
    &:focus ~ &__label {
      top: 10px;
      color: $highlight-color;
      font-size: $font-size-h6;

      @include targetIE() {
        font-size: 16px;
      }

      & ~ .cm-c-form-select__arrow:after {
        border-color: $highlight-color;

        @include targetEdge() {
          border-color: $default-color;
        }
      }
    }

    &:not(:focus) ~ &__label {
      @include targetEdge() {
        color: $default-color !important;
      }
    }

    &--filled:not(:focus) ~ &__label,
    &--filled[required]:not(:focus) ~ &__label {
      color: $default-color;

      & ~ .cm-c-form-select__arrow:after {
        border-color: $default-color;
        // this will not apply to edge, since filled class is not there for select
      }
    }
    &--filled[required]:focus:invalid,
    &--filled[required]:not(:focus):invalid {
      background: $c-nt-150;
      border-color: $error-color;
      & ~ .cm-c-form-select__arrow:after {
        border-color: $error-color;
        // exclude from edge
      }
    }
    &--filled[required]:focus:invalid ~ &__label,
    &--filled[required]:not(:focus):invalid ~ &__label {
      color: $error-color; //This does not get applied, the above selector has higher specificity

      // TODO-4911: Remove this once custom leads form is migrated to epi forms
      // Display validation message
      & ~ .input-validation-message {
        display: block;
      }
    }

    // .cm-c-form-control[readonly]
    &[readonly],
    &:disabled {
      background: $c-nt-200;
      color: rgb(84, 84, 84);
    }
  }

  // .cm-c-form-control-group
  &c-form-control-group {
    position: relative;

    // .cm-c-form-control-group--date
    &--date {
      // .cm-c-form-control-group--date:after
      &:after {
        position: absolute;
        top: 25px;
        right: 40px;
        // To let the click through and trigger input onClick
        pointer-events: none;
        @include add-icon($cm-icon-calendar);
      }
    }

    // .cm-c-form-control-group__label
    &__label {
      font-weight: 700;
    }

    &--withgap {
      margin: $u-200 0;
    }
  }

  // .cm-c-form-select
  &c-form-select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    $arrow-right-size: 50px;
    // This var is the resulting size of `.cm-c-form-select__arrow`, which is a combination of box-model and css transforms and can't be expressed with vars in 'settings'.
    // To prevent the arrow overlapping with long select option texts, padding the size of the right arrow is added and the oveflow text is replaced by ellipsis (...)
    padding-right: $arrow-right-size;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // Appearance none does not work in IE 11
    &::-ms-expand {
      display: none;
    }

    &::-ms-value {
      background: transparent;
      color: $color-black;
    }

    // .cm-c-form-select__arrow
    &__arrow {
      // .cm-c-form-select__arrow:after
      &:after {
        position: absolute;
        right: $u-350;
        top: $u-300;
        -webkit-transform: translateY(-75%) rotate(45deg);
        transform: translateY(-75%) rotate(45deg);
        background-color: transparent;
        border-color: $default-color;
        border-style: solid;
        border-width: 0 1.5px 1.5px 0;
        content: '';
        display: block;
        height: 0.75rem;
        margin: 0 auto;
        transform: rotate(45deg);
        width: 0.75rem;
      }
    }
  }
}

// Validation error messages
.field-validation-error {
  position: absolute;
  display: block;
  color: $error-color;
  font-size: 12px;
  top: 58px;
  padding: 0px 12px;
}

//This removes overlap on small resolutions on norlys partnershop 
.unset-position-partnershop {
  .field-validation-error {
    position: unset;
    display: block;
    margin-top: -24px;
  }
}

//*** TODO-4911: Remove this custom class after migrating leads form to EPI forms ***
// Custom validation message for leads form
.input-validation-message {
  color: $error-color;
  font-size: 12px;
  padding: 0px 12px;
  display: none;
}

// TODO-4911: above error messages are complete mess in terms of normal document flow. To not break
// any backward compatiability, will use modifier instead
.input-validation-message--relative {
  position: relative;
  top: 0;
  padding: 0;
}

/* TODO-4911 */
/* Overwrite juejs-datepicker styles */
.vdp-datepicker__calendar .cell.selected,
.vdp-datepicker__calendar .cell.selected.highlighted,
.vdp-datepicker__calendar .cell.selected:hover {
  background: #eb0000 !important;
  color: $color-white;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid #eb0000 !important;
}
