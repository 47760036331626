












































































































@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.price-summary {
  position: relative;

  // .price-summary__section
  &__section {
    border-bottom: 1px solid $c-nt-400;
    margin-bottom: $u-300;
    padding-bottom: $u-300;
    font-size: $global-font-size-md;
    line-height: 1.3;

    &-line {
      padding: $u-200 0;

      &:first-child {
        padding-top: 0px;
      }

      &:last-child {
        padding-bottom: 0px;
      }

      & > dd {
        text-align: right;
      }
    }

    // .price-summary__section--heading
    &--heading {
      font-size: $global-font-size;
      line-height: 1.5;
    }
  }

  &__trigger {
    position: relative;
    padding-left: $u-350;
    font-size: $global-font-size-md;
    line-height: 1.3;
    color: $c-nt-600;
    display: flex;
    justify-content: space-between;
    margin-bottom: $u-300;
    cursor: pointer;

    // .broadband-offering-page__prices-trigger > svg
    & > svg {
      position: absolute;
      top: 2px;
      left: 0;
      width: 14px;
      height: 14px;
      fill: $c-nt-600;
      transition: all 0.2s ease-in-out;

      // .broadband-offering-page__prices-trigger > svg.expanded
      &.expanded {
        transform: rotateZ(180deg);
      }
    }
  }

  // .price-summary__skeleton-loader
  &__skeleton-loader {
    width: 478px;
    height: 164px;
  }

  // .price-summary__footer
  &__footer {
    margin-bottom: $u-300;
    margin-top: $u-300;
    font-size: $global-font-size-sm;
    line-height: 18px;
    color: $c-nt-600;
    font-weight: 400;
  }
}
