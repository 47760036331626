

















































































































































































































































































































































$scroll-width: 17px;
$modal-body-padding: 2rem;

body.c-model-opened {
  overflow: hidden;
  padding-right: $scroll-width;
}

.c-modal {
  position: absolute;
  z-index: 999;

  // .c-modal-wrapper
  &-wrapper {
    position: fixed;
    display: block;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 0;
    z-index: 1000;
    padding: 0px 10px;

    // .c-modal-wrapper-inner
    &-inner {
      position: relative;
      width: auto;
      max-width: 500px;
      margin: 2rem auto 0 auto;
      max-height: calc(100vh - 3.5rem);
    }
  }

  // .c-modal-content
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    outline: 0;

    // .c-modal-content__header
    &__header {
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }

    // .c-modal-content__body
    &__body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem;
      max-height: 50vh;
      overflow-y: auto;
    }

    // .c-modal-content__footer
    &__footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      padding: 1rem;
      border-bottom-right-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;

      button,
      a {
        &:first-child:not(:only-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  // .c-modal-backdrop
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000070;
  }

  // .c-modal--centered
  &--centered {
    .c-modal-wrapper-inner {
      min-height: 100vh;
      display: flex;
      align-items: center;
    }
  }

  &--content-centered {
    .c-modal-content__header,
    .c-modal-content__body,
    .c-modal-content__footer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--large {
    & .c-modal-wrapper-inner {
      max-width: 100vw;
      height: calc(100vh - 3.5rem);
    }

    & .c-modal-content {
      height: 100%;
    }
  }

  &--medium {
    & .c-modal-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    & .c-modal-wrapper-inner {
      max-width: 1200px;
      width: 100%;
    }

    & .c-modal-content {
      height: 100%;

      &__body {
        max-height: calc(100vh - 2 * #{$modal-body-padding});
        padding: $modal-body-padding;
      }
    }
  }

  // .c-modal-header--close
  &-header--close {
    padding-top: 3rem;

    & > button {
      position: absolute;
      right: 20px;
      top: 20px;
      margin-left: auto;
      width: 40px;
      height: 40px;
      opacity: 0.3;
      transition: opacity 0.3s;
      z-index: 1010;

      &:hover {
        opacity: 1;
      }

      &:before,
      &:after {
        position: absolute;
        top: 8px;
        left: calc(50% - 4px);
        content: ' ';
        height: 24px;
        width: 4px;
        background-color: #333;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.cm-enter-active,
.cm-leave-active {
  transition: opacity 0.3s, transform 0.5s;
}
.cm-enter,
.cm-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.cm-backdrop-enter-active,
.cm-backdrop-leave-active {
  transition: opacity 0.5s;
}
.cm-backdrop-enter,
.cm-backdrop-leave-to {
  opacity: 0;
}
