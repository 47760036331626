/* Fixes for the generic Rich Text Block */
.ss-c-rich-text__content-wrap,
.c-section__description,
.c-ob__text {
  @extend .c-article__body;
}
.c-ob__text ol:not(.c-list) {
  @include padding(left, 2.5);
}

.ss-c-rich-text__content-wrap {
  .c-btn {
    display: inline-flex;
  }
}
