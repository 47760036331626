@mixin c-footer($footer-theme) {
  $root: &;
  background: map-get($footer-theme, background);
  position: relative;

  * {
    color: map-get($footer-theme, color);
  }

  @include targetIE() {
    .c-footer__newsletter > a {
      background-color: color(accent, blue, 100) !important;
      border: 1px solid color(primary, light, 100);
      &:hover {
        background-color: color(primary, core, 100) !important;
      }
    }
  }

  @include targetEdge() {
    .c-footer__newsletter > a {
      background-color: color(accent, blue, 100) !important;
      border: 1px solid color(primary, light, 100);
      &:hover {
        background-color: color(primary, core, 100) !important;
      }
    }
  }

  .cm-container {
    display: flex;
    flex-wrap: wrap;
  }

  // .c-footer__col
  &__col {
    flex: 0 0 100%;

    @include mq($screen-md, min) {
      flex: 1 0 0;
    }

    #{$root}__nav & {
      @if (map-get($footer-theme, start-end-border) == true) {
        &:first-child {
          border-top-width: 1px;
        }
      }

      @if (map-get($footer-theme, start-end-border) != true) {
        &:last-child {
          border-bottom-width: 0;
        }
      }

      border: 0 solid transparent;
      border-bottom-width: 1px;

      @include mq($screen-md, min) {
        border: none;
      }
    }

    & + #{$root}__col {
      @include mq($screen-md, min) {
        @include padding(left, 3);
      }
    }

    @if (map-get($footer-theme, has-badges) == false) {
      &--badges {
        display: none;
      }
    }
  }

  // .c-footer__nav
  &__nav {
    @include padding(
      vertical,
      map-deep-get($footer-theme, nav, spacing, vertical, default)
    );

    @include mq($screen-md, min) {
      @include padding(
        top,
        map-deep-get($footer-theme, nav, spacing, top, desktop)
      );
      @include padding(
        bottom,
        map-deep-get($footer-theme, nav, spacing, bottom, desktop)
      );
    }

    background: map-get($footer-theme, secondary-background);

    #{$root}__col {
      border-color: map-get($footer-theme, border-color);
    }

    input[type='checkbox'] {
      display: none;

      &:checked ~ #{$root}__nav-links {
        display: block;
      }

      &:checked ~ #{$root}__nav-heading svg {
        transform: rotate(180deg) translateY(50%);
      }
    }

    &-heading {
      display: block;
      position: relative;

      @include padding(vertical, map-deep-get($footer-theme, heading, spacing));
      @include typography(
        map-deep-get($footer-theme, heading, typography, default),
        bold
      );

      svg {
        fill: map-get($footer-theme, color);
        width: map-get($footer-theme, arrowIcon);
        height: map-get($footer-theme, arrowIcon);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.15s ease;
      }

      @include mq($screen-md, min) {
        @include typography(
          map-deep-get($footer-theme, heading, typography, desktop),
          bold
        );
        @include margin(bottom, 1.25);
        padding: 0;
        cursor: default;

        svg {
          display: none;
        }
      }
    }

    &-links {
      display: none;

      @include mq($screen-md, min) {
        display: block;
      }

      a {
        display: block;
        @include typography(body);
        @include margin(bottom, 3);

        @include mq($screen-md, min) {
          @include margin(bottom);
        }
      }
    }
  }

  // .c-footer__legal
  &__legal {
    @include padding(
      top,
      map-deep-get($footer-theme, legal, spacing, top, default)
    );
    @include padding(
      bottom,
      map-deep-get($footer-theme, legal, spacing, bottom, default)
    );

    @include mq($screen-md, min) {
      @include padding(
        top,
        map-deep-get($footer-theme, legal, spacing, top, desktop)
      );
      @include padding(
        bottom,
        map-deep-get($footer-theme, legal, spacing, bottom, desktop)
      );
    }
  }

  // .c-footer__content-block
  &__content-block {
    @include typography(body-sm);
    @include margin(bottom, 3);

    @include mq($screen-md, min) {
      margin: 0;
      line-height: rem-calc(30);
    }

    @if (map-deep-get($footer-theme, centerAlignedMobile) == true) {
      @include centerAlignOnMobile;
    }
  }

  // .c-footer__logo
  &__logo {
    width: rem-calc(127);
    display: block;

    @include margin(bottom, 3);

    @include mq($screen-md, min) {
      margin: 0;
    }
  }

  // .c-footer__social
  &__social {
    display: flex;

    @if (map-deep-get($footer-theme, centerAlignedMobile) == true) {
      @include centerAlignOnMobile;
    }
  }

  // .c-footer__social-link
  &__social-link {
    @include padding(horizontal, 2);

    svg {
      width: map-get($footer-theme, socialIcon);
      height: map-get($footer-theme, socialIcon);
      fill: map-get($footer-theme, color);
    }

    &:first-child {
      @include padding(left, 0);
    }

    @if (map-deep-get($footer-theme, centerAlignedMobile) == true) {
      @include mq($screen-md, max) {
        &:first-child {
          @include padding(left, 2);
        }
      }
    }

    @if (map-deep-get($footer-theme, social-links, separator) == true) {
      & + #{$root}__social-link {
        border-left: 1px solid map-get($footer-theme, color);
      }
    }
  }

  // .c-footer__newsletter
  &__newsletter {
    @include margin(bottom, 3);

    @include mq($screen-md, min) {
      margin: 0;
    }

    @if (map-deep-get($footer-theme, centerAlignedMobile) == true) {
      @include centerAlignOnMobile;
    }
  }

  // .c-footer__badges
  &__badges {
    @include margin(top, 2);

    @if (map-deep-get($footer-theme, centerAlignedMobile) == true) {
      @include centerAlignOnMobile;
    }

    @include mq($screen-md, min) {
      margin: 0;
    }

    img,
    a {
      height: rem-calc(40);

      & + img,
      & + a {
        @include margin(left, 3);

        @include mq($screen-md, min) {
          @include margin(left, 2);
        }
      }
    }
  }
}

@mixin centerAlignOnMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include mq($screen-md, min) {
    justify-content: flex-start;
    text-align: left;
  }
}

.c-footer {
  @include c-footer($c-footer);
}
