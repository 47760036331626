/// Accordion Component
/// - Version 1.0.0
/// - Date last edited: 2020-03-19
/// @name c-accordion
/// @author Mads Stoumann, Valtech

@mixin c-accordion($theme) {
  $root: &;
  // c-accordion__extra
  &__extra {
    width: 100%;
    // c-accordion__extra-inner
    &-inner .c-accordion__item:last-of-type {
      border-bottom-width: 1px;
    }
    // c-accordion__extra-toggle
    &-toggle {
      @include arrow($theme);
      color: map-deep-get($theme, toggle, color);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: map-deep-get($theme, toggle, margin);
      text-align: center;
      &::before {
        display: none;
      }
      &::-webkit-details-marker {
        display: none;
      }
      &:hover {
        text-decoration: underline;
      }
      @include targetIE() {
        display: none;
      }
    }
  }
  // c-accordion__item
  &__item {
    border-color: map-deep-get($theme, item, border-color);
    border-style: map-deep-get($theme, item, border-style);
    border-width: map-deep-get($theme, item, border-width);
    box-sizing: border-box;
    overflow: hidden;

    &[open] #{$root}__panel {
      animation: opacity-fade 600ms ease-in-out forwards;
      display: block;
    }
  }
  // c-accordion__headline
  &__headline {
    flex: 1;
    line-height: 1.4;
  }
  // c-accordion__panel
  &__panel {
    display: none;
    line-height: 1.4;
    opacity: 0;
    padding: map-deep-get($theme, panel, padding);

    a {
      @extend .link;
    }
  }
  // c-accordion__summary
  &__summary {
    @include arrow($theme);
    box-sizing: border-box;
    display: block;
    justify-content: space-between;
    list-style: none;
    outline: none;
    padding: map-deep-get($theme, summary, padding);
    position: relative;
    width: 100%;
    &::after {
      display: inline-flex;
      margin-right: $u-200;
      position: absolute;
      right: 0;
    }
    &::before {
      display: none;
    }
    &::-webkit-details-marker {
      display: none;
    }
    &::marker {
      display: none;
    }
    &:focus,
    &:hover {
      color: map-deep-get($theme, summary, color--focus);
    }
  }
  // c-accordion__wrapper
  &__wrapper {
    // c-accordion__wrapper-headline
    &-headline {
      color: map-deep-get($theme, headline, color);
      margin: map-deep-get($theme, headline, margin);
      text-align: map-deep-get($theme, headline, text-align);
      display: inline-flex;
    }
    & .c-accordion__item:last-of-type {
      border-bottom-width: 1px;
    }
  }
  &__extra[open] .c-accordion__extra-inner {
    display: contents;
  }
  &__extra[open] .c-accordion__extra-toggle {
    display: none;
  }
  &__item[open] .c-accordion__summary::after {
    margin-top: map-deep-get($theme, arrow, size);
    transform: rotate(225deg);
  }
  &__item[open] .c-accordion__headline {
    color: map-deep-get($theme, summary, color--open);
  }
  @include mq($screen-md, min) {
    &__panel {
      padding: map-deep-get($theme, panel, padding--desktop);
    }
  }
}

.c-accordion {
  @include c-accordion($c-accordion);
}
